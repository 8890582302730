import { ObjectEntries } from '../types/ts-utils';

/**
 * Groups an array of entitites by id
 * @param entities
 * @returns
 */
export function groupById<T extends { id: string }>(entities: T[]): Record<string, T> {
    return entities.reduce<Record<string, T>>((result, entity) => ({ ...result, [entity.id]: entity }), {});
}

export function getObjectEntriesTypes<T extends object>(obj: T) {
    return Object.entries(obj) as ObjectEntries<T>;
}

//Generic grouping functions
export function groupBy<T, K extends keyof T>(entities: T[], key: K) {
    let groupedEntities = new Map<T[K], T[]>();

    entities.forEach(item => {
        let keyValue = item[key];
        const value = groupedEntities.get(keyValue);

        if (value == null) {
            groupedEntities.set(keyValue, [item]);
        } else {
            value.push(item);
        }
    });

    return groupedEntities;
}
