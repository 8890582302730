import { ProgramStatus } from '../../app/constants';
import { SignalRListener } from '../middleware/listeners';
import {
    addProgram,
    removeProgram,
    selectProgramById,
    setFertiMeterFlowRateCalculatingStatus,
    setProgramStatus,
    setWaterMeterFlowRateCalculatingStatus,
    updateMixingProgress,
    updateProgress,
} from '../../slices/programsSlice';
import { getProgram } from '../../services/programs';
import { baseApi } from '../../services/baseApi';
import { setFertiMeterCalculatingStatus } from '~/slices/fertimetersSlice';
import { setWaterMeterCalculatingStatus } from '~/slices/watermetersSlice';

const onProgramStarting: SignalRListener<OndoCloudEvents.Programs.ProgramStarting> =
    e =>
    async ({ dispatch, getState }) => {
        const program = selectProgramById(getState(), e.programId);
        if (program) {
            const { programId, ...eventPayload } = e;
            dispatch(
                setProgramStatus({
                    id: programId,
                    status: ProgramStatus.Starting,
                    eventPayload,
                })
            );
        } else {
            // This program was not loaded in the state for some reason
            // In this case we need to request the program info from the b/end and populate the state
            try {
                const program = await dispatch(getProgram.initiate({ id: e.programId })).unwrap();
                dispatch(
                    addProgram({
                        id: e.programId,
                        irrigationInfrastructureId: program.irrigationInfrastructure.id,
                        controllerId: program.irrigationInfrastructure.controllerId,
                        startTime: e.startTime,
                        endTime: e.endTime,
                        duration: e.duration,
                        setPointCurrent: e.setPointCurrent,
                        setPointTotal: e.setPointTotal,
                        stepSetPointTotal: e.stepSetPointTotal,
                        stepSetPointCurrent: e.stepSetPointCurrent,
                        stoppingDelayCurrent: e.stoppingDelayCurrent,
                        stoppingDelayTotal: e.stoppingDelayTotal,
                        mixingBeforeProgramCurrentTime: e.mixingBeforeProgramCurrentTime,
                        mixingBeforeProgramTotalTime: e.mixingBeforeProgramTotalTime,
                        startedBy: e.startedBy,
                        startMode: e.startMode,
                        status: ProgramStatus.Starting,
                        stepIndex: -1,
                        steps: e.steps,
                        stepsCount: e.steps.length,
                        stepsRemaining: e.steps.length,
                        water: {
                            estimated: e.water.estimated,
                            used: 0,
                        },
                        fertimeters: e.fertimeters,
                        watermeters: {},
                    })
                );
            } catch (err) {
                console.error(`Failed to load program ${e.programId}`, err);
            }
        }
    };

const onProgramStartMixing: SignalRListener<OndoCloudEvents.Programs.ProgramStartMixing> =
    e =>
    async ({ dispatch, getState }) => {
        const program = selectProgramById(getState(), e.programId);
        if (program) {
            const { programId, ...eventPayload } = e;
            dispatch(
                setProgramStatus({
                    id: programId,
                    status: ProgramStatus.Mixing,
                    eventPayload,
                })
            );
        } else {
            // This program was not loaded in the state for some reason
            // In this case we need to request the program info from the b/end and populate the state
            try {
                const program = await dispatch(getProgram.initiate({ id: e.programId })).unwrap();
                dispatch(
                    addProgram({
                        id: e.programId,
                        status: ProgramStatus.Mixing,
                        irrigationInfrastructureId: program.irrigationInfrastructure.id,
                        controllerId: program.irrigationInfrastructure.controllerId,
                        startTime: e.startTime,
                        endTime: e.endTime,
                        duration: e.duration,
                        setPointCurrent: e.setPointCurrent,
                        setPointTotal: e.setPointTotal,
                        stepSetPointTotal: 0,
                        stepSetPointCurrent: 0,
                        stoppingDelayCurrent: 0,
                        stoppingDelayTotal: 0,
                        mixingBeforeProgramCurrentTime: e.mixingBeforeProgramCurrentTime,
                        mixingBeforeProgramTotalTime: e.mixingBeforeProgramTotalTime,
                        startedBy: e.startedBy,
                        startMode: e.startMode,
                        stepIndex: -1,
                        steps: e.steps,
                        stepsCount: e.steps.length,
                        stepsRemaining: e.steps.length,
                        water: {
                            estimated: e.water.estimated,
                            used: 0,
                        },
                        fertimeters: e.fertimeters,
                        watermeters: {},
                    })
                );
            } catch (err) {
                console.error(`Failed to load program ${e.programId}`, err);
            }
        }
    };

const onProgramMixingProgress: SignalRListener<OndoCloudEvents.Programs.ProgramMixingProgress> =
    e =>
    ({ dispatch }) =>
        dispatch(updateMixingProgress(e));

const onProgramStarted: SignalRListener<OndoCloudEvents.Programs.ProgramStarted> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setProgramStatus({
                id: e.programId,
                status: ProgramStatus.Running,
            })
        );
    };

const onProgramStopping: SignalRListener<OndoCloudEvents.Programs.ProgramStopping> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setProgramStatus({
                id: e.programId,
                status: ProgramStatus.Stopping,
                eventPayload: {
                    stoppingDelayCurrent: e.current,
                    stoppingDelayTotal: e.total,
                },
            })
        );
    };

const onProgramStoppingProgress: SignalRListener<OndoCloudEvents.Programs.ProgramStoppingProgress> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setProgramStatus({
                id: e.programId,
                status: ProgramStatus.Stopping,
                eventPayload: {
                    stoppingDelayCurrent: e.current,
                    stoppingDelayTotal: e.total,
                },
            })
        );
    };

const onProgramStopped: SignalRListener<OndoCloudEvents.Programs.ProgramStopped> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setProgramStatus({
                id: e.programId,
                status: ProgramStatus.Stopped,
            })
        );
    };

const onProgramFinished: SignalRListener<OndoCloudEvents.Programs.ProgramFinished> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setProgramStatus({
                id: e.programId,
                status: ProgramStatus.Finished,
            })
        );
    };

const onProgramProgress: SignalRListener<OndoCloudEvents.Programs.ProgramProgress> =
    e =>
    ({ dispatch }) => {
        dispatch(updateProgress(e));
    };

const onProgramAdded: SignalRListener<OndoCloudEvents.Programs.ProgramAdded> = e => api => {
    const program = selectProgramById(api.getState(), e.id);
    if (program) {
        return;
    }

    api.dispatch(
        baseApi.util.invalidateTags([
            { type: 'Programs', id: 'LIST' },
            { type: 'Programs', id: e.id },
        ])
    );
};

const onProgramUpdated: SignalRListener<OndoCloudEvents.Programs.ProgramUpdated> = e => api => {
    api.dispatch(
        baseApi.util.invalidateTags([
            { type: 'Programs', id: 'LIST' },
            { type: 'Programs', id: e.id },
        ])
    );
};

const onProgramRemoved: SignalRListener<OndoCloudEvents.Programs.ProgramRemoved> = e => api => {
    api.dispatch(removeProgram({ id: e.id }));
};

const onProgramStepStarted: SignalRListener<OndoCloudEvents.Programs.ProgramStepStarted> = e => api => {
    api.dispatch(
        baseApi.util.invalidateTags(
            e.fieldIds.flatMap(fieldId => [
                { type: 'FieldCurrentProgram', id: fieldId },
                { type: 'FieldNextProgram', id: fieldId },
                { type: 'FieldLastProgram', id: fieldId },
                { type: 'FieldSkippedProgram', id: fieldId },
            ])
        )
    );
};

const onProgramStepFinished: SignalRListener<OndoCloudEvents.Programs.ProgramStepFinished> = e => api => {
    api.dispatch(
        baseApi.util.invalidateTags(
            e.fieldIds.flatMap(fieldId => [
                { type: 'FieldCurrentProgram', id: fieldId },
                { type: 'FieldNextProgram', id: fieldId },
                { type: 'FieldLastProgram', id: fieldId },
                { type: 'FieldSkippedProgram', id: fieldId },
            ])
        )
    );
};

const onProgramWatermeterFlowRateCalculationChange: SignalRListener<
    OndoCloudEvents.Programs.ProgramWatermeterFlowRateCalculationChange
> = e => api => {
    api.dispatch(setWaterMeterFlowRateCalculatingStatus(e));
    api.dispatch(
        setWaterMeterCalculatingStatus({ id: e.watermeterId, isFlowRateCalculating: e.isFlowRateCalculating })
    );
};

const onProgramFertimeterFlowRateCalculationChange: SignalRListener<
    OndoCloudEvents.Programs.ProgramFertimeterFlowRateCalculationChange
> = e => api => {
    api.dispatch(setFertiMeterFlowRateCalculatingStatus(e));
    api.dispatch(
        setFertiMeterCalculatingStatus({ id: e.fertimeterId, isFlowRateCalculating: e.isFlowRateCalculating })
    );
};

export const listeners = {
    ProgramStarting: onProgramStarting,
    ProgramStartMixing: onProgramStartMixing,
    ProgramStarted: onProgramStarted,
    ProgramStopping: onProgramStopping,
    ProgramStoppingProgress: onProgramStoppingProgress,
    ProgramStopped: onProgramStopped,
    ProgramFinished: onProgramFinished,
    ProgramProgress: onProgramProgress,
    ProgramMixingProgress: onProgramMixingProgress,
    ProgramAdded: onProgramAdded,
    ProgramUpdated: onProgramUpdated,
    ProgramRemoved: onProgramRemoved,
    ProgramStepStarted: onProgramStepStarted,
    ProgramStepFinished: onProgramStepFinished,
    ProgramWatermeterFlowRateCalculationChange: onProgramWatermeterFlowRateCalculationChange,
    ProgramFertimeterFlowRateCalculationChange: onProgramFertimeterFlowRateCalculationChange,
};
