import { sortByNameAsc } from '../utils/sort';
import { baseApi } from './baseApi';

export const programsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getPrograms: build.query<
            OndoCloudSchemas.ProgramDto[],
            Partial<{
                irrigationInfrastructureId: string;
            }> | void
        >({
            query: params => ({
                url: 'programs',
                method: 'GET',
                params: params ?? undefined,
            }),
            transformResponse: (result: OndoCloudSchemas.ProgramDto[]) => {
                return result.sort(sortByNameAsc);
            },
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Programs' as const, id })), { type: 'Programs', id: 'LIST' }]
                    : [{ type: 'Programs', id: 'LIST' }],
        }),

        getProgram: build.query<OndoCloudSchemas.ProgramDto, { id: string }>({
            query: ({ id }) => `programs/${id}`,
            providesTags: result =>
                result
                    ? [
                          { type: 'Programs', id: 'LIST' },
                          { type: 'Programs' as const, id: result.id },
                      ]
                    : [],
        }),

        getDefaultAlarmSettings: build.query<
            OndoCloudSchemas.ProgramDefaultAlarmSettingsWrapperDto,
            Partial<{ irrigationInfrastructureId: string }>
        >({
            query: args => ({
                url: 'programs/defaultAlarmSettings',
                params: args ?? undefined,
            }),
            providesTags: [{ type: 'ProgramsAlarmSettings', id: 'LIST' }],
        }),

        createProgram: build.mutation<OndoCloudSchemas.ProgramDto, OndoCloudSchemas.CreateProgramCommand>({
            query: body => ({
                url: 'programs',
                method: 'POST',
                body,
            }),
            invalidatesTags: result =>
                result
                    ? [
                          { type: 'Programs' as const, id: 'LIST' },
                          { type: 'DashboardIrrigationInfrastructure', id: result.irrigationInfrastructure.id },
                      ]
                    : [],
        }),

        updateProgram: build.mutation<
            OndoCloudSchemas.ProgramDto,
            OndoCloudSchemas.EditProgramCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `programs/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'Programs', id: arg.id },
                          { type: 'Programs', id: 'LIST' },
                          { type: 'DashboardIrrigationInfrastructure', id: result.irrigationInfrastructure.id },
                      ]
                    : [],
        }),

        deleteProgram: build.mutation<void, { id: string; irrigationInfrastructureId: string }>({
            query: ({ id }) => ({
                url: `programs/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'Programs', id: args.id },
                          { type: 'Programs', id: 'LIST' },
                          { type: 'DashboardIrrigationInfrastructure', id: args.irrigationInfrastructureId },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetProgramsQuery,
    useGetProgramQuery,
    useGetDefaultAlarmSettingsQuery,
    useCreateProgramMutation,
    useUpdateProgramMutation,
    useDeleteProgramMutation,
} = programsApi;

export const { getProgram, getPrograms, createProgram, updateProgram, deleteProgram, getDefaultAlarmSettings } =
    programsApi.endpoints;
