import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MistingLineControlType, MistingLineStatus } from '../app/constants';
import { RootState } from '../app/store';
import { mistingLinesApi } from '../services/mistingLines';

export type MistingLineStateItem = OndoCloudState.MistingLineState;
export type MistingLineState = Record<string, MistingLineStateItem>;
const initialState: MistingLineState = {};

type MistingLineStatusChangePayload = {
    id: string;
} & (
    | {
          status: MistingLineStatus.Error | MistingLineStatus.Idle;
      }
    | ({
          status: MistingLineStatus.Working | MistingLineStatus.Resting;
      } & Omit<OndoCloudEvents.MistingLines.MistingLineStatusChange, 'id' | 'status'>)
);

export const mistingLinesSlice = createSlice({
    name: 'mistingLines',
    initialState,
    reducers: {
        sync(draft, { payload: mistingLines }: PayloadAction<OndoCloudState.RootState['mistingLineState']>) {
            for (const [id, mistingLine] of Object.entries(mistingLines)) {
                draft[id] = mistingLine;
            }
        },

        addMistingLine(draft, { payload }: PayloadAction<{ id: string } & MistingLineStateItem>) {
            const mistingLine = draft[payload.id];
            if (mistingLine) {
                return;
            }

            draft[payload.id] = payload;
        },

        removeMistingLine(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setMistingLineStatus(draft, { payload }: PayloadAction<MistingLineStatusChangePayload>) {
            const mistingLine = draft[payload.id];
            if (!mistingLine) {
                return;
            }

            mistingLine.status = payload.status;
            if (payload.status === MistingLineStatus.Working || payload.status === MistingLineStatus.Resting) {
                mistingLine.operationStartedAt = payload.operationStartedAt;
                mistingLine.workDuration = payload.workDuration;
                mistingLine.restDuration = payload.restDuration;
                mistingLine.startSource = payload.startSource;
            }
        },

        setMistingLinePeriods(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.MistingLines.MistingLineClimateStrategyPeriodChange>
        ) {
            const mistingLine = draft[payload.id];
            if (!mistingLine) {
                return;
            }

            mistingLine.activePeriod = payload.activePeriod ?? undefined;
            mistingLine.nextPeriod = payload.nextPeriod ?? undefined;
        },

        setMistingLineExtremeCondition(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.MistingLines.MistingLineExtremeConditionChange>
        ) {
            const mistingLine = draft[payload.id];
            if (!mistingLine) {
                return;
            }

            mistingLine.isInExtremeCondition = payload.isInExtremeCondition;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            mistingLinesApi.endpoints.createMistingLine.matchFulfilled,
            (draft, { payload: mistingLine }) => {
                draft[mistingLine.id] = {
                    controllerId: mistingLine.output.peripheral.controllerId,
                    fieldId: mistingLine.field.id,
                    status: MistingLineStatus.Idle,
                };
            }
        );
        builder.addMatcher(
            mistingLinesApi.endpoints.updateMistingLine.matchFulfilled,
            (draft, { payload: mistingLine }) => {
                const currentMistingLine = draft[mistingLine.id];
                if (!currentMistingLine) {
                    return;
                }

                currentMistingLine.status = MistingLineStatus.Idle;
            }
        );
        builder.addMatcher(mistingLinesApi.endpoints.deleteMistingLine.matchFulfilled, (draft, { meta }) => {
            const mistingLineId = meta.arg.originalArgs.mistingLineId;
            delete draft[mistingLineId];
        });
    },
});

// Selectors

export const selectMistingLines = (state: RootState) => state.mistingLines;

export const selectMistingLineById = createSelector(
    selectMistingLines,
    (_: RootState, id: string) => id,
    (mistingLines, id): MistingLineStateItem | null => mistingLines[id] ?? null
);

export const selectMistingLineStatus = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.status ?? MistingLineStatus.Idle
);

export const selectMistingLineCurrentActivePeriod = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.activePeriod ?? null
);

export const selectMistingLineNextActivePeriod = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.nextPeriod ?? null
);

export const selectMistingLineIsInActivePeriod = createSelector(selectMistingLineCurrentActivePeriod, period =>
    Boolean(period)
);

export const selectMistingLineHasNextActivePeriod = createSelector(selectMistingLineNextActivePeriod, period =>
    Boolean(period)
);

export const selectMistingLineActivePeriodCirculationFanId = createSelector(
    selectMistingLineCurrentActivePeriod,
    period => period?.circulationFanId ?? null
);

export const selectMistingLineActivePeriodHasAirUniformingEnabled = createSelector(
    selectMistingLineCurrentActivePeriod,
    period => {
        if (!period) {
            return false;
        }

        if (period.controlType === MistingLineControlType.Temperature) {
            return Boolean(period.mistingTemperatureControlSettings?.isAirCirculationEnabled);
        } else {
            return Boolean(period.mistingHumidityControlSettings?.isAirCirculationEnabled);
        }
    }
);

export const selectMistingLineOperationStartedAt = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.operationStartedAt ?? null
);

export const selectMistingLineWorkDuration = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.workDuration ?? null
);

export const selectMistingLineRestDuration = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.restDuration ?? null
);

export const selectMistingLineIsInExtremeCondition = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.isInExtremeCondition ?? null
);

export const selectMistingLineStartSource = createSelector(
    selectMistingLineById,
    mistingLine => mistingLine?.startSource ?? null
);

export const {
    sync,
    addMistingLine,
    removeMistingLine,
    setMistingLineStatus,
    setMistingLinePeriods,
    setMistingLineExtremeCondition,
} = mistingLinesSlice.actions;
