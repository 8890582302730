import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { WritableDraft } from 'immer/dist/internal';

const initialState: OndoCloudAlarms.ActiveAlarm[] = [];

export const alarmsSlice = createSlice({
    name: 'alarms',
    initialState,
    reducers: {
        sync(draft, { payload: alarms }: PayloadAction<OndoCloudAlarms.ActiveAlarm[]>) {
            const formattedAlarms = alarms.map(alarm => {
                return {
                    ...alarm,
                    metadata: alarm.metadata,
                };
            });

            draft = formattedAlarms;

            return draft;
        },
        dismissAlarm(draft, { payload: id }: PayloadAction<string>) {
            const alarm = selectAlarmById(draft, id);
            if (!alarm) {
                return;
            }

            alarm.isDismissedByCurrentUser = true;
        },
        addAlarm(draft, { payload: raisedAlarm }: PayloadAction<OndoCloudAlarms.ActiveAlarm>) {
            const alarm = draft.find(alarm => alarm.id === raisedAlarm.id);
            if (alarm) {
                return;
            }

            draft.push({
                ...raisedAlarm,
                metadata: raisedAlarm.metadata,
            });
        },
        clearAlarm(draft, { payload: id }: PayloadAction<string>) {
            const alarmIndex = draft.findIndex(alarm => alarm.id === id);
            if (alarmIndex === -1) {
                return;
            }

            draft.splice(alarmIndex, 1);
        },
        acknowledgeAlarm(draft, { payload: id }: PayloadAction<string>) {
            const alarm = selectAlarmById(draft, id);

            if (!alarm) {
                return;
            }

            alarm.isAcknowledgedByCurrentUser = true;
        },
    },
});

export const selectAlarmById = (draft: WritableDraft<OndoCloudAlarms.ActiveAlarm>[], id: string) =>
    draft.find(alarm => alarm.id === id);

// Selectors

export const selectActiveAlarms = (state: RootState) => state.alarms;

export const selectNotDismissedActiveAlarms = createSelector(selectActiveAlarms, state =>
    state.filter(alarm => !alarm.isDismissedByCurrentUser)
);

export const selectUnacknowledgedAlarms = createSelector(selectActiveAlarms, state =>
    state.filter(alarm => !alarm.isAcknowledgedByCurrentUser)
);

export const { sync, acknowledgeAlarm, clearAlarm, dismissAlarm, addAlarm } = alarmsSlice.actions;
