import { baseApi } from './baseApi';

export const accountsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getCurrentAccount: build.query<OndoCloudSchemas.AccountDto, void>({
            query: () => `accounts/current`,
            providesTags: result => (result ? [{ type: 'Accounts', id: 'LIST' }] : []),
        }),
    }),
    overrideExisting: true,
});

export const { useGetCurrentAccountQuery } = accountsApi;
