import {
    setAtmosphericPressure,
    setBatteryLevel,
    setDewPoint,
    setHumidity,
    setLastReadingAt,
    setLeafWetness,
    setRainFall,
    setRainQuantity,
    setRainQuantityLast24h,
    setSoilMoisture,
    setSoilTemperature,
    setSunRadiation,
    setTemperature,
    setWindDirection,
    setWindGust,
    setWindSpeed,
} from '../../slices/weatherStationsSlice';
import { SignalRListener } from '../middleware/listeners';

const onDataUpdatedChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationDataUpdated> =
    e =>
    ({ dispatch }) =>
        dispatch(setLastReadingAt(e));

const onTemperatureChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationTemperatureChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setTemperature({
                id: e.weatherStationId,
                temperature: e.temperature,
            })
        );

const onHumidityChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationHumidityChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setHumidity({
                id: e.weatherStationId,
                humidity: e.humidity,
            })
        );

const onRainQuantityChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationRainQuantityChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setRainQuantity({
                id: e.weatherStationId,
                rainQuantity: e.rainQuantity,
            })
        );

const onRainQuantityLast24hChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationRainQuantityLast24hChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setRainQuantityLast24h({
                id: e.weatherStationId,
                rainQuantity: e.rainQuantity,
            })
        );

const onRainFallChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationRainFallChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setRainFall({
                id: e.weatherStationId,
                rainFall: e.rainfall,
            })
        );

const onWindDirectionChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationWindDirectionChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setWindDirection({
                id: e.weatherStationId,
                windDirection: e.windDirection,
            })
        );

const onWindSpeedChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationWindSpeedChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setWindSpeed({
                id: e.weatherStationId,
                windSpeed: e.windSpeed,
            })
        );

const onSunRadiationChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationSunRadiationChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setSunRadiation({
                id: e.weatherStationId,
                sunRadiation: e.sunRadiation,
            })
        );

const onWeatherStationLeafWetnessChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationLeafWetnessChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setLeafWetness({
                id: e.weatherStationId,
                leafWetness: e.leafWetness,
            })
        );

const onWeatherStationWindGustChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationWindGustChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setWindGust({
                id: e.weatherStationId,
                windGust: e.windGust,
            })
        );

const onWeatherStationBatteryLevelChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationBatteryLevelChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setBatteryLevel({
                id: e.weatherStationId,
                batteryLevel: e.batteryLevel,
            })
        );

const onWeatherStationAtmosphericPressureChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationAtmosphericPressureChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setAtmosphericPressure({
                id: e.weatherStationId,
                atmosphericPressure: e.atmosphericPressure,
            })
        );

const onWeatherStationSoilTemperatureChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationSoilTemperatureChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setSoilTemperature({
                id: e.weatherStationId,
                soilTemperature: e.soilTemperature,
            })
        );

const onWeatherStationSoilMoistureChanged: SignalRListener<
    OndoCloudEvents.WeatherStations.WeatherStationSoilMoistureChanged
> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setSoilMoisture({
                id: e.weatherStationId,
                soilMoisture: e.soilMoisture,
            })
        );

const onWeatherStationDewPointChanged: SignalRListener<OndoCloudEvents.WeatherStations.WeatherStationDewPointChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setDewPoint({
                id: e.weatherStationId,
                dewPoint: e.dewPoint,
            })
        );

export const listeners = {
    WeatherStationDataUpdated: onDataUpdatedChanged,
    WeatherStationTemperatureChanged: onTemperatureChanged,
    WeatherStationHumidityChanged: onHumidityChanged,
    WeatherStationRainQuantityChanged: onRainQuantityChanged,
    WeatherStationRainQuantityLast24hChanged: onRainQuantityLast24hChanged,
    WeatherStationRainFallChanged: onRainFallChanged,
    WeatherStationWindDirectionChanged: onWindDirectionChanged,
    WeatherStationWindSpeedChanged: onWindSpeedChanged,
    WeatherStationSunRadiationChanged: onSunRadiationChanged,
    WeatherStationLeafWetnessChanged: onWeatherStationLeafWetnessChanged,
    WeatherStationWindGustChanged: onWeatherStationWindGustChanged,
    WeatherStationBatteryLevelChanged: onWeatherStationBatteryLevelChanged,
    WeatherStationAtmosphericPressureChanged: onWeatherStationAtmosphericPressureChanged,
    WeatherStationSoilTemperatureChanged: onWeatherStationSoilTemperatureChanged,
    WeatherStationDewPointChanged: onWeatherStationDewPointChanged,
    WeatherStationSoilMoistureChanged: onWeatherStationSoilMoistureChanged,
};
