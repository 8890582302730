import { baseApi } from './baseApi';

export const mainValvesApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getMainValves: build.query<OndoCloudSchemas.MainValveDto[], Partial<{ irrigationInfrastructureId: string }>>({
            query: params => ({
                url: 'main-valves',
                method: 'GET',
                params,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'MainValves' as const, id })),
                          { type: 'MainValves', id: 'LIST' },
                      ]
                    : [{ type: 'MainValves', id: 'LIST' }],
        }),
        getMainValve: build.query<OndoCloudSchemas.MainValveDto, Pick<OndoCloudSchemas.MainValveDto, 'id'>>({
            query: ({ id }) => `main-valves/${id}`,
            providesTags: (result, _, arg) => (result ? [{ type: 'MainValves' as const, id: arg.id }] : []),
        }),
        createMainValve: build.mutation<OndoCloudSchemas.MainValveDto, OndoCloudSchemas.CreateMainValveCommand>({
            query: body => ({
                url: 'main-valves',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'MainValves' as const, id: 'LIST' },
                          { type: 'MainValves' as const, id: result.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateMainValve: build.mutation<
            OndoCloudSchemas.MainValveDto,
            {
                mainValve: OndoCloudSchemas.EditMainValveCommand & { id: string };
                infrastructureId: string;
            }
        >({
            query: ({ mainValve }) => ({
                url: `main-valves/${mainValve.id}`,
                method: 'PUT',
                body: mainValve,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'MainValves' as const, id: 'LIST' },
                          { type: 'MainValves' as const, id: arg.mainValve.id },
                          { type: 'IrrigationInfrastructures', id: arg.infrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteMainValve: build.mutation<
            void,
            {
                id: string;
                irrigationInfrastructureId: string;
            }
        >({
            query: ({ id }) => ({
                url: `main-valves/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'MainValves' as const, id: 'LIST' },
                          { type: 'MainValves' as const, id: arg.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetMainValvesQuery,
    useGetMainValveQuery,
    useCreateMainValveMutation,
    useUpdateMainValveMutation,
    useDeleteMainValveMutation,
} = mainValvesApi;
