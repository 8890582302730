import { baseApi } from './baseApi';

export const airBubbleJetsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getAirBubbleJets: build.query<OndoCloudSchemas.AirBubbleJetDto[], void>({
            query: () => 'air-bubble-jets',
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'AirBubbleJets' as const, id })),
                          { type: 'AirBubbleJets', id: 'LIST' },
                      ]
                    : [{ type: 'AirBubbleJets', id: 'LIST' }],
        }),
        getAirBubbleJet: build.query<OndoCloudSchemas.AirBubbleJetDto, { id: string }>({
            query: ({ id }) => `air-bubble-jets/${id}`,
            providesTags: (result, _, arg) => (result ? [{ type: 'AirBubbleJets', id: arg.id }] : []),
        }),
        createAirBubbleJet: build.mutation<
            OndoCloudSchemas.AirBubbleJetDto,
            OndoCloudSchemas.CreateAirBubbleJetCommand
        >({
            query: body => ({
                url: 'air-bubble-jets',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error) => {
                if (error || !result) {
                    return [];
                }
                return [
                    { type: 'AirBubbleJets', id: 'LIST' },
                    { type: 'IrrigationInfrastructures', id: 'LIST' },
                    { type: 'IrrigationInfrastructures', id: result.irrigationInfrastructure.id },
                    { type: 'InputOutputs', id: 'LIST' },
                ];
            },
        }),
        updateAirBubbleJet: build.mutation<
            OndoCloudSchemas.AirBubbleJetDto,
            OndoCloudSchemas.EditAirBubbleJetCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `air-bubble-jets/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: result =>
                result
                    ? [
                          { type: 'AirBubbleJets', id: 'LIST' },
                          { type: 'AirBubbleJets', id: result.id },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: result.irrigationInfrastructure.id },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteAirBubbleJet: build.mutation<
            void,
            {
                id: string;
                irrigationInfrastructureId: string;
            }
        >({
            query: ({ id }) => ({
                url: `air-bubble-jets/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'AirBubbleJets', id: args.id },
                          { type: 'AirBubbleJets', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: args.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetAirBubbleJetsQuery,
    useGetAirBubbleJetQuery,
    useCreateAirBubbleJetMutation,
    useUpdateAirBubbleJetMutation,
    useDeleteAirBubbleJetMutation,
} = airBubbleJetsApi;
