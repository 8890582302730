import { deactivateClimateStrategy, changeClimateStrategyState } from '../../slices/climateStrategiesSlice';
import { SignalRListener } from '../middleware';

const onClimateStrategyDeactivated: SignalRListener<OndoCloudEvents.ClimateStrategies.DeactivateClimateStrategy> =
    e =>
    ({ dispatch }) => {
        dispatch(deactivateClimateStrategy(e));
    };

const onClimateStrategyStateChange: SignalRListener<OndoCloudEvents.ClimateStrategies.ClimateStrategyStateChange> =
    e =>
    ({ dispatch }) => {
        dispatch(changeClimateStrategyState(e));
    };

export const listeners = {
    DeactivateClimateStrategy: onClimateStrategyDeactivated,
    ClimateStrategyStateChange: onClimateStrategyStateChange,
};
