import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProgramStartMode, ProgramStatus, ProgramStepStatus } from '../app/constants';
import { RootState } from '../app/store';
import { programsApi } from '../services/programs';
import { isProgramStatusFinal } from '../utils/programs';

export function waterMeterGroupById(watermeters: OndoCloudSchemas.ProgramStateWatermeterResp[]) {
    return watermeters.reduce<OndoCloudState.ProgramState['watermeters']>((watermetersAcc, watermeter) => {
        if (!watermetersAcc[watermeter.id]) {
            watermetersAcc[watermeter.id] = { isFlowRateCalculating: watermeter.isFlowRateCalculating };
        }

        return watermetersAcc;
    }, {});
}
type ProgramStatusPayload =
    | {
          id: string;
          status: Exclude<ProgramStatus, ProgramStatus.Starting | ProgramStatus.Mixing | ProgramStatus.Stopping>;
      }
    | {
          id: string;
          status: ProgramStatus.Starting;
          eventPayload: Omit<OndoCloudEvents.Programs.ProgramStarting, 'programId'>;
      }
    | {
          id: string;
          status: ProgramStatus.Mixing;
          eventPayload: Omit<OndoCloudEvents.Programs.ProgramStartMixing, 'programId'>;
      }
    | {
          id: string;
          status: ProgramStatus.Stopping;
          eventPayload: {
              stoppingDelayCurrent: number;
              stoppingDelayTotal: number;
          };
      };

type ProgramStateFertimeter = ProgramStateItem['fertimeters'][string];
export type ProgramsState = OndoCloudState.RootState['programsState'];
export type ProgramStateItem = ProgramsState[string];

const initialState: ProgramsState = {};

export const programsSlice = createSlice({
    name: 'programs',
    initialState,
    reducers: {
        sync(draft, { payload: programsById }: PayloadAction<ProgramsState>) {
            if (Object.values(programsById).length === 0) {
                for (const program of Object.values(draft)) {
                    draft[program.id].status = ProgramStatus.None;
                }
            } else {
                for (const program of Object.values(programsById)) {
                    draft[program.id] = program;
                }
            }
        },

        syncControllerPrograms(
            draft,
            { payload }: PayloadAction<{ controllerId: string; programsState: ProgramsState }>
        ) {
            const { controllerId, programsState } = payload;

            for (const program of Object.values(draft)) {
                if (program.controllerId === controllerId) {
                    delete draft[program.id];
                }
            }

            for (const program of Object.values(programsState)) {
                draft[program.id] = program;
            }
        },

        setProgramStatus(draft, { payload }: PayloadAction<ProgramStatusPayload>) {
            const program = draft[payload.id];
            if (!program) {
                return;
            }

            program.status = payload.status;
            if (
                payload.status === ProgramStatus.Starting ||
                payload.status === ProgramStatus.Mixing ||
                payload.status === ProgramStatus.Stopping
            ) {
                Object.assign(program, payload.eventPayload);
            }
        },

        updateProgress(draft, { payload }: PayloadAction<OndoCloudEvents.Programs.ProgramProgress>) {
            const program = draft[payload.programId];
            if (!program) {
                return;
            }

            const stepIndex = payload.step.index;
            const step = program.steps[stepIndex];
            if (!step) {
                return;
            }

            step.duration = payload.step.duration;
            step.remainingTime = payload.step.remainingTime;
            step.setPointCurrent = payload.step.setPointCurrent;
            step.setPointTotal = payload.step.setPointTotal;
            step.status = payload.step.status;
            step.water = payload.step.water;
            step.watermeterIds = payload.step.watermeterIds;
            step.dosingChannelIds = payload.step.dosingChannelIds;
            step.recipeId = payload.step.recipeId;

            program.stepIndex = stepIndex;
            program.stepsCount = payload.stepsCount;
            program.stepsRemaining = payload.stepsRemaining;
            program.stepSetPointCurrent = payload.stepSetPointCurrent;
            program.stepSetPointTotal = payload.stepSetPointTotal;

            program.status = ProgramStatus.Running;
            program.setPointTotal = payload.setPointTotal;
            program.setPointCurrent = payload.setPointCurrent;
            program.water = payload.water;
            program.fertimeters = payload.fertimeters;
            program.watermeters = { ...program.watermeters, ...waterMeterGroupById(payload.watermeters) };
        },

        updateMixingProgress(draft, { payload }: PayloadAction<OndoCloudEvents.Programs.ProgramMixingProgress>) {
            const program = draft[payload.programId];
            if (!program) {
                return;
            }

            program.mixingBeforeProgramCurrentTime = payload.mixingBeforeProgramCurrentTime;
            program.mixingBeforeProgramTotalTime = payload.mixingBeforeProgramTotalTime;
        },

        resetProgram(draft, { payload }: PayloadAction<{ id: string }>) {
            const program = draft[payload.id];
            if (!program) {
                return;
            }

            program.status = ProgramStatus.None;
            program.startTime = null;
            program.endTime = null;
            program.stepIndex = -1;
            program.stepsRemaining = program.stepsCount;
            program.setPointCurrent = 0;
            program.water.used = 0;
            program.fertimeters = Object.keys(program.fertimeters).reduce<Record<string, ProgramStateFertimeter>>(
                (fertimeters, id) => {
                    fertimeters[id] = {
                        id,
                        currentStepLiters: 0,
                        totalLiters: 0,
                        isFlowRateCalculating: false,
                    };
                    return fertimeters;
                },
                {}
            );
        },

        addProgram(draft, { payload }: PayloadAction<ProgramStateItem>) {
            draft[payload.id] = payload;
        },

        removeProgram(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setWaterMeterFlowRateCalculatingStatus(
            draft,
            { payload }: PayloadAction<{ programId: string; watermeterId: string; isFlowRateCalculating: boolean }>
        ) {
            const { programId, watermeterId, isFlowRateCalculating } = payload;

            if (!draft[programId].watermeters[watermeterId]) {
                return;
            }

            draft[programId].watermeters[watermeterId].isFlowRateCalculating = isFlowRateCalculating;
        },
        setFertiMeterFlowRateCalculatingStatus(
            draft,
            { payload }: PayloadAction<{ programId: string; fertimeterId: string; isFlowRateCalculating: boolean }>
        ) {
            const { programId, fertimeterId, isFlowRateCalculating } = payload;

            if (!draft[programId].fertimeters[fertimeterId]) {
                return;
            }
            draft[programId].fertimeters[fertimeterId].isFlowRateCalculating = isFlowRateCalculating;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(programsApi.endpoints.getPrograms.matchFulfilled, (draft, { payload: programs }) => {
            programs.forEach(program => {
                if (draft[program.id]) {
                    return;
                }

                const promramState = transformProgramToStateForm(program);

                draft[program.id] = promramState;
            });
        });
        builder.addMatcher(programsApi.endpoints.getProgram.matchFulfilled, (draft, { payload: program }) => {
            if (draft[program.id]) {
                return;
            }
            const promramState = transformProgramToStateForm(program);

            draft[program.id] = promramState;
        });
        builder.addMatcher(programsApi.endpoints.createProgram.matchFulfilled, (draft, { payload: program }) => {
            draft[program.id] = transformProgramToStateForm(program);
        });
        builder.addMatcher(programsApi.endpoints.updateProgram.matchFulfilled, (draft, { payload: program }) => {
            draft[program.id] = transformProgramToStateForm(program);
        });
        builder.addMatcher(programsApi.endpoints.deleteProgram.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

export const startProgram = createAsyncThunk('programs', (id: string, { dispatch }) => {
    dispatch(resetProgram({ id }));
    dispatch(
        setProgramStatus({
            id,
            status: ProgramStatus.StartInitiated,
        })
    );
});

export const stopProgram = createAsyncThunk('programs', (id: string, { dispatch }) => {
    dispatch(
        setProgramStatus({
            id,
            status: ProgramStatus.StopInitiated,
        })
    );
});

// Selectors
const selectIrrigationInfraId = (_: RootState, irrigationInfrastructureId: string) => irrigationInfrastructureId;

export function selectPrograms(state: RootState) {
    return state.programs;
}

export const selectProgramById = (state: RootState, id: string) => state.programs[id] ?? null;

export const selectProgramDuration = createSelector(selectProgramById, program => program?.duration ?? null);
export const selectProgramStatus = createSelector(selectProgramById, program => program?.status ?? ProgramStatus.None);
export const selectProgramStartTime = createSelector(selectProgramById, program => program?.startTime ?? null);
export const selectProgramEndTime = createSelector(selectProgramById, program => program?.endTime ?? null);
export const selectProgramWaterUsedLiters = createSelector(selectProgramById, program => program?.water.used ?? null);
export const selectProgramWaterEstimatedLiters = createSelector(
    selectProgramById,
    program => program?.water.estimated ?? null
);
export const selectProgramStartMode = createSelector(selectProgramById, program => program?.startMode ?? null);
export const selectProgramStartedBy = createSelector(selectProgramById, program => program?.startedBy ?? null);
export const selectProgramStartedByInfo = createSelector(
    selectProgramById,
    selectProgramStartMode,
    selectProgramStartedBy,
    (_, startMode, startedBy) => {
        return startedBy != null && startMode != null
            ? {
                  startMode,
                  startedBy,
              }
            : null;
    }
);
export const selectProgramSetPointCurrent = createSelector(
    selectProgramById,
    program => program?.setPointCurrent ?? null
);
export const selectProgramSetPointTotal = createSelector(selectProgramById, program => program?.setPointTotal ?? null);
// This setpoint value indicates the total sum of setpoints of all steps in the program without any delays
export const selectProgramStepSetPointTotal = createSelector(
    selectProgramById,
    program => program?.stepSetPointTotal ?? null
);

export const selectProgramStepSetPointCurrent = createSelector(
    selectProgramById,
    program => program?.stepSetPointCurrent ?? null
);

export const selectProgramStoppingDelayTotal = createSelector(
    selectProgramById,
    program => program?.stoppingDelayTotal ?? null
);

export const selectProgramStoppingDelayCurrent = createSelector(
    selectProgramById,
    program => program?.stoppingDelayCurrent ?? null
);

export const selectProgramSteps = createSelector(selectProgramById, program => program?.steps ?? null);
export const selectProgramStepIndex = createSelector(selectProgramById, program => program?.stepIndex ?? null);
export const selectProgramStepsCount = createSelector(selectProgramSteps, steps => steps?.length ?? null);

export const selectCurrentProgramStep = createSelector(
    selectProgramSteps,
    selectProgramStepIndex,
    (steps, stepIndex) => {
        if (steps === null) {
            return null;
        }

        let index = stepIndex;
        // Negative index indicates that the program is not running
        // We default to the first program step in that case due to
        // the current requirements of always showing the first step even
        // when it is not active at the moment.
        if (index === -1) {
            index = 0;
        }

        return steps[index] ?? null;
    }
);

export const selectCurrentProgramStepRecipeId = createSelector(
    selectCurrentProgramStep,
    step => step?.recipeId ?? null
);

export const selectProgramStepByIndex = createSelector(
    [selectProgramSteps, (_: RootState, __: string, index: number) => index],
    (steps, index) => steps?.[index] ?? null
);

export const selectProgramStepWatermeterIds = createSelector(
    selectCurrentProgramStep,
    step => step?.watermeterIds ?? null
);

export const selectProgramStepDosingChannelIds = createSelector(
    selectCurrentProgramStep,
    step => step?.dosingChannelIds ?? null
);

export const selectProgramsByIrrigationInfrastructureId = createSelector(
    selectPrograms,
    selectIrrigationInfraId,
    (programs, irrigationInfrastructureId) =>
        Object.values(programs).filter(program => program.irrigationInfrastructureId === irrigationInfrastructureId)
);

export const selectActiveProgramInIrrigationInfrastructure = createSelector(
    selectProgramsByIrrigationInfrastructureId,
    programs => programs.find(program => !isProgramStatusFinal(program.status)) ?? null
);

export const selectActiveProgramIdInIrrigationInfrastructure = createSelector(
    selectActiveProgramInIrrigationInfrastructure,
    (program): string | null => program?.id ?? null
);

export const selectProgramFertimeters = createSelector(selectProgramById, program => program?.fertimeters ?? null);

export const selectProgramFertimeter = createSelector(
    [selectProgramFertimeters, (_: RootState, programId: string, fertimeterId: string) => fertimeterId],
    (fertimeters, fertimeterId) => (fertimeters !== null ? fertimeters[fertimeterId] : null)
);

export const selectProgramWatermeters = createSelector(selectProgramById, program => program?.watermeters ?? null);

export const selectProgramWatermeter = createSelector(
    [selectProgramWatermeters, (_: RootState, programId: string, watermeterId: string) => watermeterId],
    (watermeters, watermeterId) => (watermeters !== null ? watermeters[watermeterId] : null)
);

export const selectProgramMixingBeforeCurrentTime = createSelector(
    selectProgramById,
    program => program?.mixingBeforeProgramCurrentTime ?? null
);

export const selectProgramMixingBeforeTotalTime = createSelector(
    selectProgramById,
    program => program?.mixingBeforeProgramTotalTime ?? null
);

export const doesProgramHaveFertigationInAnyStep = createSelector(
    selectProgramSteps,
    steps => steps?.some(step => step.recipeId) ?? false
);

function transformProgramToStateForm(program: OndoCloudSchemas.ProgramDto): ProgramStateItem {
    const enabledSteps = program.steps.filter(step => step.isEnabled);
    let fertimeters: ProgramStateItem['fertimeters'] = {};
    let watermeters: ProgramStateItem['watermeters'] = {};

    enabledSteps.forEach(step => {
        if (step.recipe) {
            const currenetStepFertimeters = step.recipe.recipeChannels.reduce<ProgramStateItem['fertimeters']>(
                (stepFertimeters, recipeChannel) => {
                    const stepFertimeter = recipeChannel.dosingChannel.fertimeter;
                    if (!stepFertimeter) {
                        return stepFertimeters;
                    }

                    stepFertimeters[stepFertimeter.id] = {
                        id: stepFertimeter.id,
                        currentStepLiters: 0,
                        totalLiters: 0,
                        isFlowRateCalculating: false,
                    };

                    return stepFertimeters;
                },
                {}
            );

            Object.assign(fertimeters, currenetStepFertimeters);
        }

        step.irrigationValves.forEach(valve => {
            if (valve.watermeter) {
                if (watermeters[valve.watermeter.id]) {
                    watermeters[valve.watermeter.id].isFlowRateCalculating = false;
                } else {
                    watermeters[valve.watermeter.id] = { isFlowRateCalculating: false };
                }
            }
        });
    });

    const stepSetPointTotal = enabledSteps.reduce((totalSetpoint, step) => (totalSetpoint += step.setPoint), 0);

    return {
        id: program.id,
        irrigationInfrastructureId: program.irrigationInfrastructure.id,
        controllerId: program.irrigationInfrastructure.controllerId,
        duration: 0,
        endTime: null,
        startTime: null,
        mixingBeforeProgramCurrentTime: 0,
        mixingBeforeProgramTotalTime: 0,
        startedBy: {
            id: '',
            name: '',
        },
        startMode: ProgramStartMode.User,
        steps: enabledSteps.map((step, idx) => ({
            id: step.id,
            duration: 0,
            index: idx,
            irrValvesIds: step.irrigationValves.map(x => x.id),
            dosingChannelIds: step.recipe?.recipeChannels.map(rc => rc.dosingChannel.id) ?? [],
            remainingTime: 0,
            setPointCurrent: 0,
            setPointTotal: step.setPoint,
            status: ProgramStepStatus.NotStarted,
            recipeId: step.recipe?.id,
            valvesByField: [],
            water: {
                used: 0,
                estimated: 0,
                remaining: 0,
            },
            watermeterIds: [],
        })),
        status: ProgramStatus.None,
        stepIndex: -1,
        stepsCount: enabledSteps.length,
        stepsRemaining: enabledSteps.length,
        water: {
            used: 0,
            estimated: 0,
        },
        setPointCurrent: 0,
        setPointTotal: stepSetPointTotal,
        stepSetPointTotal,
        stepSetPointCurrent: 0,
        stoppingDelayCurrent: 0,
        stoppingDelayTotal: 0,
        fertimeters,
        watermeters: watermeters,
    };
}

export const {
    sync,
    syncControllerPrograms,
    setProgramStatus,
    updateProgress,
    updateMixingProgress,
    resetProgram,
    addProgram,
    removeProgram,
    setWaterMeterFlowRateCalculatingStatus,
    setFertiMeterFlowRateCalculatingStatus,
} = programsSlice.actions;
