import { baseApi } from './baseApi';

export const irrigationValvesApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getIrrigationValves: build.query<
            OndoCloudSchemas.IrrigationValveDto[],
            Partial<{ irrigationInfrastructureId: string }> | void
        >({
            query: params => ({
                url: 'irrigation-valves',
                method: 'GET',
                params: params ?? undefined,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'IrrigationValves' as const, id })),
                          { type: 'IrrigationValves', id: 'LIST' },
                      ]
                    : [{ type: 'IrrigationValves', id: 'LIST' }],
        }),
        getIrrigationValve: build.query<
            OndoCloudSchemas.IrrigationValveDto,
            Pick<OndoCloudSchemas.IrrigationValveDto, 'id'>
        >({
            query: ({ id }) => `irrigation-valves/${id}`,
            providesTags: (_, __, arg) => [{ type: 'IrrigationValves', id: arg.id }],
        }),
        addIrrigationValve: build.mutation<
            OndoCloudSchemas.IrrigationValveDto,
            OndoCloudSchemas.CreateIrrigationValveCommand
        >({
            query: payload => ({
                body: payload,
                method: 'POST',
                url: 'irrigation-valves',
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'IrrigationValves', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'Programs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateIrrigationValve: build.mutation<
            OndoCloudSchemas.IrrigationValveDto,
            {
                irrigationValve: OndoCloudSchemas.EditIrrigationValveCommand;
                irrigationValveId: string;
                infrastructureId: string;
            }
        >({
            query: ({ irrigationValve, irrigationValveId, infrastructureId }) => ({
                url: `irrigation-valves/${irrigationValveId}`,
                method: 'PUT',
                body: irrigationValve,
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'IrrigationValves', id: arg.irrigationValveId },
                          { type: 'IrrigationValves', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.infrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'Programs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteIrrigationValve: build.mutation<
            void,
            {
                irrigationValveId: OndoCloudSchemas.IrrigationValveDto['id'];
                irrigationInfrastructureId: string;
                force?: boolean;
            }
        >({
            query: args => ({
                url: `irrigation-valves/${args.irrigationValveId}`,
                method: 'delete',
                params: { force: args.force },
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'IrrigationValves', id: args.irrigationValveId },
                          { type: 'IrrigationValves', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: args.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'Programs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export default irrigationValvesApi;

export const {
    useGetIrrigationValveQuery,
    useGetIrrigationValvesQuery,
    useAddIrrigationValveMutation,
    useUpdateIrrigationValveMutation,
    useDeleteIrrigationValveMutation,
} = irrigationValvesApi;
