import { sortByNameAsc } from '../utils/sort';
import { baseApi } from './baseApi';

export const frostProtectionDevicesApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getFrostProtectionDevices: build.query<OndoCloudSchemas.FrostProtectionDeviceDto[], void>({
            query: () => 'frost-protection-devices',
            transformResponse: (response: OndoCloudSchemas.FrostProtectionDeviceDto[]) => response.sort(sortByNameAsc),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'FrostProtectionDevices' as const, id })),
                          { type: 'FrostProtectionDevices', id: 'LIST' },
                      ]
                    : [{ type: 'FrostProtectionDevices', id: 'LIST' }],
        }),

        getFrostProtectionDevice: build.query<OndoCloudSchemas.FrostProtectionDeviceDto, { id: string }>({
            query: ({ id }) => `frost-protection-devices/${id}`,
            providesTags: (_, __, arg) => [{ type: 'FrostProtectionDevices', id: arg.id }],
        }),

        createFrostProtectionDevice: build.mutation<
            OndoCloudSchemas.FrostProtectionDeviceDto,
            OndoCloudSchemas.CreateFrostProtectionDeviceCommand
        >({
            query: payload => ({
                method: 'POST',
                url: 'frost-protection-devices',
                body: payload,
            }),
            invalidatesTags: result =>
                result
                    ? [
                          { type: 'FrostProtectionDevices', id: 'LIST' },
                          { type: 'FrostProtectionStrategies', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),

        updateFrostProtectionDevice: build.mutation<
            OndoCloudSchemas.FrostProtectionDeviceDto,
            OndoCloudSchemas.EditFrostProtectionDeviceCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                method: 'PUT',
                url: `frost-protection-devices/${id}`,
                body,
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'FrostProtectionDevices', id: 'LIST' },
                          { type: 'FrostProtectionDevices', id: args.id },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'FrostProtectionStrategies', id: 'LIST' },
                          { type: 'FrostProtectionStrategies', id: args.id },
                      ]
                    : [],
        }),

        deleteFrostProtectionDevice: build.mutation<void, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `frost-protection-devices/${id}`,
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'FrostProtectionDevices', id: 'LIST' },
                          { type: 'FrostProtectionDevices', id: args.id },
                          { type: 'FrostProtectionStrategies', id: 'LIST' },
                          { type: 'FrostProtectionStrategies', id: args.id },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetFrostProtectionDevicesQuery,
    useGetFrostProtectionDeviceQuery,
    useCreateFrostProtectionDeviceMutation,
    useUpdateFrostProtectionDeviceMutation,
    useDeleteFrostProtectionDeviceMutation,
} = frostProtectionDevicesApi;
