import { AuthError } from '@azure/msal-browser';
import {
    DefaultHttpClient,
    HttpRequest,
    HubConnection,
    HubConnectionBuilder,
    HubConnectionState,
    LogLevel,
    NullLogger,
} from '@microsoft/signalr';
import { Middleware } from 'redux';
import { config } from '../../app/config';
import { CloudCommandStatus } from '../../app/constants';
import { AppDispatch, RootState } from '../../app/store';
import { loginRequest, msalInstance, tokenRequest } from '../../auth';
import { logout } from '../../slices/authSlice';
import {
    sendCommand,
    mapCommandIds,
    resolveCommand,
    rejectCommand,
    connectSuccess,
    disconnectSuccess,
    reconnecting,
    startConnection,
    stopConnection,
    updateRetryContext,
    initiateCommand,
    keepAlive,
} from '../../slices/signalR';
import { addListeners } from './listeners';

const SOCKET_URL = config.socket.baseUrl;
const LOG_LEVEL = import.meta.env.MODE !== 'production' ? LogLevel.Debug : LogLevel.None;
const RETRY_DELAY_MS = config.socket.retryDelayMs;

export const SIGNALR_TOKEN_EXPERIATION_DATE = '@ondo-ui/cached_signalR_token_experiation_date';

async function accessTokenFactory() {
    try {
        const tokenResult = await msalInstance.acquireTokenSilent(tokenRequest);

        localStorage.setItem(SIGNALR_TOKEN_EXPERIATION_DATE, tokenResult.expiresOn?.toISOString() ?? '');

        return tokenResult.accessToken;
    } catch (err) {
        if (err instanceof AuthError) {
            await msalInstance.acquireTokenRedirect({
                ...loginRequest,
                state: window.location.pathname,
            });
        }

        console.error(err);
        return '';
    }
}

class SignalRHttpClient extends DefaultHttpClient {
    private _defaultRequestTimeoutMs = config.socket.requestTimeoutMs;

    constructor() {
        super(NullLogger.instance);
    }

    send(request: HttpRequest) {
        if (!request.timeout) {
            request.timeout = this._defaultRequestTimeoutMs;
        }

        return super.send(request);
    }
}

export const defaultHttpClient = new SignalRHttpClient();

export function createHubConnection(dispatch: AppDispatch): () => [HubConnection, (isInSilentMode: boolean) => void] {
    let connection: HubConnection;
    let currentIntervalId: ReturnType<typeof setInterval>;
    let lastRetryTimestamp = Date.now();
    let isSilentReconnectionActive: boolean = false;

    const buildHubConnection = () => {
        if (connection) {
            return;
        }

        connection = new HubConnectionBuilder()
            .configureLogging(LOG_LEVEL)
            .withUrl(SOCKET_URL, { httpClient: defaultHttpClient, accessTokenFactory })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: ctx => {
                    dispatch(reconnecting());

                    // Clear previous timer
                    if (currentIntervalId) {
                        clearInterval(currentIntervalId);
                    }

                    lastRetryTimestamp = Date.now();
                    // Start the new timer
                    currentIntervalId = setInterval(() => {
                        const remainingMillisecondsUntilNextRetry = RETRY_DELAY_MS - (Date.now() - lastRetryTimestamp);
                        dispatch(
                            updateRetryContext({
                                elapsedMilliseconds: ctx.elapsedMilliseconds,
                                previousRetryCount: ctx.previousRetryCount,
                                currentAttemptDelayMilliseconds: RETRY_DELAY_MS,
                                remainingMillisecondsUntilNextRetry: remainingMillisecondsUntilNextRetry,
                            })
                        );
                    }, 1000);

                    return ctx.previousRetryCount === 0 ? 0 : RETRY_DELAY_MS;
                },
            })
            .build();

        connection.onreconnecting(() => {
            if (!isSilentReconnectionActive) {
                dispatch(reconnecting());
            }
        });

        connection.onreconnected(connectionId => {
            dispatch(connectSuccess({ connectionId: connectionId! }));
            if (currentIntervalId) {
                clearInterval(currentIntervalId);
            }
        });

        connection.onclose(() => {
            if (!isSilentReconnectionActive) {
                dispatch(disconnectSuccess());
            }
        });
    };
    buildHubConnection();

    const setIsSilentReconnectionActive = (isInSilentMode: boolean) => {
        isSilentReconnectionActive = isInSilentMode;
    };

    return () => [connection, setIsSilentReconnectionActive];
}

export async function rebootHubConnection(
    connection: HubConnection,
    setIsSilentReconnectionActive: (isInSilentMode: boolean) => void
) {
    try {
        setIsSilentReconnectionActive(true);
        await connection.stop();
        await connection.start();
        setIsSilentReconnectionActive(false);
    } catch (error) {
        console.log('failed do reboot connection');
    }
}

export type CloudCommandPromiseResponse =
    | {
          isError: boolean;
          errors?: OndoCloudCommands.CloudCommandError[];
          data?: OndoCloudCommands.CloudCommandResponse['payload'];
      }
    | {
          isError: true;
          errors: OndoCloudCommands.CloudCommandError[];
      }
    | {
          isError: false;
          data: OndoCloudCommands.CloudCommandResponse['payload'];
      };

export const runningPromisesMap = new Map<string, { resolve(value: any): void; reject(reason?: any): void }>();

export const signalRMiddleware: Middleware<{}, RootState> = store => next => {
    let removeListeners: () => void;
    const getHubConnection = createHubConnection(store.dispatch);

    return action => {
        const connectionState = store.getState().signalR.connectionState;
        const isLoggedIn = store.getState().auth.status === 'loggedIn';

        if (
            isLoggedIn &&
            connectionState === HubConnectionState.Disconnected &&
            !startConnection.match(action) &&
            !keepAlive.match(action) &&
            !connectSuccess.match(action)
        ) {
            store.dispatch(startConnection());
        } else if (logout.match(action) && !stopConnection.match(action)) {
            store.dispatch(stopConnection());
        } else if (startConnection.match(action) && connectionState === HubConnectionState.Disconnected) {
            const [connection] = getHubConnection();

            connection
                .start()
                .then(() => {
                    store.dispatch(connectSuccess({ connectionId: connection.connectionId! }));
                })
                .catch(() => {
                    store.dispatch(disconnectSuccess());
                });

            removeListeners = addListeners(connection, store);
        } else if (keepAlive.match(action) && connectionState === HubConnectionState.Connected) {
            const [connection, setIsSilentReconnectionActive] = getHubConnection();

            rebootHubConnection(connection, setIsSilentReconnectionActive)
                .then(() => removeListeners())
                .then(() => {
                    store.dispatch(connectSuccess({ connectionId: connection.connectionId! }));

                    removeListeners = addListeners(connection, store);
                })
                .catch(() => store.dispatch(disconnectSuccess()));
        } else if (stopConnection.match(action) && connectionState === HubConnectionState.Connected) {
            const [connection] = getHubConnection();

            connection
                .stop()
                .then(() => {
                    store.dispatch(disconnectSuccess());
                })
                .catch(() => {
                    store.dispatch(disconnectSuccess());
                });

            if (removeListeners) {
                removeListeners();
            }
        } else if (sendCommand.match(action)) {
            if (connectionState !== HubConnectionState.Connected) {
                console.error('CommandError: Attempt to send a command when the hub is not connected!', action);
                return next(action);
            }

            const [connection] = getHubConnection();
            const { command, commandKey, requestId } = action.payload;

            store.dispatch(initiateCommand(command, requestId, commandKey));

            const promise = connection
                .invoke(command.name, command.payload)
                .then((response: OndoCloudCommands.CloudCommandResponse) => {
                    store.dispatch(mapCommandIds(commandKey, requestId, response.id));
                    if (response.status === CloudCommandStatus.Success) {
                        store.dispatch(resolveCommand(commandKey, response));
                        return { isError: false, data: response.payload };
                    } else if (response.status === CloudCommandStatus.Error) {
                        store.dispatch(rejectCommand(requestId, response.errors));
                        return { isError: true, errors: response.errors };
                    } else {
                        const promise = new Promise<CloudCommandPromiseResponse>((resolve, reject) => {
                            runningPromisesMap.set(response.id, { resolve, reject });
                        });

                        return promise.finally(() => {
                            runningPromisesMap.delete(response.id);
                        });
                    }
                })
                .catch(error => {
                    const errors = error.errors;
                    store.dispatch(rejectCommand(requestId, errors));
                    return { isError: true, errors };
                });

            return {
                payload: {
                    commandKey,
                    requestId,
                },
                meta: {
                    async unwrap() {
                        const result = await promise;

                        if ('data' in result) {
                            return result.data;
                        } else {
                            throw result.errors;
                        }
                    },
                },
            };
        }

        return next(action);
    };
};
