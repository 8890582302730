import { HubConnectionState } from '@microsoft/signalr';
import { DialogTitle, DownLoadIcon, Loader, NoConnectionIcon, RefreshIcon } from '@ondo-ui/components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../hooks';
import {
    Button,
    Dialog,
    DialogActions,
    dialogClasses,
    DialogContent,
    Stack,
    styled,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { msalInstance } from '~/auth';

export const ActionButton = styled(Button)(({ theme }) => ({
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 1.5),
    },
}));

const ServerConnectionDialog = styled(Dialog, {
    shouldForwardProp(propName) {
        return (
            propName !== 'connectionState' &&
            propName !== 'isInitialConnect' &&
            propName !== 'remainingSecondsUntilNextRetry'
        );
    },
})<{
    connectionState: HubConnectionState;
    isInitialConnect: boolean;
    remainingSecondsUntilNextRetry: number;
}>(({ theme, connectionState, isInitialConnect, remainingSecondsUntilNextRetry }) => {
    const getBorderColor = (): string => {
        if (
            (connectionState === HubConnectionState.Connecting && !isInitialConnect) ||
            (connectionState === HubConnectionState.Reconnecting && remainingSecondsUntilNextRetry <= 0)
        ) {
            return theme.palette.warning.main;
        } else if (connectionState === HubConnectionState.Reconnecting) {
            return theme.palette.warning.main;
        } else if (connectionState === HubConnectionState.Disconnected) {
            return theme.palette.error.main;
        } else {
            return 'transparent';
        }
    };

    return {
        [`& .${dialogClasses.paper}`]: {
            borderWidth: 2,
            borderStyle: 'solid',
            borderRadius: 4,
            borderColor: getBorderColor(),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
        },
    };
});

const ServerConnectionOverlay = () => {
    const { t } = useTranslation(['overlay', 'buttons']);
    const navigate = useNavigate();
    const clientState = useAppSelector(state => state.signalR.connectionState);
    const retryContext = useAppSelector(state => state.signalR.retryContext);
    const isInitialConnect = useAppSelector(state => state.signalR.isInitialConnect);
    const remainingSecondsUntilNextRetry = Math.round(retryContext.remainingMillisecondsUntilNextRetry / 1000);
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down(350));

    const renderContent = () => {
        if (
            (clientState === HubConnectionState.Connecting && !isInitialConnect) ||
            (clientState === HubConnectionState.Reconnecting && remainingSecondsUntilNextRetry <= 0)
        ) {
            return (
                <DialogContent>
                    <Loader color="primary" threshold={null} />
                    <Typography variant="subtitle2" color="secondary" align="center">
                        {t('socket.connecting')}
                    </Typography>
                </DialogContent>
            );
        } else if (clientState === HubConnectionState.Reconnecting) {
            return (
                <>
                    <DialogContent>
                        <Typography variant="subtitle2" color="secondary" align="center">
                            {t('socket.noConnection')}
                        </Typography>
                        <Typography variant="subtitle2" color="secondary" align="center">
                            {t('socket.retryIn', {
                                seconds: remainingSecondsUntilNextRetry,
                            })}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" size="large" onClick={() => navigate(0)}>
                            {t('reload', { ns: 'buttons' })}
                        </Button>
                    </DialogActions>
                </>
            );
        } else if (clientState === HubConnectionState.Disconnected) {
            return (
                <Stack spacing={3}>
                    <DialogTitle
                        sx={theme => ({
                            [theme.breakpoints.down('sm')]: { padding: theme.spacing(1, 0, 1, 0) },
                        })}
                        icon={
                            <NoConnectionIcon
                                color="error"
                                sx={{
                                    width: 32,
                                    height: 32,
                                }}
                            />
                        }
                    >
                        <Typography variant="h2">{t('title.noConnection')}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="subtitle2" color="secondary" align="center">
                            {t('socket.cannotConnect')}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Stack
                            direction={isSmallScreen ? 'column' : 'row'}
                            spacing={2}
                            flex={1}
                            sx={theme => ({
                                [theme.breakpoints.down(350)]: {
                                    rowGap: 2,
                                    '& > :not(:first-of-type)': {
                                        margin: 0,
                                    },
                                },
                            })}
                        >
                            <ActionButton
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={() => navigate(0)}
                                startIcon={<RefreshIcon />}
                            >
                                {t('reload', { ns: 'buttons' })}
                            </ActionButton>
                            <ActionButton
                                fullWidth
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={() => msalInstance.logoutRedirect()}
                                startIcon={
                                    <DownLoadIcon
                                        sx={{
                                            transform: 'rotate(-90deg)  ',
                                        }}
                                    />
                                }
                            >
                                {t('logout', { ns: 'buttons' })}
                            </ActionButton>
                        </Stack>
                    </DialogActions>
                </Stack>
            );
        }
    };

    const content = renderContent();

    return (
        <ServerConnectionDialog
            fullWidth
            open={Boolean(content)}
            connectionState={clientState}
            isInitialConnect={isInitialConnect}
            remainingSecondsUntilNextRetry={remainingSecondsUntilNextRetry}
            maxWidth="sm"
        >
            {content}
        </ServerConnectionDialog>
    );
};

export { ServerConnectionOverlay };
