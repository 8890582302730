import { AuthError } from '@azure/msal-browser';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { loginRequest, msalInstance, tokenRequest } from '../auth';
import { config } from './config';
import urlJoin from 'url-join';
import qs from 'query-string';
import { RootState } from './store';

const baseUrl = urlJoin(config.apiEndpoint.baseUrl, '/api');

async function addAccessTokenHeader(headers: Headers) {
    try {
        const { accessToken } = await msalInstance.acquireTokenSilent(tokenRequest);
        headers.set('Authorization', `Bearer ${accessToken}`);
        return headers;
    } catch (err) {
        if (err instanceof AuthError) {
            return msalInstance
                .acquireTokenRedirect({
                    ...loginRequest,
                    state: window.location.pathname,
                })
                .then(() => headers);
        }
        return headers;
    }
}

export const baseQuery = fetchBaseQuery({
    baseUrl,
    mode: 'cors',
    prepareHeaders: async (headers, api) => {
        const state = api.getState() as RootState;
        if (state.signalR.connectionId) {
            headers.set('X-Socket-ConnectionId', state.signalR.connectionId);
        }

        return await addAccessTokenHeader(headers);
    },
    paramsSerializer: params =>
        qs.stringify(params, {
            arrayFormat: 'bracket',
            skipEmptyString: true,
        }),
});
