import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AirUniformingStatus, CirculationFanStatus } from '../app/constants';
import { RootState } from '../app/store';
import { circulationFansApi } from '../services/circulationFans';

export type CirculationFanStateItem = OndoCloudState.CirculationFanState;
export type CirculationFanState = Record<string, CirculationFanStateItem>;

type AirUniformingIdlePayload = { status: AirUniformingStatus.Idle };
type AirUniformingWorkingOrRestingPayload = {
    status: AirUniformingStatus.Working | AirUniformingStatus.Resting;
} & OndoCloudEvents.CirculationFans.CirculationFanAirUniformingWorking;

export type SetCirculationFanAirUniformingStatusActionPayload =
    | {
          id: string;
      } & (AirUniformingIdlePayload | AirUniformingWorkingOrRestingPayload);

const initialState: CirculationFanState = {};

export const circulationFansSlice = createSlice({
    name: 'circulationFans',
    initialState,
    reducers: {
        sync(draft, { payload: circulationFans }: PayloadAction<OndoCloudState.RootState['circulationFanState']>) {
            for (const [id, circulationFan] of Object.entries(circulationFans)) {
                draft[id] = circulationFan;
            }
        },

        addCirculationFan(draft, { payload }: PayloadAction<{ id: string } & CirculationFanStateItem>) {
            const circulationFan = draft[payload.id];
            if (circulationFan) {
                return;
            }

            draft[payload.id] = payload;
        },

        removeCirculationFan(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setCirculationFanStatus(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.CirculationFans.CirculationFanStatusChanged>
        ) {
            const circulationFan = draft[payload.id];
            if (!circulationFan) {
                return;
            }

            circulationFan.status = payload.status;
            if (payload.status === CirculationFanStatus.Working || payload.status === CirculationFanStatus.Resting) {
                circulationFan.operationStartedAt = payload.operationStartedAt;
            } else {
                circulationFan.operationStartedAt = null;
            }
        },

        setCirculationFanPeriods(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.CirculationFans.CircFanClimateStrategyPeriodChange>
        ) {
            const circulationFan = draft[payload.id];
            if (!circulationFan) {
                return;
            }

            circulationFan.activePeriod = payload.activePeriod;
            circulationFan.nextPeriod = payload.nextPeriod;
        },

        setCirculationFanAirUniformingStatus(
            draft,
            { payload }: PayloadAction<SetCirculationFanAirUniformingStatusActionPayload>
        ) {
            const circulationFan = draft[payload.id];
            if (!circulationFan) {
                return;
            }

            if (payload.status === AirUniformingStatus.Idle) {
                if (circulationFan.airUniforming) {
                    circulationFan.airUniforming.status = payload.status;
                }
            } else {
                circulationFan.airUniforming = {
                    status: payload.status,
                    controlType: payload.controlType,
                    isFixedDuration: payload.isFixedDuration,
                    duration: payload.duration ?? 0,
                    workDuration: payload.workDuration,
                    restDuration: payload.restDuration,
                    startedAt: payload.operationStartedAt,
                    startSource: payload.startSource,
                    startValue: payload.startValue,
                    ventId: payload.ventId,
                };
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            circulationFansApi.endpoints.createCirculationFan.matchFulfilled,
            (draft, { payload: circulationFan }) => {
                draft[circulationFan.id] = {
                    id: circulationFan.id,
                    activePeriod: null,
                    nextPeriod: null,
                    operationStartedAt: null,
                    status: CirculationFanStatus.Idle,
                    fieldId: circulationFan.field.id,
                    controllerId: circulationFan.output.peripheral.controllerId,
                    airUniforming: null,
                };
            }
        );
        builder.addMatcher(
            circulationFansApi.endpoints.updateCirculationFan.matchFulfilled,
            (draft, { payload: circulationFan }) => {
                const currentCirculationFan = draft[circulationFan.id];
                if (!currentCirculationFan) {
                    return;
                }

                currentCirculationFan.status = CirculationFanStatus.Idle;
            }
        );
        builder.addMatcher(circulationFansApi.endpoints.deleteCirculationFan.matchFulfilled, (draft, { meta }) => {
            const circulationFanId = meta.arg.originalArgs.circFanId;
            delete draft[circulationFanId];
        });
    },
});

// Selectors

export const selectCirculationFans = (state: RootState) => state.circulationFans;

export const selectCirculationFanById = createSelector(
    selectCirculationFans,
    (_: RootState, id: string) => id,
    (circulationFans, id): CirculationFanStateItem | null => circulationFans[id]
);

export const selectCirculationFanStatus = createSelector(
    selectCirculationFanById,
    circulationFan => circulationFan?.status ?? CirculationFanStatus.Idle
);

export const selectCirculationFanCurrentActivePeriod = createSelector(
    selectCirculationFanById,
    (circulationFan): CirculationFanStateItem['activePeriod'] | null => circulationFan?.activePeriod ?? null
);

export const selectCirculationFanNextActivePeriod = createSelector(
    selectCirculationFanById,
    (circulationFan): CirculationFanStateItem['nextPeriod'] | null => circulationFan?.nextPeriod ?? null
);

export const selectCirculationFanIsInActivePeriod = createSelector(selectCirculationFanCurrentActivePeriod, period =>
    Boolean(period)
);

export const selectCirculationFanHasNextActivePeriod = createSelector(selectCirculationFanNextActivePeriod, period =>
    Boolean(period)
);

export const selectCirculationFanOperationStartedAt = createSelector(
    selectCirculationFanById,
    circulationFan => circulationFan?.operationStartedAt ?? null
);

export const selectCirculationFanAirUniforming = createSelector(
    selectCirculationFanById,
    circulationFan => circulationFan?.airUniforming ?? null
);

export const selectAirUniformingStartSource = createSelector(
    selectCirculationFanAirUniforming,
    airUniforming => airUniforming?.startSource ?? null
);

export const selectCirculationFanIsAirUniforming = createSelector(
    selectCirculationFanAirUniforming,
    af => af !== null && af.status !== AirUniformingStatus.Idle
);

export const {
    sync,
    addCirculationFan,
    removeCirculationFan,
    setCirculationFanStatus,
    setCirculationFanPeriods,
    setCirculationFanAirUniformingStatus,
} = circulationFansSlice.actions;
