import { AirBubbleJetStatus } from '../../app/constants';
import { setAirBubbleJetStatus } from '../../slices/airBubbleJetsSlice';
import { SignalRListener } from '../middleware';

const onABJStarted: SignalRListener<OndoCloudEvents.AirBubbleJets.AirBubbleJetStart> =
    e =>
    ({ dispatch }) => {
        dispatch(setAirBubbleJetStatus({ id: e.id, status: AirBubbleJetStatus.Working }));
    };

const onABJStopped: SignalRListener<OndoCloudEvents.AirBubbleJets.AirBubbleJetStop> =
    e =>
    ({ dispatch }) => {
        dispatch(setAirBubbleJetStatus({ id: e.id, status: AirBubbleJetStatus.Idle }));
    };

export const listeners = {
    AirBubbleJetStart: onABJStarted,
    AirBubbleJetStop: onABJStopped,
};
