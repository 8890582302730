import { createAsyncThunk, createSelector, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { msalInstance } from '../auth';
import type { UserRole } from '../app/constants';
import type { RootState } from '../app/store';
import { usersApi } from '~/services/users';

const activeAccount = msalInstance.getActiveAccount();

export interface User {
    id: string;
    /**
     * Full name
     */
    name: string;
    username: string;
    companyName: string;
    role: UserRole;
}

type AuthStatus = 'initial' | 'loggingIn' | 'loggingOut' | 'loggedIn' | 'loggedOut';

export interface AuthState {
    status: AuthStatus;
    user: User | null;
}

const user: User | null =
    activeAccount != null
        ? {
              id: activeAccount.localAccountId,
              username: activeAccount.username,
              name: activeAccount.name ?? '',
              companyName: activeAccount.idTokenClaims!.extension_ClientID as string,
              role: activeAccount.idTokenClaims!.extension_Role as UserRole,
          }
        : null;

const initialState: AuthState = {
    status: activeAccount ? 'loggedIn' : 'initial',
    user,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        initiateLogin(draft) {
            draft.status = 'loggingIn';
        },
        loginSuccess(draft, { payload }: PayloadAction<User>) {
            draft.status = 'loggedIn';
            draft.user = payload;
        },
        initiateLogout(draft) {
            draft.status = 'loggingOut';
        },
        logout(draft) {
            draft.status = 'loggedOut';
            draft.user = null;
        },
        setAccountName(draft, { payload }: PayloadAction<{ id: string; companyName: string }>) {
            if (draft.user == null || draft.user.id !== payload.id) return;

            draft.user.companyName = payload.companyName;
        },
        setAuthStatus(draft, { payload }: PayloadAction<{ status: AuthStatus }>) {
            draft.status = payload.status;
        },
    },
});

export const setLoginSuccess = createAsyncThunk('auth', async (paylaod: User, { dispatch }) => {
    dispatch(loginSuccess(paylaod));

    const userQuery = await dispatch(usersApi.endpoints.getUser.initiate({ id: paylaod.id }));
    dispatch(
        setAccountName({ id: userQuery.data?.b2cUserId ?? '', companyName: userQuery.data?.account.displayName ?? '' })
    );
});

// Selectors

export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectAccountName = (state: RootState) => state.auth.user?.companyName;
export const selectUserRole = createSelector(selectUser, user => user?.role ?? 'Viewer');
export const selectUserId = createSelector(selectUser, user => user?.id);

export const { initiateLogin, loginSuccess, initiateLogout, logout, setAccountName, setAuthStatus } = authSlice.actions;
