import { baseApi } from './baseApi';

export const thermalScreensApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getThermalScreens: build.query<OndoCloudSchemas.ThermalScreenDto[], { fieldId: string }>({
            query: ({ fieldId }) => `fields/${fieldId}/thermal-screens`,
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'ThermalScreens' as const, id })),
                          { type: 'ThermalScreens', id: 'LIST' },
                      ]
                    : [{ type: 'ThermalScreens', id: 'LIST' }],
        }),
        getThermalScreen: build.query<OndoCloudSchemas.ThermalScreenDto, { thermalScreenId: string; fieldId: string }>({
            query: ({ thermalScreenId, fieldId }) => `fields/${fieldId}/thermal-screens/${thermalScreenId}`,
            providesTags: result => (result ? [{ type: 'ThermalScreens' as const, id: result.id }] : []),
        }),
        createThermalScreen: build.mutation<
            OndoCloudSchemas.ThermalScreenDto,
            OndoCloudSchemas.CreateThermalScreenCommand & { fieldId: string }
        >({
            query: ({ fieldId, ...body }) => ({
                url: `fields/${fieldId}/thermal-screens`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'ThermalScreens', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        updateThermalScreen: build.mutation<
            OndoCloudSchemas.ThermalScreenDto,
            OndoCloudSchemas.EditThermalScreenCommand & { fieldId: string; thermalScreenId: string }
        >({
            query: ({ fieldId, thermalScreenId, ...body }) => ({
                url: `fields/${fieldId}/thermal-screens/${thermalScreenId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'ThermalScreens', id: arg.thermalScreenId },
                          { type: 'ThermalScreens', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        deleteThermalScreens: build.mutation<
            void,
            {
                thermalScreenId: string;
                fieldId: string;
            }
        >({
            query: ({ thermalScreenId, fieldId }) => ({
                url: `fields/${fieldId}/thermal-screens/${thermalScreenId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, { thermalScreenId, fieldId }) =>
                !error
                    ? [
                          { type: 'ThermalScreens', id: thermalScreenId },
                          { type: 'ThermalScreens', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: fieldId },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateThermalScreenMutation,
    useDeleteThermalScreensMutation,
    useGetThermalScreenQuery,
    useGetThermalScreensQuery,
    useUpdateThermalScreenMutation,
} = thermalScreensApi;
