import { baseApi } from '../../services/baseApi';
import { SignalRListener } from '../middleware';

const onSkippedProgramDueToSoilMoisture: SignalRListener<
    OndoCloudEvents.Schedules.ScheduleProgramSkippedSoilMoisture
> = e => api => {
    api.dispatch(baseApi.util.invalidateTags(e.fieldIds.map(id => ({ type: 'FieldSkippedProgram', id }))));
};

export const listeners = {
    ScheduleProgramSkippedSoilMoisture: onSkippedProgramDueToSoilMoisture,
};
