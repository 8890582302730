import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { sidemenuItems, type SidemenuItem } from '../app/navigation';
import { irrigationInfrastructuresApi } from '../services/irrigationInfrastructures';

type SortFn = (a: SidemenuItem, b: SidemenuItem) => number;
type AddItemPayload = {
    item: SidemenuItem;
    after?: {
        id?: SidemenuItem['id'];
        sortFn?: SortFn | undefined;
    };
    parentId?: SidemenuItem['id'];
};

function addSidemenuItem(draft: WritableDraft<SidemenuItem>[], item: SidemenuItem, after?: AddItemPayload['after']) {
    if (after?.id) {
        const insertIdx = draft.findIndex(item => item.id === after.id);
        draft.splice(insertIdx + 1, 0, castDraft(item));
    } else {
        draft.push(castDraft(item));
    }

    if (after?.sortFn) {
        draft.sort(after.sortFn);
    }
}

function removeSidemenuItem(draft: WritableDraft<SidemenuItem>[], id: string) {
    const newItems: SidemenuItem[] = [];
    for (const item of draft) {
        if (item.id === id) {
            continue;
        }

        let children = item.items;
        if (children) {
            children = children.filter(item => item.id !== id);
        }

        newItems.push(item);
    }

    return castDraft(newItems);
}

export const sidemenuSlice = createSlice({
    name: 'sidemenu',
    initialState: sidemenuItems,
    reducers: {
        addSidebarItem(draft, action: PayloadAction<AddItemPayload>) {
            const { item, parentId, after } = action.payload;
            if (parentId) {
                const parentItemIdx = draft.findIndex(item => item.id === parentId);
                const parent = draft[parentItemIdx];

                if (!parent.items) {
                    parent.items = [];
                }

                addSidemenuItem(parent.items, item, after);
            } else {
                addSidemenuItem(draft, item, after);
            }
        },
        removeSidebarItem(draft, action: PayloadAction<SidemenuItem['id']>) {
            const { payload: id } = action;
            return removeSidemenuItem(draft, id);
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            irrigationInfrastructuresApi.endpoints.deleteIrrigationInfrastructure.matchFulfilled,
            (draft, action) => {
                const id = action.meta.arg.originalArgs;
                return removeSidemenuItem(draft, id);
            }
        );
    },
});

export const { addSidebarItem, removeSidebarItem } = sidemenuSlice.actions;
