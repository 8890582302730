import { useInterval } from '@ondo-ui/hooks';
import dayjs from 'dayjs';
import { useState } from 'react';
import { msalInstance, tokenRequest } from '~/auth';
import { useAppDispatch } from '~/hooks';
import { SIGNALR_TOKEN_EXPERIATION_DATE } from '~/signalR/middleware';
import { keepAlive } from '~/slices/signalR';

export const useKeepSignalRAliveWorker = () => {
    const dispatch = useAppDispatch();
    const [nextTokenExperiationCheck, setNextTokenExperiationCheck] = useState(60 * 1000);

    useInterval(async () => {
        const currentTime = dayjs();
        const signalRHubTokenExperiesOn = localStorage.getItem(SIGNALR_TOKEN_EXPERIATION_DATE);
        const accessToken = await msalInstance.acquireTokenSilent(tokenRequest);
        const remainingTimeToTokenExperiation = dayjs(accessToken.expiresOn?.toISOString()).diff(
            currentTime.toISOString(),
            'milliseconds'
        );

        if (nextTokenExperiationCheck !== remainingTimeToTokenExperiation) {
            setNextTokenExperiationCheck(remainingTimeToTokenExperiation + 60 * 1000);
        }

        const isConnectionInSync = dayjs(accessToken.expiresOn?.toISOString()).isSame(signalRHubTokenExperiesOn);

        if (!isConnectionInSync) {
            setNextTokenExperiationCheck(remainingTimeToTokenExperiation + 60 * 1000);
            dispatch(keepAlive());
        }
    }, nextTokenExperiationCheck);
};
