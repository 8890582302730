import { config as appConfig } from '../app/config';
import { Configuration, RedirectRequest, SilentRequest } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: appConfig.auth.clientId,
        authority: appConfig.auth.authority,
        knownAuthorities: [appConfig.b2cPolicies.authorityDomain],
        redirectUri: '/',
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: appConfig.auth.postLogoutRedirectUri,
    },
    cache: {
        cacheLocation: 'localStorage',
    },
};

const defaultScopes = appConfig.auth.scope || [
    'https://myondo.onmicrosoft.com/ondo.api.shared.dev/api.all.read.write.web',
];

export const loginRequest: RedirectRequest = {
    scopes: defaultScopes,
};

export const tokenRequest: SilentRequest = {
    scopes: defaultScopes,
    forceRefresh: false,
};
