import { MainPumpStatus } from '../../app/constants';
import { setMainPumpStatus } from '../../slices/mainPumpsSlice';
import { SignalRListener } from '../middleware';

const onMainPumpStarted: SignalRListener<OndoCloudEvents.MainPump.MainPumpStart> =
    e =>
    ({ dispatch }) => {
        dispatch(setMainPumpStatus({ id: e.id, status: MainPumpStatus.Started }));
    };

const onMainPumpStopped: SignalRListener<OndoCloudEvents.MainPump.MainPumpStop> =
    e =>
    ({ dispatch }) => {
        dispatch(setMainPumpStatus({ id: e.id, status: MainPumpStatus.Stopped }));
    };

export const listeners = {
    MainPumpStart: onMainPumpStarted,
    MainPumpStop: onMainPumpStopped,
};
