import { sortByNameAsc } from '../utils/sort';
import { baseApi } from './baseApi';

export const weatherStationsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getWeatherStations: build.query<OndoCloudSchemas.WeatherStationDto[], Partial<{ controllerId: string }> | void>(
            {
                query: params => ({
                    url: 'weather-stations',
                    method: 'GET',
                    params: params ?? undefined,
                }),
                transformResponse: (result: OndoCloudSchemas.WeatherStationDto[]) => {
                    return result.sort(sortByNameAsc);
                },
                providesTags: result =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: 'WeatherStations' as const, id })),
                              { type: 'WeatherStations', id: 'LIST' },
                          ]
                        : [{ type: 'WeatherStations', id: 'LIST' }],
            }
        ),

        getWeatherStation: build.query<OndoCloudSchemas.WeatherStationDto, { id: string }>({
            query: ({ id }) => `weather-stations/${id}`,
            providesTags: result => (result ? [{ type: 'WeatherStations' as const, id: result.id }] : []),
        }),

        createWeatherStation: build.mutation<
            OndoCloudSchemas.WeatherStationDto,
            OndoCloudSchemas.CreateWeatherStationCommand
        >({
            query: body => ({
                url: 'weather-stations',
                method: 'POST',
                body,
            }),
            invalidatesTags: result => {
                if (!result) return [];

                let invalidateItems = [
                    { type: 'WeatherStations' as const, id: 'LIST' },
                    { type: 'InputOutputs' as const, id: 'LIST' },
                    { type: 'Assets' as const, id: 'LIST' },
                ];

                if (result.connection) {
                    invalidateItems.push({ type: 'InputOutputs' as const, id: result.connection.id });
                }
                return invalidateItems;
            },
        }),

        updateWeatherStation: build.mutation<
            OndoCloudSchemas.WeatherStationDto,
            OndoCloudSchemas.EditWeatherStationCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `weather-stations/${id}`,
                method: 'PUT',
                body: { ...body, id },
            }),
            invalidatesTags: (result, _, arg) => {
                if (!result) return [];

                const connectionId = arg.connectionId;

                return connectionId !== undefined && connectionId !== null
                    ? [
                          { type: 'WeatherStations', id: arg.id },
                          { type: 'WeatherStations', id: 'LIST' },
                          { type: 'InputOutputs' as const, id: 'LIST' },
                          { type: 'InputOutputs' as const, id: connectionId },
                      ]
                    : [
                          { type: 'WeatherStations', id: arg.id },
                          { type: 'WeatherStations', id: 'LIST' },
                          { type: 'InputOutputs' as const, id: 'LIST' },
                      ];
            },
        }),

        deleteWeatherStation: build.mutation<void, string>({
            query: id => ({
                url: `weather-stations/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, id) =>
                !error
                    ? [
                          { type: 'WeatherStations', id },
                          { type: 'WeatherStations', id: 'LIST' },
                          { type: 'InputOutputs' as const, id: 'LIST' },
                          { type: 'Assets', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetWeatherStationsQuery,
    useGetWeatherStationQuery,
    useCreateWeatherStationMutation,
    useUpdateWeatherStationMutation,
    useDeleteWeatherStationMutation,
} = weatherStationsApi;
