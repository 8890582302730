/**
 * This file only exists to re-export the globally defined config.js file.
 * This is to prevent any overwrites in the global file by malicious scripts
 * or simply due to developer inadequacies (:wink:).
 */

if (!window.ONDO_CONFIG) {
    throw new Error('Failed to load config file! Please check if public/config.js exists and is configured properly!');
}

const config: IONDOConfig = JSON.parse(JSON.stringify(window.ONDO_CONFIG));

delete window.ONDO_CONFIG;

export { config };
