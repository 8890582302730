import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { irrigationInfrastructuresApi } from '../services/irrigationInfrastructures';
import waterMetersApi from '../services/waterMeters';
import { groupById } from '../utils/object';
import { RootState } from '../app/store';
import { volume } from '@ondo-ui/utils';

type WatermeterStateItem = Omit<OndoCloudState.WatermeterState, 'controllerId'>;
export type WatermetersState = Record<string, WatermeterStateItem>;

const initialState: WatermetersState = {};

export const watermetersSlice = createSlice({
    name: 'watermeters',
    initialState,
    reducers: {
        sync(draft, { payload: watermeters }: PayloadAction<OndoCloudState.RootState['watermetersState']>) {
            draft = groupById(watermeters.map(formatWatermeterState));
            return draft;
        },

        setLitersAndFlowRate(
            draft,
            { payload }: PayloadAction<Pick<WatermeterStateItem, 'id' | 'flowRate' | 'flowRate_m3h' | 'liters'>>
        ) {
            const watermeter = draft[payload.id];
            if (!watermeter) {
                return;
            }

            watermeter.liters = volume.fromLitersToM3(payload.liters);
            watermeter.flowRate = payload.flowRate;
            watermeter.flowRate_m3h = payload.flowRate_m3h;
        },
        setWaterMeterCalculatingStatus(
            draft,
            { payload }: PayloadAction<{ isFlowRateCalculating: boolean; id: string }>
        ) {
            const watermeter = draft[payload.id];
            if (!watermeter) {
                return;
            }

            watermeter.isFlowRateCalculating = payload.isFlowRateCalculating;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            waterMetersApi.endpoints.getWaterMeters.matchFulfilled,
            (draft, { payload: watermeters }) => {
                watermeters.forEach(watermeter => {
                    addWatermeterToState(draft, watermeter);
                });
            }
        );

        builder.addMatcher(waterMetersApi.endpoints.getWaterMeter.matchFulfilled, (draft, { payload: watermeter }) => {
            addWatermeterToState(draft, watermeter);
        });

        builder.addMatcher(waterMetersApi.endpoints.addWaterMeter.matchFulfilled, (draft, { payload: watermeter }) => {
            addWatermeterToState(draft, watermeter);
        });

        builder.addMatcher(
            waterMetersApi.endpoints.updateWaterMeter.matchFulfilled,
            (draft, { payload: watermeter }) => {
                const currentLiters = draft[watermeter.id]?.liters ?? 0;
                draft[watermeter.id] = {
                    ...transformWatermeterToStateForm(watermeter),
                    liters: currentLiters,
                };
            }
        );

        builder.addMatcher(waterMetersApi.endpoints.deleteWaterMeter.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.waterMeterId;
            delete draft[id];
        });

        builder.addMatcher(
            irrigationInfrastructuresApi.endpoints.getIrrigationInfrastructures.matchFulfilled,
            (draft, { payload: irrInfras }) => {
                irrInfras.forEach(infra => {
                    infra.irrigation.watermeters.forEach(watermeter => {
                        addWatermeterToState(draft, watermeter);
                    });
                });
            }
        );

        builder.addMatcher(
            irrigationInfrastructuresApi.endpoints.getIrrigationInfrastructure.matchFulfilled,
            (draft, { payload: infra }) => {
                infra.irrigation.watermeters.forEach(watermeter => {
                    addWatermeterToState(draft, watermeter);
                });
            }
        );
    },
});

function addWatermeterToState(draft: WritableDraft<WatermetersState>, watermeter: OndoCloudSchemas.WatermeterDto) {
    if (draft[watermeter.id]) {
        return;
    }

    draft[watermeter.id] = transformWatermeterToStateForm(watermeter);
}

function transformWatermeterToStateForm(watermeter: OndoCloudSchemas.WatermeterDto): WatermeterStateItem {
    return {
        id: watermeter.id,
        irrigationInfrastructureId: watermeter.irrigationInfrastructure.id,
        name: watermeter.name,
        flowRate: 0,
        flowRate_m3h: 0,
        liters: 0,
        isFlowRateCalculating: false,
    };
}

function formatWatermeterState(watermeter: OndoCloudState.WatermeterState): WatermeterStateItem {
    const { controllerId, ...newWatermeter } = watermeter;
    newWatermeter.liters = volume.fromLitersToM3(newWatermeter.liters);
    return newWatermeter;
}

// Selectors

export function selectWaterMeters(state: RootState) {
    return state.watermeters;
}

export const selectWaterMeterById = createSelector(
    selectWaterMeters,
    (_: RootState, id: string) => id,
    (waterMeter, id) => waterMeter[id]
);

export const selectWaterMeterLiters = createSelector(selectWaterMeterById, waterMeter => waterMeter?.liters ?? null);
export const selectWaterMeterFlowRate_m3h = createSelector(
    selectWaterMeterById,
    waterMeter => waterMeter?.flowRate_m3h ?? null
);
export const selectWaterMeterIsFlowRateCalculating = createSelector(
    selectWaterMeterById,
    waterMeter => waterMeter?.isFlowRateCalculating ?? null
);

export const { sync, setLitersAndFlowRate, setWaterMeterCalculatingStatus } = watermetersSlice.actions;
