import { createContext, ReactNode, Dispatch, useState, SetStateAction, useMemo } from 'react';
import { DeleteDialog, DeleteDialogProps } from '../../components/dialog/delete-dialog/DeleteDialog';
import { noop } from '../../utils/noop';

export type DeleteDialogState = DeleteDialogProps;
export type DeleteDialogContextValue = readonly [DeleteDialogState, Dispatch<SetStateAction<DeleteDialogState>>];

export const initialState: DeleteDialogState = {
    open: false,
    name: '',
    isError: false,
    isLoading: false,
    isSuccess: false,
    onConfirm: noop,
    onClose: noop,
    onSuccess: noop,
};

export const DeleteDialogContext = createContext<DeleteDialogContextValue>([initialState, noop]);

DeleteDialogContext.displayName = 'DeleteDialogContext';

export interface DeleteDialogProviderProps {
    children: ReactNode;
}

const DeleteDialogProvider = ({ children }: DeleteDialogProviderProps) => {
    const [dialogState, setDialogState] = useState<DeleteDialogState>(initialState);

    const contextValue = useMemo(() => {
        return [dialogState, setDialogState] as const;
    }, [dialogState, setDialogState]);

    return (
        <DeleteDialogContext.Provider value={contextValue}>
            <DeleteDialog />
            {children}
        </DeleteDialogContext.Provider>
    );
};

export { DeleteDialogProvider };
