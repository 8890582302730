import { SignalRListener } from '../middleware/listeners';
import {
    setMistingLineExtremeCondition,
    setMistingLinePeriods,
    setMistingLineStatus,
} from '../../slices/mistingLinesSlice';

const onMistingLineStatusChanged: SignalRListener<OndoCloudEvents.MistingLines.MistingLineStatusChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setMistingLineStatus(e));
    };

const onMistingLinePeriodsChanged: SignalRListener<
    OndoCloudEvents.MistingLines.MistingLineClimateStrategyPeriodChange
> =
    e =>
    ({ dispatch }) => {
        dispatch(setMistingLinePeriods(e));
    };

const onExtremeConditionChanged: SignalRListener<OndoCloudEvents.MistingLines.MistingLineExtremeConditionChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setMistingLineExtremeCondition(e));
    };

export const listeners = {
    MistingLineStatusChange: onMistingLineStatusChanged,
    MistingLineClimateStrategyPeriodChange: onMistingLinePeriodsChanged,
    MistingLineExtremeConditionChange: onExtremeConditionChanged,
};
