import { type SupportedLanguageCode } from '../app/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/bg';

export function languageCodeToDayjsLocale(code: SupportedLanguageCode): string {
    switch (code) {
        case 'en-en': {
            return 'en';
        }

        case 'bg-bg': {
            return 'bg';
        }

        default: {
            return 'en';
        }
    }
}

export function useLocalizedDayjs() {
    const { i18n } = useTranslation();

    return useMemo(() => {
        const locale = languageCodeToDayjsLocale(i18n.language as unknown as SupportedLanguageCode);
        dayjs.locale(locale);
        return dayjs;
    }, [i18n.language]);
}
