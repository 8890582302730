import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { weatherStationsApi } from '../services/weatherStations';

const initialState: Record<string, OndoCloudState.WeatherStationState> = {};

export const weatherStationsSlice = createSlice({
    name: 'weatherStations',
    initialState,
    reducers: {
        sync(
            draft,
            { payload: weatherStationsById }: PayloadAction<Record<string, OndoCloudSchemas.WeatherStationStateResp>>
        ) {
            for (const weatherStation of Object.values(weatherStationsById)) {
                draft[weatherStation.id] = {
                    id: weatherStation.id,
                    controllerId: weatherStation.controllerId,
                    lastUpdatedAt: weatherStation.lastUpdatedAt,
                    temperature: weatherStation.temperature ?? 0,
                    humidity: weatherStation.humidity ?? 0,
                    rainFall: weatherStation.rainfall ?? false,
                    rainQuantity: weatherStation.rainQuantity ?? 0,
                    rainQuantityLast24Hours: weatherStation.rainQuantityLast24Hours ?? null,
                    sunRadiation: weatherStation.sunRadiation ?? 0,
                    windDirection: weatherStation.windDirection ?? 0,
                    windSpeed: weatherStation.windSpeed ?? 0,
                    leafWetness: weatherStation.leafWetness ?? 0,
                    dewPoint: weatherStation.dewPoint ?? 0,
                    soilMoisture: weatherStation.soilMoisture ?? 0,
                    soilTemperature: weatherStation.soilTemperature ?? 0,
                    windGust: weatherStation.windGust ?? 0,
                    batteryLevel: weatherStation.batteryLevel ?? 0,
                    atmosphericPressure: weatherStation.atmosphericPressure ?? 0,
                };
            }
        },

        setLastReadingAt(draft, { payload }: PayloadAction<OndoCloudEvents.WeatherStations.WeatherStationDataUpdated>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].lastUpdatedAt = payload.updatedAt;
        },

        setTemperature(draft, { payload }: PayloadAction<{ id: string; temperature: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].temperature = payload.temperature;
        },

        setHumidity(draft, { payload }: PayloadAction<{ id: string; humidity: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].humidity = payload.humidity;
        },

        setRainQuantity(draft, { payload }: PayloadAction<{ id: string; rainQuantity: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].rainQuantity = payload.rainQuantity;
        },

        setRainQuantityLast24h(draft, { payload }: PayloadAction<{ id: string; rainQuantity: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].rainQuantityLast24Hours = payload.rainQuantity;
        },

        setRainFall(draft, { payload }: PayloadAction<{ id: string; rainFall: boolean }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].rainFall = payload.rainFall;
        },

        setWindDirection(draft, { payload }: PayloadAction<{ id: string; windDirection: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].windDirection = payload.windDirection;
        },

        setWindSpeed(draft, { payload }: PayloadAction<{ id: string; windSpeed: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].windSpeed = payload.windSpeed;
        },

        setSunRadiation(draft, { payload }: PayloadAction<{ id: string; sunRadiation: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].sunRadiation = payload.sunRadiation;
        },

        setLeafWetness(draft, { payload }: PayloadAction<{ id: string; leafWetness: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].leafWetness = payload.leafWetness;
        },

        setWindGust(draft, { payload }: PayloadAction<{ id: string; windGust: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].windGust = payload.windGust;
        },

        setBatteryLevel(draft, { payload }: PayloadAction<{ id: string; batteryLevel: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].batteryLevel = payload.batteryLevel;
        },

        setAtmosphericPressure(draft, { payload }: PayloadAction<{ id: string; atmosphericPressure: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].atmosphericPressure = payload.atmosphericPressure;
        },

        setSoilTemperature(draft, { payload }: PayloadAction<{ id: string; soilTemperature: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].soilTemperature = payload.soilTemperature;
        },

        setSoilMoisture(draft, { payload }: PayloadAction<{ id: string; soilMoisture: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].soilMoisture = payload.soilMoisture;
        },

        setDewPoint(draft, { payload }: PayloadAction<{ id: string; dewPoint: number }>) {
            if (!draft[payload.id]) {
                return;
            }
            draft[payload.id].dewPoint = payload.dewPoint;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            weatherStationsApi.endpoints.createWeatherStation.matchFulfilled,
            (draft, { payload: weatherStation }) => {
                if (draft[weatherStation.id]) {
                    return;
                }
                draft[weatherStation.id] = {
                    id: weatherStation.id,
                    controllerId: weatherStation.connection?.peripheral?.controllerId,
                    lastUpdatedAt: null,
                    temperature: 0,
                    humidity: 0,
                    rainQuantity: 0,
                    rainFall: false,
                    sunRadiation: 0,
                    windDirection: 0,
                    windSpeed: 0,
                    rainQuantityLast24Hours: null,
                    leafWetness: 0,
                    dewPoint: 0,
                    soilMoisture: 0,
                    soilTemperature: 0,
                    batteryLevel: 0,
                    windGust: 0,
                    atmosphericPressure: 0,
                };
            }
        );

        builder.addMatcher(
            weatherStationsApi.endpoints.updateWeatherStation.matchFulfilled,
            (draft, { payload: weatherStation }) => {
                const currentWeatherStation = draft[weatherStation.id];
                if (!currentWeatherStation || !weatherStation.connection) {
                    return;
                }
                currentWeatherStation.controllerId = weatherStation.connection.peripheral?.controllerId;
            }
        );

        builder.addMatcher(weatherStationsApi.endpoints.deleteWeatherStation.matchFulfilled, (draft, { meta }) => {
            delete draft[meta.arg.originalArgs];
        });
    },
});

// Selectors

export const selectWeatherStations = (state: RootState) => state.weatherStations;
export const selectWeatherStationById = createSelector(
    selectWeatherStations,
    (_: RootState, id: string) => id,
    (weatherStations, id: string): OndoCloudState.WeatherStationState | null => weatherStations[id]
);

export const selectWeatherStationLastUpdatedAt = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.lastUpdatedAt
);

export const selectWeatherStationTemperature = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.temperature
);

export const selectWeatherStationHumidity = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.humidity
);

export const selectWeatherStationWindSpeed = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.windSpeed
);

export const selectWeatherStationWindDirection = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.windDirection
);

export const selectWeatherStationRainFall = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.rainFall
);

export const selectWeatherStationRainQuantity = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.rainQuantity
);

export const selectWeatherStationSunRadiation = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.sunRadiation
);

export const selectWeatherStationRainQuantityLast24h = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.rainQuantityLast24Hours
);

export const selectWeatherStationLeafWetness = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.leafWetness
);

export const selectWeatherStationWindGust = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.windGust
);

export const selectWeatherStationSoilTemperature = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.soilTemperature
);

export const selectWeatherStationSoilMoisture = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.soilMoisture
);

export const selectWeatherStationDewPoint = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.dewPoint
);

export const selectWeatherStationBattery = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.batteryLevel
);

export const selectWeatherStationAtmosphericPressure = createSelector(
    selectWeatherStationById,
    weatherStation => weatherStation?.atmosphericPressure
);

export const {
    sync,
    setTemperature,
    setHumidity,
    setLastReadingAt,
    setRainFall,
    setRainQuantity,
    setRainQuantityLast24h,
    setWindDirection,
    setWindSpeed,
    setSunRadiation,
    setLeafWetness,
    setWindGust,
    setBatteryLevel,
    setAtmosphericPressure,
    setSoilMoisture,
    setSoilTemperature,
    setDewPoint,
} = weatherStationsSlice.actions;
