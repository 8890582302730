import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AirBubbleJetValveStatus } from '../app/constants';
import { RootState } from '../app/store';
import { dosingChannelsApi } from '../services/dosingChannels';

export type AirBubbleJetValveStateItem = Omit<OndoCloudState.AirBubbleJetValveState, 'controllerId'>;
export type AirBubbleJetValve = Record<string, AirBubbleJetValveStateItem>;

const initialState: AirBubbleJetValve = {};

export const airBubbleJetValvesSlice = createSlice({
    name: 'airBubbleJetValves',
    initialState,
    reducers: {
        sync(
            draft,
            { payload: airBubbleJetValves }: PayloadAction<OndoCloudState.RootState['airBubbleJetValvesState']>
        ) {
            for (const [id, valve] of Object.entries(airBubbleJetValves)) {
                draft[id] = {
                    status: valve.status,
                    irrigationInfrastructureId: valve.irrigationInfrastructureId,
                };
            }
        },
        addAirBubbleJetValve(draft, { payload }: PayloadAction<{ id: string } & AirBubbleJetValveStateItem>) {
            const airBubbleJetValve = draft[payload.id];
            if (airBubbleJetValve) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeAirBubbleJetValve(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setAirBubbleJetValveStatus(draft, { payload }: PayloadAction<{ id: string; status: AirBubbleJetValveStatus }>) {
            const airBubbleJetValve = draft[payload.id];
            if (!airBubbleJetValve) {
                return;
            }

            airBubbleJetValve.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            dosingChannelsApi.endpoints.createDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                if (dosingChannel.airBubbleJetValve) {
                    draft[dosingChannel.airBubbleJetValve.id] = {
                        status: AirBubbleJetValveStatus.Closed,
                        irrigationInfrastructureId: dosingChannel.irrigationInfrastructure.id,
                    };
                }
            }
        );

        builder.addMatcher(
            dosingChannelsApi.endpoints.updateDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                if (dosingChannel.airBubbleJetValve) {
                    draft[dosingChannel.airBubbleJetValve.id] = {
                        status: AirBubbleJetValveStatus.Closed,
                        irrigationInfrastructureId: dosingChannel.irrigationInfrastructure.id,
                    };
                }
            }
        );

        builder.addMatcher(dosingChannelsApi.endpoints.deleteDosingChannel.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

export function selectAirBubbleJetValves(state: RootState) {
    return state.airBubbleJetValves;
}

export const selectAirBubbleJetValveById = createSelector(
    selectAirBubbleJetValves,
    (_: RootState, id: string) => id,
    (airBubbleJetValve, id) => {
        return airBubbleJetValve[id];
    }
);

export const selectAirBubbleJetValveStatus = createSelector(
    selectAirBubbleJetValveById,
    airBubbleJetValve => airBubbleJetValve?.status
);

export const { addAirBubbleJetValve, removeAirBubbleJetValve, setAirBubbleJetValveStatus, sync } =
    airBubbleJetValvesSlice.actions;
