import { alarmsHistoryApi } from '../../services/alarmsHistory';
import { addAlarm, acknowledgeAlarm, clearAlarm, dismissAlarm } from '../../slices/alarmSlice';
import { SignalRListener } from '../middleware/listeners';

const onAlarmRaised: SignalRListener<OndoCloudEvents.Alarms.AlarmRaised> =
    e =>
    async ({ dispatch }) => {
        const response = await dispatch(alarmsHistoryApi.endpoints.getAlarmHistory.initiate({ id: e.id }));
        if (!response.data) {
            return;
        }

        dispatch(addAlarm({ ...response.data, isAcknowledgedByCurrentUser: false }));
    };

const onAlarmCleared: SignalRListener<OndoCloudEvents.Alarms.AlarmCleared> =
    e =>
    ({ dispatch }) => {
        dispatch(clearAlarm(e.id));
    };

const onAlarmDismissed: SignalRListener<OndoCloudEvents.Alarms.AlarmDismissed> =
    e =>
    ({ dispatch }) => {
        dispatch(dismissAlarm(e.id));
    };

const onAlarmAcknowledged: SignalRListener<OndoCloudEvents.Alarms.AlarmAcknowledged> =
    e =>
    ({ dispatch }) => {
        dispatch(acknowledgeAlarm(e.id));
    };

export const listeners = {
    AlarmRaised: onAlarmRaised,
    AlarmCleared: onAlarmCleared,
    AlarmDismissed: onAlarmDismissed,
    AlarmAcknowledged: onAlarmAcknowledged,
};
