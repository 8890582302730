import { MsalProvider as AzureMsalProvider } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { msalInstance, CustomNavigationClient } from '../../auth';

export interface MsalCustomNavigationProviderProps {
    children: React.ReactNode;
}

const MsalProvider = ({ children }: MsalCustomNavigationProviderProps): JSX.Element => {
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    msalInstance.setNavigationClient(navigationClient);
    return <AzureMsalProvider instance={msalInstance}>{children}</AzureMsalProvider>;
};

export { MsalProvider };
