import { createContext, ReactNode, Dispatch, useCallback, useState, SetStateAction, useMemo } from 'react';
import { Dialog, DialogProps } from '../../components/dialog/Dialog';
import { noop } from '../../utils/noop';

export type DialogContextValue = readonly [DialogProps, Dispatch<SetStateAction<DialogProps>>];

export const initialState: DialogProps = {
    type: 'generic',
    open: false,
    message: '',
    onClose: noop,
};

export const DialogContext = createContext<DialogContextValue>([initialState, noop]);

DialogContext.displayName = 'DialogContext';

export interface DialogProviderProps {
    children: ReactNode;
}

const DialogProvider = ({ children }: DialogProviderProps) => {
    const [dialogState, setDialogState] = useState<DialogProps>(initialState);

    const handleClose = useCallback(() => {
        dialogState.onClose();
        setDialogState(prev => ({
            ...prev,
            open: false,
        }));
    }, [dialogState]);

    const contextValue = useMemo(() => {
        return [dialogState, setDialogState] as const;
    }, [dialogState, setDialogState]);

    return (
        <DialogContext.Provider value={contextValue}>
            <Dialog {...dialogState} onClose={handleClose} />
            {children}
        </DialogContext.Provider>
    );
};

export { DialogProvider };
