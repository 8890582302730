import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FrostProtectionDeviceStatus, FrostProtectionMode } from '../app/constants';
import { RootState } from '../app/store';
import { frostProtectionDevicesApi } from '../services/frostProtectionDevices';

export type FrostProtectionDeviceStateItem = Omit<
    OndoCloudState.FrostProtectionDeviceState,
    'name' | 'flowRate' | 'strategy'
> & { strategy: OndoCloudState.FrostProtectionDeviceState['strategy'] | null };
export type FrostProtectionDevicesState = Record<string, FrostProtectionDeviceStateItem>;

const initialState: FrostProtectionDevicesState = {};

export const frostProtectionDevicesSlice = createSlice({
    name: 'frostProtectionDevices',
    initialState,
    reducers: {
        sync(draft, { payload }: PayloadAction<OndoCloudState.RootState['frostProtectionState']>) {
            for (const fpd of Object.values(payload)) {
                draft[fpd.id] = {
                    id: fpd.id,
                    controllerId: fpd.controllerId,
                    status: fpd.status,
                    statusPump: fpd.statusPump,
                    operationStartedAt: fpd.operationStartedAt,
                    strategy: fpd.strategy,
                };
            }
        },

        addFrostProtectionDevice(draft, { payload }: PayloadAction<OndoCloudState.FrostProtectionDeviceState>) {
            const fpd = draft[payload.id];

            if (fpd) {
                return;
            }

            draft[payload.id] = { ...payload, status: FrostProtectionDeviceStatus.Idle };
        },

        removeFrostProtectionDevice(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setFrostProtectionDeviceStatus(
            draft,
            { payload }: PayloadAction<{ id: string; status: FrostProtectionDeviceStatus }>
        ) {
            const fpd = draft[payload.id];
            if (!fpd) {
                return;
            }

            fpd.status = payload.status;
        },
        setFrostProtectionStartTime(draft, { payload }: PayloadAction<{ id: string; startedAt: string }>) {
            const fpd = draft[payload.id];
            if (!fpd) {
                return;
            }

            fpd.operationStartedAt = payload.startedAt;
        },
        setFrostProtectionStrategy(
            draft,
            { payload }: PayloadAction<{ strategy: OndoCloudState.FrostProtectionDeviceState['strategy'] }>
        ) {
            const fpd = draft[payload.strategy.id];

            if (!fpd) {
                return;
            }

            fpd.strategy = payload.strategy;
        },
        setFrostProtectionStrategyMode(draft, { payload }: PayloadAction<{ mode: FrostProtectionMode; id: string }>) {
            const fpd = draft[payload.id];

            if (!fpd || !fpd.strategy) {
                return;
            }

            fpd.strategy.mode = payload.mode;
        },
        setFrostProtectionAutomaticSettings(
            draft,
            {
                payload,
            }: PayloadAction<{ settings: OndoCloudSchemas.FrostProtectionStrategyAutoSettingsStateResp; id: string }>
        ) {
            const fpd = draft[payload.id];

            if (!fpd || !fpd.strategy) {
                return;
            }

            fpd.strategy.automaticSettings = payload.settings;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            frostProtectionDevicesApi.endpoints.deleteFrostProtectionDevice.matchFulfilled,
            (draft, { meta }) => {
                const id = meta.arg.originalArgs.id;
                delete draft[id];
            }
        );
    },
});

// Selectors

function selectFrostProtectionDevices(state: RootState) {
    return state.frostProtectionDevices;
}

export const selectFrostProtectionDeviceById = createSelector(
    selectFrostProtectionDevices,
    (_: RootState, id: string) => id,
    (fpds, id) => fpds[id]
);

export const selectFrostProtectionDeviceStatus = createSelector(selectFrostProtectionDeviceById, fpd => fpd?.status);

export const selectFrostProtectionStrategy = createSelector(selectFrostProtectionDeviceById, fpd => fpd?.strategy);

export const selectFrostProtectionDeviceMode = createSelector(
    selectFrostProtectionStrategy,
    strategy => strategy?.mode ?? FrostProtectionMode.Disabled
);

export const selectFrostProtectionStartTime = createSelector(
    selectFrostProtectionDeviceById,
    fpd => fpd?.operationStartedAt
);

export const selectFrostProtectionAutomaticSettings = createSelector(
    selectFrostProtectionStrategy,
    strategy => strategy?.automaticSettings
);

export const selectFrostProtectionStartTemperature = createSelector(
    selectFrostProtectionAutomaticSettings,
    settings => settings?.startTemperature
);
export const selectFrostProtectionStopTemperature = createSelector(
    selectFrostProtectionAutomaticSettings,
    settings => settings?.stopTemperature
);
export const selectFrostProtectionWeatherStation = createSelector(
    selectFrostProtectionAutomaticSettings,
    settings => settings?.weatherStationId
);

export const {
    sync,
    addFrostProtectionDevice,
    removeFrostProtectionDevice,
    setFrostProtectionDeviceStatus,
    setFrostProtectionStrategy,
    setFrostProtectionStrategyMode,
    setFrostProtectionAutomaticSettings,
    setFrostProtectionStartTime,
} = frostProtectionDevicesSlice.actions;
