import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SensorStatus } from '../app/constants';
import { RootState } from '../app/store';
import { sensorsApi } from '../services/sensors';

export type SensorStateItem = Omit<
    OndoCloudState.SensorState,
    'sensorModel' | 'controllerId' | 'error' | 'id' | 'sensorParameters' | 'lastReadingAt'
> & { sensorParameters: SensorParametersById; lastReadingAt: string | null };
export type SensorState = Record<string, SensorStateItem>;
export type SensorParametersById = Record<string, Omit<OndoCloudSchemas.SensorParameterStateResp, 'status'>>;

const initialState: SensorState = {};

export function groupSensorParametersById(
    sensorParameters: Omit<OndoCloudSchemas.SensorParameterStateResp, 'status'>[]
): SensorParametersById {
    return sensorParameters.reduce<SensorParametersById>((result, prev) => {
        if (!result[prev.id]) {
            result[prev.id] = prev;
        }

        return result;
    }, {});
}

export const sensorsSlice = createSlice({
    name: 'sensors',
    initialState,
    reducers: {
        sync(draft, { payload: sensors }: PayloadAction<OndoCloudState.RootState['sensorState']>) {
            const syncedState = { ...draft };

            Object.entries(sensors).forEach(([id, sensor]) => {
                syncedState[id] = {
                    status: sensor.status,
                    lastReadingAt: sensor.lastReadingAt ?? null,
                    sensorParameters: groupSensorParametersById(sensor.sensorParameters),
                };
            });

            return syncedState;
        },
        addSensor(draft, { payload }: PayloadAction<{ id: string } & SensorStateItem>) {
            const sensor = draft[payload.id];
            if (sensor) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                lastReadingAt: payload.lastReadingAt,
                sensorParameters: payload.sensorParameters,
            };
        },
        removeSensor(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },
        setSensorStatus(draft, { payload }: PayloadAction<{ sensorId: string; status: SensorStatus }>) {
            const sensor = draft[payload.sensorId];
            if (!sensor) {
                return;
            }

            sensor.status = payload.status;
        },
        setSensorValue(draft, { payload }: PayloadAction<{ sensorId: string; parameterId: string; value: number }>) {
            const sensorParameter = draft[payload.sensorId]?.sensorParameters[payload.parameterId];
            if (!sensorParameter) {
                return;
            }

            sensorParameter.measuredValue = payload.value;
        },
        setSensorLastReadingTime(draft, { payload }: PayloadAction<{ sensorId: string; lastReadingAt: string }>) {
            const sensor = draft[payload.sensorId];
            if (!sensor) {
                return;
            }

            sensor.lastReadingAt = payload.lastReadingAt;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(sensorsApi.endpoints.createSensor.matchFulfilled, (draft, { payload: sensor }) => {
            draft[sensor.id] = {
                lastReadingAt: null,
                sensorParameters: groupSensorParametersById(sensor.sensorParameters),
                status: SensorStatus.Disconnected,
            };
        });
        builder.addMatcher(sensorsApi.endpoints.updateSensor.matchFulfilled, (draft, { payload: sensor }) => {
            const currentSensor = draft[sensor.id];
            if (!currentSensor) {
                return;
            }

            currentSensor.sensorParameters = groupSensorParametersById(sensor.sensorParameters);
        });
        builder.addMatcher(sensorsApi.endpoints.deleteSensor.matchFulfilled, (draft, { meta }) => {
            const sensorId = meta.arg.originalArgs.sensorId;
            delete draft[sensorId];
        });
    },
});

// Selectors

export const selectSensors = (state: RootState) => state.sensors;

export const selectSensorById = createSelector(
    selectSensors,
    (_: RootState, id: string) => id,
    (sensors, id) => sensors[id]
);

export const selectSensorStatus = createSelector(selectSensorById, sensor => sensor?.status);
export const selectSensorParameters = createSelector(selectSensorById, sensor => sensor?.sensorParameters);
export const selectSensorParameterById = createSelector(
    selectSensorParameters,
    (_: RootState, id: string, parameterId: string) => parameterId,
    (sensors, parameterId) => sensors?.[parameterId] ?? null
);
export const selectSensorParameterMeasuredValue = createSelector(
    selectSensorParameterById,
    sensor => sensor?.measuredValue
);

export const selectSensorLastReadingTime = createSelector(selectSensorById, sensor => sensor?.lastReadingAt);

export const { sync, addSensor, removeSensor, setSensorStatus, setSensorLastReadingTime, setSensorValue } =
    sensorsSlice.actions;
