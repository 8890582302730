import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '~/app/store';
import { PermissionStateExtended } from '~/hooks';

type PermissionState = { permission: PermissionStateExtended | NotificationPermission };

const initialState: PermissionState = {
    permission: 'default',
};

export const notificationPermissionSlice = createSlice({
    name: 'notificationPermission',
    initialState,
    reducers: {
        setNotificationPermission(draft, { payload }: PayloadAction<PermissionState>) {
            draft.permission = payload.permission;
        },
    },
});

export const selectNotificationPermission = (state: RootState) => state.notificationPermission.permission;

export const { setNotificationPermission } = notificationPermissionSlice.actions;
