export type UserRole = 'SuperAdmin' | 'Support' | 'Integrator' | 'Admin' | 'Operator' | 'Viewer';
export type UserGroup = ReadonlyArray<UserRole>;
export const DATE_FORMAT = 'DD MMM YYYY HH:mm:ss';

export interface IGroup {
    viewers: UserGroup;
    operators: UserGroup;
    admins: UserGroup;
    integrators: UserGroup;
    support: UserGroup;
    superAdmins: UserGroup;
}

export const Group = Object.freeze({
    viewers: Object.freeze(['Viewer', 'Operator', 'Admin', 'Integrator', 'Support', 'SuperAdmin'] as const),
    operators: Object.freeze(['Operator', 'Admin', 'Integrator', 'Support', 'SuperAdmin'] as const),
    admins: Object.freeze(['Admin', 'Integrator', 'Support', 'SuperAdmin'] as const),
    integrators: Object.freeze(['Integrator', 'Support', 'SuperAdmin'] as const),
    support: Object.freeze(['Support', 'SuperAdmin'] as const),
    superAdmins: Object.freeze(['SuperAdmin'] as const),
} satisfies IGroup);

export enum IOType {
    DigitalIn = 1,
    DigitalOut = 2,
    WaterMark = 3,
    AnalogOut = 4,
    Counter = 5,
    MeasuringBox = 6,
    Ima01 = 7,
    ModbusSerialPort = 8,
    AnalogIn = 9,
    SerialPort = 10,
    SerialPortAP300 = 11,
    SerialPortMB200 = 12,
}

export enum DosingChannelType {
    Fertilizer_EC = 1,
    Acid = 2,
}

export enum SensorControllerType {
    PH = 1,
    EC = 2,
}

export enum SensorControllerFunction {
    Control = 1,
    Monitoring = 2,
}

export enum EcSensorModel {
    Supmea_SUP_TDS210_B = 1,
}

export enum PhSensorModel {
    Supmea_SUP_TDS210_B = 1,
}

export enum PeripheralModel {
    ONDO_TM251_100 = 1002,
    ModbusSerialPort = 1003,
    ONDO_WS_200 = 1004,
    ONDO_AP_100 = 1005,
    ONDO_TM251_200 = 1006,
    ONDO_TM221_100 = 1007,
    ONDO_AP200 = 1008,
    ONDO_RP100 = 1009,
    ONDO_RP200 = 1010,
    ONDO_TM200_100 = 1011,
    ONDO_AP_300 = 3000,
}

export enum FertigationAlarmAction {
    None = 1,
    StopProgram = 2,
    SkipStep = 3,
    StopFertigation = 4,
}

export enum IrrigationAlarmAction {
    None = 1,
    StopProgram = 2,
    SkipStep = 3,
}

export const WatermeterRatios = [0.1, 1, 10, 100, 1000];

export enum CloudCommandStatus {
    Pending = 1,
    Success = 2,
    Error = 3,
}

export enum OndoFieldType {
    Greenhouse = 1,
    Field = 2,
}

export enum AirpointSignalStrength {
    Excellent = 1,
    VeryGood = 2,
    Good = 3,
    Ok = 4,
    Weak = 5,
    Poor = 6,
    NoSignal = 7,
}

export enum EventHistoryActor {
    None = 0,
    User = 1,
    Schedule = 2,
    Alarm = 3,
    System = 4,
    Program = 5,
    AutoStrategy = 6,
}

export enum EventHistoryActionType {
    Starting = 1,
    Started = 2,
    Stopping = 3,
    Stopped = 4,
    Error = 5,
    Finished = 6,
    Running = 7,
    SystemStopping = 8,
    Finishing = 9,
    None = 10,
    Pausing = 11,
    Paused = 12,
    Resuming = 13,
    Resumed = 14,
    InProgress = 15,
    Manual = 16,
    Skipped = 17,
}

export enum EventHistorySourceType {
    Irrigation = 0,
    FrostProtection = 1,
    Vent = 2,
    System = 3,
    CirculationFan = 4,
}

export enum EventHistoryLogLevel {
    Info = 0,
    Warning = 1,
    Error = 2,
    Detail = 3,
}

export enum EventHistoryProgramStepType {
    IrrigationOnly = 1,
    IrrigationFertigation = 2,
}

export enum EventHistorySearchDate {
    Latest = 1,
    Yesterday = 2,
}

export enum EventHistorySearchDirection {
    Newer = 1,
    Older = 2,
}

export enum ProgramType {
    Time = 1,
    Quantity = 2,
    WaterDepth = 3,
}

export enum ScheduleFrequencyType {
    OneTimeOnly = 1,
    Daily = 4,
    Weekly = 8,
    Monthly = 16,
    MonthlyRelative = 32,
}

export enum ScheduleWeekday {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 4,
    Thursday = 8,
    Friday = 16,
    Saturday = 32,
    Sunday = 64,
}

export enum ScheduleLineSoilMoistureOperator {
    Equal = 0, // =
    GreaterThan = 1, // >
    LessThan = 2, // <
    GreaterThanOrEqual = 3, // >=
    LessThanOrEqual = 4, // <=
}

export enum RecipeType {
    Time = 1,
    Quantity = 2,
    ProportionalQuantity = 3,
    EC_PH = 4,
}

export enum SoilType {
    Unspecified = 0,
    Sandy = 1,
    Silt = 2,
    Clay = 3,
    Loamy = 4,
}

export enum SensorModel {
    WaterMark = 1,
    Truebner_SMT100 = 2,
    ONDO_JBXS_3001_TH = 3,
    ONDO_Measuring_Box_200 = 4,
    S_Soil_MTEC_02A = 5,
    // Renke
    RS_W_S_N01_TR_3_10_20_30CM = 6,
    RS_W_S_N01_TR_3_10_30_50CM = 7,
}

export enum SensorMeasuringParameter {
    AirTemperature = 1,
    RelativeHumidity = 2,
    SoilMoisture = 3,
    SoilTemperature = 4,
    EC = 5,
}

export enum SensorMeasuringUnit {
    Celsius = 1,
    Percent = 2,
    CB = 3,
    MicroSiemens = 4,
}

export enum SensorType {
    Temperature = 1,
    Humidity = 2,
    SoilMoisture = 3,
    WindSpeed = 4,
    WindDirection = 5,
    SunRadiation = 6,
    Rainfall = 7,
    RainQuantity = 8,
    LeafWetness = 9,
    EC = 10,
    TDS = 11,
    Resistivity = 12,
    PH = 14,
    ORP = 15,
    MilliAmps = 16,
    Volts = 17,
    AtmosphericPressure = 18,
    SoilTemperature = 19,
    WindGust = 20,
    DewPoint = 21,
}

export enum AlarmType {
    LowFlow = 1,
    HighFlow = 2,
    NoFlow = 3,
    WindProtection = 4,
    FrostProtectionPumpFailure = 5,
    PeripheralConnectionLost = 6,
    RainProtection = 7,
    SensorConnectionLost = 8,
    SensorFanStopped = 9,
    ControllerConnectionLost = 10,
    MistingStrategyHighTemperature = 11,
    MistingStrategyLowHumidity = 12,
    IQRFLowVoltageAlarm = 13,
    Leak = 14,
    FrostProtectionActivated = 15,
}

export enum AlarmsHistorySourceType {
    Irrigation = 1,
    Fertigation = 2,
    Climate = 3,
    Peripherals = 4,
    Sensors = 5,
    Controllers = 6,
}

export enum AlarmEventType {
    Raised = 1,
    Cleared = 2,
}

export enum ProgramStatus {
    None = 0,
    Started = 1,
    Starting = 2,
    Finished = 4,
    Stopping = 5,
    Stopped = 6,
    Pausing = 7,
    Paused = 8,
    Resuming = 9,
    Resumed = 10,
    Running = 11,
    Mixing = 12,
    Closing = 13,
    StartInitiated = 100,
    StopInitiated = 101,
}

export enum IrrigationValveStatus {
    Closed = 1,
    Opened = 2,
}

export enum AirBubbleJetStatus {
    Idle = 1,
    Working = 2,
}

export enum AirBubbleJetValveWorkMode {
    Periodical = 1,
    NonStop = 2,
}

export enum DeviceStatus {
    Unknown = -1,
    Idle = 1,
    Working = 2,
}

export enum FrostProtectionPumpSignal {
    Running = 1,
    NotRunning = 2,
}

export enum FrostProtectionPumpFailureAlarmAction {
    Stop = 1,
    DoNothing = 2,
}

export enum FrostProtectionDeviceStatus {
    Idle = 1,
    Active = 2,
}

export enum FrostProtectionPumpStatus {
    Idle = 1,
    Active = 2,
}

export enum FrostProtectionMode {
    Disabled = 1,
    Manual = 2,
    Automatic = 3,
}

export enum MainPumpStatus {
    Stopped = 1,
    Started = 2,
}

export enum BoosterPumpStatus {
    Stopped = 1,
    Started = 2,
}

export enum MainValveStatus {
    Closed = 1,
    Opened = 2,
}

export enum PeripheralConnectionType {
    Unknown = -1,
    ModbusSerialPort = 1,
    SerialPort = 2,
    WebSocket = 3,
    Tcp = 4,
    SerialDevice = 5,
}

export enum PeripheralCommandError {
    Connect_Error = 'CTRL_13005',
    Invalid_Model = 'CTRL_13006',
    Peripheral_Used = 'CL_21006',
    Connection_Used = 'CL_21003',
}

export enum PeripheralStatus {
    Disconnected = 1,
    Connected = 2,
}

export enum ControllerStatus {
    Connected = 1,
    Disconnected = 2,
}

export enum DosingChannelValveStatus {
    Closed = 1,
    Opened = 2,
}

export enum AirBubbleJetValveStatus {
    Closed = 1,
    Opened = 2,
}

export enum SensorStatus {
    Disconnected = 1,
    Connected = 2,
}

export enum CirculationFanStatus {
    Idle = 1,
    Working = 2,
    Resting = 3,
}

export enum WeatherStationModel {
    ONDO_WS_100 = 1003,
    ONDO_WS_200 = 1004,
}

export enum ThermalScreenStatus {
    Unknown = -1,
    Opening = 1,
    Opened = 2,
    Closing = 3,
    Closed = 4,
    Synchronizing = 5,
    MeasuringTimes = 6,
    Error = 7,
    Idle = 8,
}

export enum ThermalScreenErrors {
    NoErrors = 0,
    InvalidFullOpeningTime = 1,
    InvalidFullClosingTime = 2,
    OpenLimitSwitchHitEarly = 4,
    OpenLimitSwitchNotHitOnTime = 8,
    OpeningEmergencyStopActivated = 16,
    CloseLimitSwitchHitEarly = 32,
    CloseLimitSwitchNotHitOnTime = 64,
    ClosingEmergencyStopActivated = 128,
    NotSynchronized = 256,
    NotMovingOnOpeningCommand = 512,
    NotMovingOnClosingCommand = 1024,
    Disconnected = 2048,
}

export enum MistingLineStatus {
    Idle = 1,
    Working = 2,
    Resting = 3,
    Error = 1000,
}

export enum VentRainProtectionType {
    Unknown = -1,
    RainFall = 1,
    RainQuantity = 2,
}

export enum ProgramStartMode {
    User = 1,
    Schedule = 2,
}

//Describe where the sensor are situated and their composition
//ex: Truebner - single level depth
//    Renke - multilevel depth i.e sensors are grouped by depth
//    JBX - noDepth i.e temperature and humidity
export enum SensorMeasuringSystemType {
    Unknown = -1,
    Depth = 1,
    NoDepth = 2,
    MultiLevelDepth = 3,
}

export enum LastProgramStatus {
    Finished = 1,
    StoppedByUser = 2,
    Error = 3,
    Warning = 4,
}

export enum ProgramStepStatus {
    NotStarted = 1,
    InProgress = 2,
    Finished = 3,
    Warnings = 4,
    Errors = 5,
}

export enum VentStatus {
    _ = -1,
    Opening = 1,
    Opened = 2,
    Closing = 3,
    Closed = 4,
    Synchronizing = 5,
    MeasuringTimes = 6,
    Error = 7,
    Idle = 8,
}

export enum VentMode {
    _ = -1,
    Local = 1,
    Remote = 2,
}

export enum VentError {
    NoErrors = 0,
    InvalidFullOpeningTime = 1,
    InvalidFullClosingTime = 2,
    OpenLimitSwitchHitEarly = 4,
    OpenLimitSwitchNotHitOnTime = 8,
    OpeningEmergencyStopActivated = 16,
    CloseLimitSwitchHitEarly = 32,
    CloseLimitSwitchNotHitOnTime = 64,
    ClosingEmergencyStopActivated = 128,
    NotSynchronized = 256,
    NotMovingOnOpeningCommand = 512,
    NotMovingOnClosingCommand = 1024,
    Disconnected = 2048,
}

export enum OndoNotificationType {
    AlarmRaised = 1,
    AlarmCleared = 2,
    PushNotificationTest = 3,
    OndoMessage = 4,
}

export enum ComparisonOperator {
    GreaterThan = 1,
    LessThan = 2,
    GreaterThanOrEqual = 3,
    LessThanOrEqual = 4,
    Equal = 0,
}

export enum VentWindDirection {
    Front = 1,
    Side = 2,
}

export enum ReportType {
    ByIrrigationInfrastructures = 1,
    ByFields = 2,
    ByIrrigationValves = 3,
}

export enum ReportGroupBy {
    ByDay = 1,
    ByWeek = 2,
    ByMonth = 3,
    ByYear = 4,
}

export enum AirpointConfigurationAction {
    UpdateAllAirpoints = 1,
    UpdateWithDefaultSettings = 2,
    DoNothing = 3,
}

export enum SerialPortParity {
    None = 0,
    Odd = 1,
    Even = 2,
    Mark = 3,
    Space = 4,
}

export enum SerialStopBits {
    None = 0,
    One = 1,
    Two = 2,
    OnePointFive = 3,
}

export const BAUD_RATES: ReadonlyArray<number> = [1200, 2400, 4800, 9600, 14400, 19200, 28800, 57600, 115200];
export const DATA_BITS: ReadonlyArray<number> = [7, 8];
export enum ClimateDeviceType {
    CirculationFans = 1,
    Vents = 2,
    MistingLines = 3,
    ThermalScreens = 4,
}

export enum ClimateDeviceMode {
    ClimateStrategy = 1,
    Manual = 2,
    Disabled = 3,
}

export enum MistingLineControlType {
    Temperature = 1,
    Humidity = 2,
}

export enum MistingLineStartSource {
    Dashboard = 1,
    Settings = 2,
}

export enum AirUniformingStatus {
    Idle = 1,
    Working = 2,
    Resting = 3,
}

export enum AirUniformingControlType {
    CirculationFanStrategy = 1,
    MistingLineStrategy = 2,
    VentStrategy = 3,
}

export enum AirUniformingStartSource {
    Temperature = 1,
    Humidity = 2,
}

export enum ThermalScreenPrimaryControlType {
    Temperature = 1,
    Disabled = 2,
}

export enum ThermalScreenSecondaryControlType {
    Humidity = 1,
    SunRadiation = 2,
    Disabled = 3,
}

export enum VentActiveControlMode {
    ByTemperature = 1,
    ByHumidity = 2,
}

export const nbsp = '\u00A0';

export enum ThermalScreenActiveControlMode {
    None = 0,
    ByLowTemperature = 1,
    ByHighTemperature = 2,
    ByLowHumidity = 3,
    ByHighHumidity = 4,
    ByLowSunRadiation = 5,
    ByHighSunRadiation = 6,
}

export enum ThermalScreenMode {
    _ = -1,
    Local = 1,
    Remote = 2,
}

export enum ThermalScreenError {
    NoErrors = 0,
    InvalidFullOpeningTime = 1,
    InvalidFullClosingTime = 2,
    OpenLimitSwitchHitEarly = 4,
    OpenLimitSwitchNotHitOnTime = 8,
    OpeningEmergencyStopActivated = 16,
    CloseLimitSwitchHitEarly = 32,
    CloseLimitSwitchNotHitOnTime = 64,
    ClosingEmergencyStopActivated = 128,
    NotSynchronized = 256,
    NotMovingOnOpeningCommand = 512,
    NotMovingOnClosingCommand = 1024,
    Disconnected = 2048,
}

export enum IrrValveOpenCloseMode {
    Simultaneously = 1,
    Sequentially = 2,
}

export enum ProductModel {
    OndoPro = 1,
    OndoGo = 2,
}

export enum OndoMessageType {
    Unspecified = 0,
    Update = 1,
    Payment = 2,
}

export const AIRPOINT_SIGNAL_POWER = [0, 1, 2, 3, 4, 5, 6, 7] as const;

export enum AssetType {
    Other = 0,
    AirPoint = 1,
    MeasuringBox = 2,
    Gateway = 3,
    WeatherStation = 4,
    FertiOn = 5,
    Controller = 6,
}

export enum ProgramLogStatus {
    FinishedSuccess = 1,
    FinishedErrors = 2,
    FinishedWarnings = 3,
    Stopped = 4,
    Running = 5,
    Interrupted = 6,
}

export enum ProgramLogSearchDate {
    Latest = 1,
    Yesterday = 2,
}

export enum ProgramLogSearchDirection {
    Newer = 1,
    Older = 2,
}

export enum ProgramStartedBy {
    User = 1,
    Schedule = 2,
}

export enum ProgramEndedBy {
    User = 1,
    System = 2,
    Alarm = 3,
}

export type ControllerModels = 'C200' | 'G200';

export type ProgramTypeToProgramModelMapType = {
    [key in ControllerModels]: ProductModel;
};

export const ProgramTypeToProgramModelMap = Object.freeze<ProgramTypeToProgramModelMapType>({
    C200: ProductModel.OndoPro,
    G200: ProductModel.OndoGo,
});

export type WS200AssetCode = 'WS200';
export type WS201AssetCode = 'WS201';
export type OWS300AssetCode = 'OWS300';
export type OWS400AssetCode = 'OWS400';

export type WeatherStationAssetCodes = WS200AssetCode | WS201AssetCode | OWS300AssetCode | OWS400AssetCode;
