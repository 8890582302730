import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IrrigationValveStatus } from '../app/constants';
import { RootState } from '../app/store';
import irrigationValvesApi from '../services/irrigationValves';

export type IrrigationValveStateItem = Omit<
    OndoCloudState.IrrigationValveState,
    'controllerId' | 'irrigationInfrastructureId'
>;
export type IrrigationValvesState = Record<string, IrrigationValveStateItem>;

const initialState: IrrigationValvesState = {};

export const irrigationValvesSlice = createSlice({
    name: 'irrigationValves',
    initialState,
    reducers: {
        sync(draft, { payload: irrigationValves }: PayloadAction<OndoCloudState.RootState['irrValvesState']>) {
            draft = Object.entries(irrigationValves).reduce<typeof initialState>((result, [id, state]) => {
                result[id] = { status: state.status };
                return result;
            }, {});
            return draft;
        },

        addIrrigationValve(draft, { payload }: PayloadAction<{ id: string } & IrrigationValveStateItem>) {
            const valve = selectValveById(draft, payload.id);
            if (valve) {
                return;
            }

            draft[payload.id] = {
                status: IrrigationValveStatus.Closed,
            };
        },
        removeIrrigationValve(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },
        changeIrrigationValveStatus(draft, { payload }: PayloadAction<{ id: string } & IrrigationValveStateItem>) {
            const valve = selectValveById(draft, payload.id);
            if (!valve) {
                return;
            }

            valve.status = payload.status;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            irrigationValvesApi.endpoints.addIrrigationValve.matchFulfilled,
            (draft, { payload: irrigationValve }) => {
                draft[irrigationValve.id] = {
                    status: IrrigationValveStatus.Closed,
                };
            }
        );
        builder.addMatcher(
            irrigationValvesApi.endpoints.updateIrrigationValve.matchFulfilled,
            (draft, { payload: irrigationValve }) => {
                draft[irrigationValve.id] = {
                    status: IrrigationValveStatus.Closed,
                };
            }
        );
        builder.addMatcher(irrigationValvesApi.endpoints.deleteIrrigationValve.matchFulfilled, (draft, { meta }) => {
            const irrigationValveId = meta.arg.originalArgs.irrigationValveId;
            delete draft[irrigationValveId];
        });
    },
});

function selectValveById(draft: WritableDraft<IrrigationValvesState>, id: string) {
    return draft[id];
}

// Selectors

const selectIrrigationValveById = (state: RootState, id: string) => state.irrigationValves[id];
export const selectIrrigationValves = (state: RootState) => state.irrigationValves;
export const selectIrrigationValveStatus = (state: RootState, id: string) =>
    selectIrrigationValveById(state, id)?.status;

export const { sync, addIrrigationValve, removeIrrigationValve, changeIrrigationValveStatus } =
    irrigationValvesSlice.actions;
