import { baseApi } from './baseApi';

export const boosterPumpsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getBoosterPumps: build.query<OndoCloudSchemas.BoosterPumpDto[], void>({
            query: () => 'booster-pumps',
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'BoosterPumps' as const, id })),
                          { type: 'BoosterPumps', id: 'LIST' },
                      ]
                    : [{ type: 'BoosterPumps', id: 'LIST' }],
        }),
        getBoosterPump: build.query<OndoCloudSchemas.BoosterPumpDto, Pick<OndoCloudSchemas.BoosterPumpDto, 'id'>>({
            query: ({ id }) => `booster-pumps/${id}`,
            providesTags: (_, __, arg) => [{ type: 'BoosterPumps', id: arg.id }],
        }),
        addBoosterPump: build.mutation<OndoCloudSchemas.BoosterPumpDto, OndoCloudSchemas.CreateBoosterPumpCommand>({
            query: payload => ({
                body: payload,
                method: 'POST',
                url: 'booster-pumps',
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'BoosterPumps', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateBoosterPump: build.mutation<
            OndoCloudSchemas.BoosterPumpDto,
            {
                boosterPump: OndoCloudSchemas.EditBoosterPumpCommand & { id: string };
                infrastructureId: string;
            }
        >({
            query: ({ boosterPump }) => ({
                url: `booster-pumps/${boosterPump.id}`,
                method: 'PUT',
                body: boosterPump,
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'BoosterPumps', id: arg.boosterPump.id },
                          { type: 'IrrigationInfrastructures', id: arg.infrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteBoosterPump: build.mutation<
            void,
            {
                id: OndoCloudSchemas.BoosterPumpDto['id'];
                irrigationInfrastructureId: OndoCloudSchemas.BoosterPumpDto['irrigationInfrastructure']['id'];
            }
        >({
            query: args => ({
                url: `booster-pumps/${args.id}`,
                method: 'delete',
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'BoosterPumps', id: args.id },
                          { type: 'IrrigationInfrastructures', id: args.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export default boosterPumpsApi;

export const {
    useGetBoosterPumpQuery,
    useAddBoosterPumpMutation,
    useUpdateBoosterPumpMutation,
    useGetBoosterPumpsQuery,
    useDeleteBoosterPumpMutation,
} = boosterPumpsApi;
