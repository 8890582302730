import { config } from '../config';

const NOTIFICATIONS_PROMPT_KEY = '@ondo-ui/notifications_prompt_state';
export type UserNotificationsPromptState = 'prompted' | 'initial';

/**
 * Request permission for sending/ receiving notifications
 * @returns
 */
export function requestNotificationPermission() {
    return new Promise<NotificationPermission>((resolve, reject) => {
        const permissionResult = Notification.requestPermission(result => resolve(result));

        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
        return permissionResult;
    });
}

export async function createNotificationsSubscription() {
    const swr = await navigator.serviceWorker.ready;
    const currentSubscription = await swr.pushManager.getSubscription();
    if (currentSubscription) {
        return currentSubscription;
    }

    const subscription = await swr.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: config.notifications.applicationServerKey,
    });

    return subscription;
}

/**
 * Checks whether the navigator supports notifications and the related APIs
 * @returns
 */
export function navigatorSupportsPushNotifications() {
    return (
        'navigator' in window &&
        'serviceWorker' in window.navigator &&
        'Notification' in window &&
        'PushManager' in window
    );
}

export function getUserNotificationsPromptKey(userId: string) {
    return `${NOTIFICATIONS_PROMPT_KEY}.${userId}`;
}

export function wasUserPromptedForNotifications(userId: string): boolean {
    const promptState = localStorage.getItem(getUserNotificationsPromptKey(userId)) as UserNotificationsPromptState;
    return promptState === 'prompted';
}

export function setUserNotificationsPromptState(userId: string, promptState: UserNotificationsPromptState): void {
    try {
        localStorage.setItem(getUserNotificationsPromptKey(userId), promptState);
    } catch (e) {
        console.error('Unable to set user prompt state!', e);
    }
}
