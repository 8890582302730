import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationProps, MsalAuthenticationTemplate } from '@azure/msal-react';
import { Loader } from '@ondo-ui/components';
import { loginRequest } from '../../auth';
import { SupportedLanguageCode } from '~/app/i18n';

export interface AuthAppRendererProps {
    children: MsalAuthenticationProps['children'];
}

const LoadingComponent = () => <Loader color="primary" />;

const AuthAppRenderer = ({ children }: AuthAppRendererProps) => {
    const cachedLanguage = localStorage.getItem('language') as SupportedLanguageCode;
    const loginRequestWithExtraParams = { ...loginRequest };

    const i18LocaleToAzureLocale = (code: SupportedLanguageCode) => {
        if (code === 'bg-bg') {
            return 'bg';
        } else if (code === 'en-en') {
            return 'en';
        }

        return 'en';
    };

    if (cachedLanguage) {
        loginRequestWithExtraParams.extraQueryParameters = {
            ui_locales: i18LocaleToAzureLocale(cachedLanguage),
        };
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequestWithExtraParams}
            loadingComponent={LoadingComponent}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
};

export { AuthAppRenderer };
