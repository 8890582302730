import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DosingChannelValveStatus } from '../app/constants';
import { RootState } from '../app/store';
import { dosingChannelsApi } from '../services/dosingChannels';

export type DosingChannelValveStateItem = Omit<OndoCloudState.DosingChannelValveState, 'controllerId'>;
export type DosingChannelValve = Record<string, DosingChannelValveStateItem>;

const initialState: DosingChannelValve = {};

export const dosingChannelValvesSlice = createSlice({
    name: 'dosingChannelValves',
    initialState,
    reducers: {
        sync(draft, { payload: dosingChannelValves }: PayloadAction<OndoCloudState.RootState['mixerValveState']>) {
            for (const [id, valve] of Object.entries(dosingChannelValves)) {
                draft[id] = {
                    status: valve.status,
                    irrigationInfrastructureId: valve.irrigationInfrastructureId,
                };
            }
        },
        addDosingChannelValve(draft, { payload }: PayloadAction<{ id: string } & DosingChannelValveStateItem>) {
            const dosingChannelValve = draft[payload.id];
            if (dosingChannelValve) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeDosingChannelValve(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setDosingChannelValveStatus(
            draft,
            { payload }: PayloadAction<{ id: string; status: DosingChannelValveStatus }>
        ) {
            const dosingChannelValve = draft[payload.id];
            if (!dosingChannelValve) {
                return;
            }

            dosingChannelValve.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            dosingChannelsApi.endpoints.createDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                draft[dosingChannel.valve.id] = {
                    status: DosingChannelValveStatus.Closed,
                    irrigationInfrastructureId: dosingChannel.irrigationInfrastructure.id,
                };
            }
        );

        builder.addMatcher(
            dosingChannelsApi.endpoints.updateDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                draft[dosingChannel.valve.id] = {
                    status: DosingChannelValveStatus.Closed,
                    irrigationInfrastructureId: dosingChannel.irrigationInfrastructure.id,
                };
            }
        );

        builder.addMatcher(dosingChannelsApi.endpoints.deleteDosingChannel.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

export function selectDosingChannelValves(state: RootState) {
    return state.dosingChannelValves;
}

export const selectDosingChannelValveById = createSelector(
    selectDosingChannelValves,
    (_: RootState, id: string) => id,
    (dosingChannelValve, id) => {
        return dosingChannelValve[id];
    }
);

export const selectDosingChannelValveStatus = createSelector(
    selectDosingChannelValveById,
    dosingChannelValve => dosingChannelValve?.status
);

export const { addDosingChannelValve, removeDosingChannelValve, setDosingChannelValveStatus, sync } =
    dosingChannelValvesSlice.actions;
