import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AlarmRaisedIcon, DialogTitle } from '@ondo-ui/components';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { setNotificationPermission } from '~/slices/notificationPermissionSlice';
import { isApiError } from '~/utils/error';
import { createNotificationsSubscription, requestNotificationPermission } from '../../../app/notifications/utils';
import { useAppDispatch, useAppSelector, useErrorDialog } from '../../../hooks';
import { useCreatePushSubscriptionMutation } from '../../../services/pushSubscriptions';
import { selectUser } from '../../../slices/authSlice';

export interface AskNotificationsPermissionsDialogProps {
    open: boolean;
    onClose: () => void;
}

const AskNotificationsPermissionsDialog = ({ open, onClose }: AskNotificationsPermissionsDialogProps) => {
    const { t } = useTranslation(['account', 'buttons', 'errors']);
    const user = useAppSelector(selectUser);
    const [savePushSubscription] = useCreatePushSubscriptionMutation();
    const dispatch = useAppDispatch();
    const [showErrorDialog] = useErrorDialog();

    const handleRequestNotificationPermissions = useCallback(async () => {
        onClose();

        try {
            const result = await requestNotificationPermission();
            dispatch(setNotificationPermission({ permission: result }));
        } catch (err) {
            console.error('User declined notification permissions!');
            return;
        }

        try {
            const subscription = await createNotificationsSubscription();
            // Save subscription on the b/end
            await savePushSubscription(subscription.toJSON()).unwrap();
        } catch (err) {
            if (isApiError(err)) {
                const messages = err.data.map(({ code }) => t(`error.code.${code}`));
                showErrorDialog({
                    title: t('title.generic', { ns: 'errors' }),
                    message: messages,
                });
            } else {
                showErrorDialog({
                    message: t('label.generic', { ns: 'errors' }),
                });
            }
            // TODO: Handle failure to save subscription
        }
    }, [dispatch, onClose, savePushSubscription, showErrorDialog, t]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle variant="h2" icon={<AlarmRaisedIcon fontSize="large" color="primary" />}>
                {t('notifications.title.alarmNotifications')}
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle2" align="center">
                    <Typography variant="inherit" paragraph>
                        {t('notifications.message.hello', { name: user?.name ?? '' })}
                    </Typography>
                    <Typography variant="inherit" paragraph>
                        {t('notifications.message.receiveNotifications')}
                    </Typography>
                    <Typography paragraph variant="inherit">
                        <Trans
                            t={t}
                            i18nKey="notifications.message.receiveNotificationsPermissions"
                            components={{
                                bold: <Typography variant="subtitle1" component="span" />,
                                regular: <Typography variant="subtitle2" component="span" />,
                            }}
                        />
                    </Typography>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={4} flex={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handleRequestNotificationPermissions}
                    >
                        {t('yes', { ns: 'buttons' })}
                    </Button>
                    <Button variant="contained" color="secondary" size="large" fullWidth onClick={onClose}>
                        {t('no', { ns: 'buttons' })}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export { AskNotificationsPermissionsDialog };
