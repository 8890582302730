import { baseApi } from './baseApi';

export const controllersApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getControllers: build.query<OndoCloudSchemas.ControllerDto[], void>({
            query: () => 'controllers',
            transformResponse: (controllers: OndoCloudSchemas.ControllerDto[]) =>
                controllers.sort((a, b) => a.serialNumber.localeCompare(b.serialNumber)),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'Controllers' as const, id })),
                          { type: 'Controllers', id: 'LIST' },
                      ]
                    : [{ type: 'Controllers', id: 'LIST' }],
        }),
        getControllerById: build.query<OndoCloudSchemas.ControllerDto, { id: string }>({
            query: ({ id }) => `controllers/${id}`,
            providesTags: result => (result ? [{ type: 'Controllers', id: result.id }] : []),
        }),
        editController: build.mutation<
            OndoCloudSchemas.ControllerDto,
            OndoCloudSchemas.EditControllerCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                method: 'PUT',
                url: `controllers/${id}`,
                body,
            }),
            invalidatesTags: (result, error) => {
                if (!result || error) {
                    return [];
                }

                return [
                    { type: 'Controllers', id: 'LIST' },
                    { type: 'Controllers', id: result.id },
                ];
            },
        }),
        clearControllerState: build.mutation<OndoCloudSchemas.ControllerDto, { id: string }>({
            query: ({ id, ...body }) => ({
                method: 'PUT',
                url: `controllers/${id}/clear-program-state`,
                body,
            }),
            invalidatesTags: (result, error) => {
                if (!result || error) {
                    return [];
                }

                return [
                    { type: 'Controllers', id: 'LIST' },
                    { type: 'Controllers', id: result.id },
                ];
            },
        }),
        addController: build.mutation<OndoCloudSchemas.ControllerDto, OndoCloudSchemas.CreateControllerCommand>({
            query: body => ({
                method: 'POST',
                url: `controllers`,
                body,
            }),
            invalidatesTags: (result, error) => {
                if (!result || error) {
                    return [];
                }

                return [
                    { type: 'Controllers', id: 'LIST' },
                    { type: 'Controllers', id: result.id },
                ];
            },
        }),
        deleteController: build.mutation<void, { id: string }>({
            query: ({ id }) => ({
                method: 'DELETE',
                url: `/controllers/${id}`,
            }),
            invalidatesTags: (_, error, args) => {
                if (error) {
                    return [];
                }

                return [
                    { type: 'Controllers', id: 'LIST' },
                    { type: 'Controllers', id: args.id },
                ];
            },
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetControllersQuery,
    useGetControllerByIdQuery,
    useEditControllerMutation,
    useAddControllerMutation,
    useDeleteControllerMutation,
    useClearControllerStateMutation,
} = controllersApi;
