import { useCallback, useContext, useEffect, useMemo } from 'react';
import { DialogProps } from '../components/dialog/Dialog';
import { DialogContext } from '../context/dialog/DialogProvider';
import { noop } from '../utils/noop';

type ErrorDialogType = Extract<DialogProps['type'], 'error' | 'noConnection'>;
type ShowDialogArgs = {
    message: DialogProps['message'];
    type?: ErrorDialogType;
} & Partial<Omit<DialogProps, 'message' | 'open' | 'type'>>;

export type ShowErrorDialogFn = (dialogArgs: ShowDialogArgs) => void;

export function useErrorDialog() {
    const [dialogState, setDialogState] = useContext(DialogContext);

    // Reset initial state on unmount
    useEffect(() => {
        return () => {
            setDialogState(prev => ({
                ...prev,
                open: false,
            }));
        };
    }, [setDialogState]);

    const showErrorDialog = useCallback<ShowErrorDialogFn>(
        ({ type, message, title, onClose }) => {
            setDialogState({
                type: type ?? 'error',
                open: true,
                message,
                title,
                onClose: onClose ?? noop,
            });
        },
        [setDialogState]
    );

    return useMemo(() => [showErrorDialog, dialogState] as const, [showErrorDialog, dialogState]);
}
