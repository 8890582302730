export function isApiError(error: any): error is OndoCloudError.ApiError {
    return typeof error?.status === 'number' && Array.isArray(error?.data);
}

export function isCloudCommandError(error: any): error is OndoCloudCommands.CloudCommandError[] {
    return Array.isArray(error) && error.length > 0 && typeof error[0].message === 'string';
}

export function isNotFoundQueryError(error: any): error is OndoCloudError.QueryError {
    return typeof error?.status === 'number' && error?.status === 404;
}

export function isApiValidationError(error: unknown): error is OndoCloudError.ApiValidationError {
    return error != null && typeof error === 'object' && 'name' in error && 'code' in error && 'message' in error;
}
