import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainValveStatus } from '../app/constants';
import { RootState } from '../app/store';
import { mainValvesApi } from '../services/mainValves';

export type MainValveStateItem = Omit<OndoCloudState.MainValveState, 'controllerId'>;
export type MainValvesState = Record<string, MainValveStateItem>;

const initialState: MainValvesState = {};

export const mainValvesSlice = createSlice({
    name: 'mainValves',
    initialState,
    reducers: {
        sync(draft, { payload: mainValves }: PayloadAction<OndoCloudState.RootState['mainValveState']>) {
            for (const [id, valve] of Object.entries(mainValves)) {
                draft[id] = {
                    status: valve.status,
                    irrigationInfrastructureId: valve.irrigationInfrastructureId,
                };
            }
        },
        addMainValve(draft, { payload }: PayloadAction<{ id: string } & MainValveStateItem>) {
            const mainValve = draft[payload.id];
            if (mainValve) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeMainValve(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setMainValveStatus(draft, { payload }: PayloadAction<{ id: string; status: MainValveStatus }>) {
            const mainValve = draft[payload.id];
            if (!mainValve) {
                return;
            }

            mainValve.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(mainValvesApi.endpoints.createMainValve.matchFulfilled, (draft, { payload: mainValve }) => {
            draft[mainValve.id] = {
                status: MainValveStatus.Closed,
                irrigationInfrastructureId: mainValve.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(mainValvesApi.endpoints.updateMainValve.matchFulfilled, (draft, { payload: mainValve }) => {
            draft[mainValve.id] = {
                status: MainValveStatus.Closed,
                irrigationInfrastructureId: mainValve.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(mainValvesApi.endpoints.deleteMainValve.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

export function selectMainValves(state: RootState) {
    return state.mainValves;
}

export const selectMainVallveById = createSelector(
    selectMainValves,
    (_: RootState, id: string) => id,
    (mainValve, id) => mainValve[id]
);

export const selectMainValveStatus = createSelector(selectMainVallveById, mainValve => mainValve?.status);

export const { addMainValve, removeMainValve, setMainValveStatus, sync } = mainValvesSlice.actions;
