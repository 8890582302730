import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../app/baseQueryClient';

export const tagTypes = [
    'Accounts',
    'Actors',
    'AirBubbleJets',
    'AirpointCoordinators',
    'AirpointConfigurations',
    'AirpointNodes',
    'Airpoints',
    'AlarmsHistory',
    'AlarmTriggerDetails',
    'BoosterPumps',
    'CirculationFans',
    'ClimateDevices',
    'ClimateHistory',
    'ClimateHistorySources',
    'ClimateStrategies',
    'ClimateStrategyDefaultPeriods',
    'ClimateStrategyPeriods',
    'Controllers',
    'DashboardIrrigationInfrastructure',
    'DosingChannels',
    'EventsHistory',
    'FieldCurrentProgram',
    'FieldLastProgram',
    'FieldNextProgram',
    'Fields',
    'FieldSkippedProgram',
    'FrostProtectionDevices',
    'InputOutputs',
    'IrrigationInfrastructures',
    'IrrigationValves',
    'MainPumps',
    'MainValves',
    'MistingLines',
    'PeripheralModels',
    'Peripherals',
    'ProgramExecutionLog',
    'Programs',
    'Programs',
    'ProgramsAlarmSettings',
    'Recipes',
    'Recipes',
    'Reports',
    'ReportSeasons',
    'Schedules',
    'SensorGroups',
    'Sensors',
    'SupmeaControllers',
    'ThermalScreens',
    'Users',
    'Vents',
    'WaterMeters',
    'WeatherStations',
    'OndoMessages',
    'Assets',
    'ProgramsLog',
    'FrostProtectionStrategies',
] as const;

export const baseApi = createApi({
    baseQuery: baseQuery,
    reducerPath: 'api',
    tagTypes,
    keepUnusedDataFor: 120,
    endpoints: () => ({}),
});
