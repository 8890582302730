import { PeripheralStatus } from '../../app/constants';
import { setPeripheralStatus } from '../../slices/peripheralsSlice';
import { SignalRListener } from '../middleware';

const onPeripheralConnected: SignalRListener<OndoCloudEvents.Peripherals.PeripheralConnected> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setPeripheralStatus({
                id: e.id,
                status: PeripheralStatus.Connected,
            })
        );
    };

const onPeripheralDisconnected: SignalRListener<OndoCloudEvents.Peripherals.PeripheralDisconnected> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setPeripheralStatus({
                id: e.id,
                status: PeripheralStatus.Disconnected,
            })
        );
    };

export const listeners = {
    PeripheralConnected: onPeripheralConnected,
    PeripheralDisconnected: onPeripheralDisconnected,
};
