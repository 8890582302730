import type { HubConnection } from '@microsoft/signalr';
import type { MiddlewareAPI } from 'redux';
import { CloudCommandStatus } from '../../app/constants';
import type { AppDispatch, RootState } from '../../app/store';
import { runningPromisesMap } from './signalR';
import { rejectCommand, resolveCommand, selectCommandByCorrelationId } from '../../slices/signalR';

import { sync as syncPrograms } from '../../slices/programsSlice';
import { sync as syncWatermeters } from '../../slices/watermetersSlice';
import { sync as syncFertimeters } from '../../slices/fertimetersSlice';
import { sync as syncAlarms } from '../../slices/alarmSlice';
import { sync as syncIrrigationValves } from '../../slices/irrigationValvesSlice';
import { sync as syncABJs } from '../../slices/airBubbleJetsSlice';
import { sync as syncFPDs } from '../../slices/frostProtectionDevicesSlice';
import { sync as syncMainPumps } from '../../slices/mainPumpsSlice';
import { sync as syncBoosterPumps } from '../../slices/boosterPumpsSlice';
import { sync as syncMainValves } from '../../slices/mainValvesSlice';
import { sync as syncPeripherals } from '../../slices/peripheralsSlice';
import { sync as syncControllers } from '../../slices/controllersSlice';
import { sync as syncDosingChannelValves } from '../../slices/dosingChannelValvesSlice';
import { sync as syncAirBubbleJetValves } from '../../slices/airBubbleJetValvesSlice';
import { sync as syncSensors } from '../../slices/sensorsSlice';
import { sync as syncCirculationFans } from '../../slices/circulationFanSlice';
import { sync as syncWeatherStations } from '../../slices/weatherStationsSlice';
import { sync as syncThermalScreens } from '../../slices/thermalScreensSlice';
import { sync as syncMistingLines } from '../../slices/mistingLinesSlice';
import { sync as syncEcSensors } from '../../slices/ecSensorsSlice';
import { sync as syncPhSensors } from '../../slices/phSensorsSlice';
import { sync as syncVents } from '../../slices/ventSlice';
import { sync as syncClimateStrategies } from '../../slices/climateStrategiesSlice';

import { listeners as programListeners } from '../listeners/programs';
import { listeners as watermeterListeners } from '../listeners/watermeters';
import { listeners as fertimeterListeners } from '../listeners/fertimeters';
import { listeners as alarmListeners } from '../listeners/alarms';
import { listeners as irrigationValveListeners } from '../listeners/irrigationValves';
import { listeners as airBubbleJetListeners } from '../listeners/airBubbleJets';
import { listeners as frostProtectionDevicesListeners } from '../listeners/frostProtectionDevices';
import { listeners as mainPumpsListeners } from '../listeners/mainPumps';
import { listeners as boosterPumpsListeners } from '../listeners/boosterPumps';
import { listeners as mainValvesListeners } from '../listeners/mainValves';
import { listeners as peripheralsListeners } from '../listeners/peripherals';
import { listeners as controllersListeners } from '../listeners/controllers';
import { listeners as dosingChannelValvesListeners } from '../listeners/dosingChannelValves';
import { listeners as airBubbleJetValvesListeners } from '../listeners/airBubbleJetValves';
import { listeners as fieldSensorsListeners } from '../listeners/fieldSensors';
import { listeners as circulationFansListeners } from '../listeners/circulationFans';
import { listeners as weatherStationListeners } from '../listeners/weatherStations';
import { listeners as thermalScreensListeners } from '../listeners/thermalScreens';
import { listeners as mistingLineListeners } from '../listeners/mistingLines';
import { listeners as ecSensorListeners } from '../listeners/ecSensors';
import { listeners as phSensorListeners } from '../listeners/phSensors';
import { listeners as ventListeners } from '../listeners/vents';
import { listeners as scheduleListeners } from '../listeners/schedules';
import { listeners as alarmsListeners } from '../listeners/alarms';
import { listeners as climateStrategiesListeners } from '../listeners/climateStrategies';

export type StoreApi = MiddlewareAPI<AppDispatch, RootState>;
export type SignalRListener<Event = unknown> = (event: Event) => (api: StoreApi) => void;
export interface SignalRListenerMap {
    [x: string]: SignalRListener<any>;
}

function mapListener<Event = unknown>(listener: SignalRListener<Event>, store: StoreApi) {
    return (e: Event) => {
        listener(e)(store);
    };
}

function mapListeners(listeners: SignalRListenerMap, store: StoreApi): Record<string, (e: Event) => void> {
    return Object.entries(listeners).reduce<Record<string, (e: Event) => void>>((result, [eventName, handler]) => {
        result[eventName] = mapListener(handler, store);
        return result;
    }, {});
}

const onConnected: SignalRListener<OndoCloudState.RootState> = e => {
    return ({ dispatch }) => {
        dispatch(syncPrograms(e.programsState));
        dispatch(syncWatermeters(e.watermetersState));
        dispatch(syncFertimeters(e.fertimetersState));
        dispatch(syncAlarms(e.activeAlarmsState));
        dispatch(syncIrrigationValves(e.irrValvesState));
        dispatch(syncABJs(e.airBubbleJetState));
        dispatch(syncFPDs(e.frostProtectionState));
        dispatch(syncMainPumps(e.mainPumpState));
        dispatch(syncBoosterPumps(e.boosterPumpState));
        dispatch(syncMainValves(e.mainValveState));
        dispatch(syncPeripherals(e.peripheralState));
        dispatch(syncControllers(e.controllerState));
        dispatch(syncDosingChannelValves(e.mixerValveState));
        dispatch(syncAirBubbleJetValves(e.airBubbleJetValvesState));
        dispatch(syncSensors(e.sensorState));
        dispatch(syncCirculationFans(e.circulationFanState));
        dispatch(syncWeatherStations(e.weatherStationsState));
        dispatch(syncThermalScreens(e.thermalScreenState));
        dispatch(syncMistingLines(e.mistingLineState));
        dispatch(syncEcSensors(e.ecState));
        dispatch(syncPhSensors(e.phState));
        dispatch(syncVents(e.ventState));
        dispatch(syncClimateStrategies(e.climateStrategyState));
    };
};

const onConnectListeners: SignalRListenerMap = {
    OnConnected: onConnected,
};

export function addListeners(connection: HubConnection, store: StoreApi) {
    const listeners = {
        ControllerCommandResponse: (response: OndoCloudCommands.CloudCommandResponse) => {
            const command = selectCommandByCorrelationId(store.getState(), response.id);
            if (!command) {
                // This command is not present in the state which means
                // that either the state is not synchronized or the command was sent from somewhere else.
                // Either way we have no way to handle this so we can just return here.
                if (import.meta.env.DEV) {
                    console.info(`Unknown command response: ${JSON.stringify(response, null, 2)}.`);
                }
                return;
            }

            const promise = runningPromisesMap.get(response.id);

            if (response.status === CloudCommandStatus.Success) {
                store.dispatch(resolveCommand(command.requestId, response));
                promise?.resolve({ isError: false, data: response.payload });
            } else if (response.status === CloudCommandStatus.Error) {
                store.dispatch(rejectCommand(command.requestId, response.errors));
                promise?.reject({ isError: true, errors: response.errors });
            }
        },

        ...mapListeners(onConnectListeners, store),
        ...mapListeners(programListeners, store),
        ...mapListeners(watermeterListeners, store),
        ...mapListeners(fertimeterListeners, store),
        ...mapListeners(alarmListeners, store),
        ...mapListeners(irrigationValveListeners, store),
        ...mapListeners(airBubbleJetListeners, store),
        ...mapListeners(frostProtectionDevicesListeners, store),
        ...mapListeners(mainPumpsListeners, store),
        ...mapListeners(boosterPumpsListeners, store),
        ...mapListeners(mainValvesListeners, store),
        ...mapListeners(peripheralsListeners, store),
        ...mapListeners(controllersListeners, store),
        ...mapListeners(dosingChannelValvesListeners, store),
        ...mapListeners(airBubbleJetValvesListeners, store),
        ...mapListeners(fieldSensorsListeners, store),
        ...mapListeners(circulationFansListeners, store),
        ...mapListeners(weatherStationListeners, store),
        ...mapListeners(thermalScreensListeners, store),
        ...mapListeners(mistingLineListeners, store),
        ...mapListeners(ecSensorListeners, store),
        ...mapListeners(phSensorListeners, store),
        ...mapListeners(ventListeners, store),
        ...mapListeners(scheduleListeners, store),
        ...mapListeners(alarmsListeners, store),
        ...mapListeners(climateStrategiesListeners, store),
    };

    Object.entries(listeners).forEach(listener => connection.on(...listener));

    return () => {
        Object.entries(listeners).forEach(listener => connection.off(...listener));
    };
}
