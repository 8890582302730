import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainPumpStatus } from '../app/constants';
import { RootState } from '../app/store';
import mainPumpsApi from '../services/mainPumps';

export type MainPumpStateItem = Omit<OndoCloudState.MainPumpState, 'controllerId'>;
export type MainPumpState = Record<string, MainPumpStateItem>;

const initialState: MainPumpState = {};

export const mainPumpsSlice = createSlice({
    name: 'mainPumps',
    initialState,
    reducers: {
        sync(draft, { payload: mainPumps }: PayloadAction<OndoCloudState.RootState['mainPumpState']>) {
            for (const [id, pump] of Object.entries(mainPumps)) {
                draft[id] = {
                    status: pump.status,
                    irrigationInfrastructureId: pump.irrigationInfrastructureId,
                };
            }
        },
        addMainPump(draft, { payload }: PayloadAction<{ id: string } & MainPumpStateItem>) {
            const mainPump = draft[payload.id];
            if (mainPump) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeMainPump(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setMainPumpStatus(draft, { payload }: PayloadAction<{ id: string; status: MainPumpStatus }>) {
            const mainPump = draft[payload.id];
            if (!mainPump) {
                return;
            }

            mainPump.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(mainPumpsApi.endpoints.addMainPump.matchFulfilled, (draft, { payload: mainPump }) => {
            draft[mainPump.id] = {
                status: MainPumpStatus.Stopped,
                irrigationInfrastructureId: mainPump.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(mainPumpsApi.endpoints.updateMainPump.matchFulfilled, (draft, { payload: mainPump }) => {
            draft[mainPump.id] = {
                status: MainPumpStatus.Stopped,
                irrigationInfrastructureId: mainPump.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(mainPumpsApi.endpoints.deleteMainPump.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.mainPumpId;
            delete draft[id];
        });
    },
});

// Selectors

export function selectMainPumps(state: RootState) {
    return state.mainPumps;
}

export const selectMainPumpById = createSelector(
    selectMainPumps,
    (_: RootState, id: string) => id,
    (mainPump, id) => mainPump[id]
);

export const selectMainPumpStatus = createSelector(selectMainPumpById, mainPump => mainPump?.status);

export const { addMainPump, removeMainPump, setMainPumpStatus, sync } = mainPumpsSlice.actions;
