import { setLitersAndFlowRate } from '../../slices/watermetersSlice';
import { SignalRListener } from '../middleware/listeners';

const onLitersChange: SignalRListener<OndoCloudEvents.Watermeters.WatermeterLitersChanged> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setLitersAndFlowRate({
                id: e.watermeterId,
                liters: e.liters,
                flowRate: e.flowRate,
                flowRate_m3h: e.flowRate_m3h,
            })
        );
    };

export const listeners = {
    WatermeterLitersChanged: onLitersChange,
};
