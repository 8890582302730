import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AirBubbleJetStatus } from '../app/constants';
import { RootState } from '../app/store';
import { airBubbleJetsApi } from '../services/airBubbleJets';

export type AirBubbleJetStateItem = Omit<OndoCloudState.AirBubbleJetState, 'controllerId'>;
export type AirBubbleJetsState = Record<string, AirBubbleJetStateItem>;

const initialState: AirBubbleJetsState = {};

export const airBubbleJetsSlice = createSlice({
    name: 'airBubbleJets',
    initialState,
    reducers: {
        sync(draft, { payload: airBubbleJets }: PayloadAction<OndoCloudState.RootState['airBubbleJetState']>) {
            for (const [id, abj] of Object.entries(airBubbleJets)) {
                draft[id] = {
                    status: abj.status,
                    irrigationInfrastructureId: abj.irrigationInfrastructureId,
                };
            }
        },

        addAirBubbleJet(draft, { payload }: PayloadAction<{ id: string } & AirBubbleJetStateItem>) {
            const abj = draft[payload.id];
            if (abj) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeAirBubbleJet(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setAirBubbleJetStatus(draft, { payload }: PayloadAction<{ id: string; status: AirBubbleJetStatus }>) {
            const abj = draft[payload.id];
            if (!abj) {
                return;
            }

            abj.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(airBubbleJetsApi.endpoints.createAirBubbleJet.matchFulfilled, (draft, { payload: abj }) => {
            draft[abj.id] = {
                status: AirBubbleJetStatus.Idle,
                irrigationInfrastructureId: abj.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(airBubbleJetsApi.endpoints.updateAirBubbleJet.matchFulfilled, (draft, { payload: abj }) => {
            draft[abj.id] = {
                status: AirBubbleJetStatus.Idle,
                irrigationInfrastructureId: abj.irrigationInfrastructure.id,
            };
        });

        builder.addMatcher(airBubbleJetsApi.endpoints.deleteAirBubbleJet.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

function selectAirBubbleJets(state: RootState) {
    return state.airBubbleJets;
}

export const selectAirBubbleJetById = createSelector(
    selectAirBubbleJets,
    (_: RootState, id: string) => id,
    (abjs, id) => abjs[id]
);

export const selectAirBubbleJetStatus = createSelector(selectAirBubbleJetById, abj => abj?.status);

export const { addAirBubbleJet, removeAirBubbleJet, setAirBubbleJetStatus, sync } = airBubbleJetsSlice.actions;
