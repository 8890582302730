import { setDosingChannelValveStatus } from '../../slices/dosingChannelValvesSlice';
import { SignalRListener } from '../middleware';

const onDosingChannelValveStatusChanged: SignalRListener<
    OndoCloudEvents.DosingChannelValves.DosingChannelValveStatusChanged
> =
    e =>
    ({ dispatch }) => {
        dispatch(setDosingChannelValveStatus({ id: e.id, status: e.status }));
    };

export const listeners = {
    MixerValveStatusChanged: onDosingChannelValveStatusChanged,
};
