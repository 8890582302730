import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { DeleteDialogContext } from '../../../context/dialog/DeleteDialogProvider';
import { Dialog } from '../Dialog';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';

export interface DeleteDialogProps {
    name: string;
    open: boolean;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
    confirmationMessage?: React.ReactNode;
    error?: FetchBaseQueryError | SerializedError | null;
    renderErrorMessage?: (
        error: FetchBaseQueryError | SerializedError,
        defaultErrorMessage: React.ReactNode
    ) => React.ReactNode;
    onClose: () => void;
    onConfirm: () => void;
    onSuccess: () => void;
}

const DeleteDialog = () => {
    const { t } = useTranslation('dialogs');
    const [
        {
            isLoading,
            isError,
            isSuccess,
            name,
            error,
            onConfirm,
            confirmationMessage,
            renderErrorMessage,
            onClose,
            open,
            onSuccess,
        },
        setDialogState,
    ] = useContext(DeleteDialogContext);

    const handleClose = useCallback(() => {
        if (isLoading) {
            return;
        }

        onClose();
        setDialogState(prev => ({
            ...prev,
            open: false,
        }));
    }, [isLoading, onClose, setDialogState]);

    if (isLoading) {
        return (
            <Dialog
                type="loading"
                open={open}
                onClose={handleClose}
                title={t('delete.title', { name })}
                message={
                    <Trans
                        t={t}
                        ns="dialogs"
                        i18nKey="delete.deletingMessage"
                        values={{
                            name,
                        }}
                        components={{
                            message: <Typography variant="subtitle2" component="span" />,
                            name: <Typography variant="subtitle1" component="span" />,
                        }}
                    />
                }
            />
        );
    } else if (isError) {
        const defaultErrorMessage = (
            <Trans
                t={t}
                ns="dialogs"
                i18nKey="delete.errorMessage"
                values={{
                    name,
                }}
                components={{
                    message: <Typography variant="subtitle2" component="span" />,
                    name: <Typography variant="subtitle1" component="span" />,
                }}
            />
        );
        return (
            <Dialog
                type="error"
                open={open}
                onClose={handleClose}
                title={t('title.generic', { ns: 'errors' })}
                message={renderErrorMessage ? renderErrorMessage(error!, defaultErrorMessage) : defaultErrorMessage}
            />
        );
    } else if (isSuccess) {
        onSuccess();

        return (
            <Dialog
                type="success"
                open={open}
                onClose={handleClose}
                title={t('delete.title', { name })}
                message={
                    <Trans
                        t={t}
                        ns="dialogs"
                        i18nKey="delete.successMessage"
                        values={{
                            name,
                        }}
                        components={{
                            message: <Typography variant="subtitle2" component="span" />,
                            name: <Typography variant="subtitle1" component="span" />,
                        }}
                    />
                }
            />
        );
    } else {
        return (
            <Dialog
                type="delete"
                open={open}
                onClose={handleClose}
                onConfirm={onConfirm}
                title={t('delete.title', { name })}
                message={
                    confirmationMessage ? (
                        confirmationMessage
                    ) : (
                        <Trans
                            t={t}
                            ns="dialogs"
                            i18nKey="delete.confirmMessage"
                            values={{
                                name,
                            }}
                            components={{
                                message: <Typography variant="subtitle2" component="span" />,
                                name: <Typography variant="subtitle1" component="span" />,
                            }}
                        />
                    )
                }
            />
        );
    }
};

export { DeleteDialog };
