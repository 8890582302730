export const SupportedLanguages = ['en-en', 'bg-bg'] as const;
export const DEFAULT_LANGUAGE = SupportedLanguages[0];

export type SupportedLanguageCode = (typeof SupportedLanguages)[number];

export function languageCodeToLabel(code: SupportedLanguageCode): string {
    switch (code) {
        case 'en-en': {
            return 'English';
        }

        case 'bg-bg': {
            return 'Български';
        }

        default: {
            return code;
        }
    }
}
