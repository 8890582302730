import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { RootState } from '../app/store';
import { dosingChannelsApi } from '../services/dosingChannels';
import { irrigationInfrastructuresApi } from '../services/irrigationInfrastructures';
import { groupById } from '../utils/object';

export type FertimeterStateItem = Omit<OndoCloudState.FertimeterState, 'controllerId' | 'name'>;
export type FertimetersState = {
    byId: Record<string, FertimeterStateItem>;
    idByDosingChannelId: Record<string, string>;
};

const initialState: FertimetersState = {
    byId: {},
    idByDosingChannelId: {},
};

export const fertimetersSlice = createSlice({
    name: 'fertimeters',
    initialState,
    reducers: {
        sync(draft, { payload: fertimeters }: PayloadAction<OndoCloudState.RootState['fertimetersState']>) {
            const byId = groupById(fertimeters.map(formatFertimeterState));

            const idByDosingChannelId = fertimeters.reduce<Record<string, string>>((result, fertimeter) => {
                result[fertimeter.dosingChannelId] = fertimeter.id;
                return result;
            }, {});

            draft.byId = byId;
            draft.idByDosingChannelId = idByDosingChannelId;
        },

        setLitersAndFlowRate(
            draft,
            { payload }: PayloadAction<Pick<FertimeterStateItem, 'id' | 'flowRate' | 'flowRate_lh' | 'liters'>>
        ) {
            const fertimeter = draft.byId[payload.id];
            if (!fertimeter) {
                return;
            }
            fertimeter.liters = payload.liters;
            fertimeter.flowRate = payload.flowRate;
            fertimeter.flowRate_lh = payload.flowRate_lh;
        },
        setFertiMeterCalculatingStatus(
            draft,
            { payload }: PayloadAction<{ isFlowRateCalculating: boolean; id: string }>
        ) {
            const ferimeter = draft.byId[payload.id];
            if (!ferimeter) {
                return;
            }

            ferimeter.isFlowRateCalculating = payload.isFlowRateCalculating;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            dosingChannelsApi.endpoints.getDosingChannels.matchFulfilled,
            (draft, { payload: dosingChannels }) => {
                dosingChannels.forEach(dosingChannel => {
                    if (!dosingChannel.fertimeter) {
                        return;
                    }
                    addFertimeterToState(
                        draft,
                        dosingChannel.fertimeter,
                        dosingChannel.id,
                        dosingChannel.irrigationInfrastructure.id
                    );
                });
            }
        );

        builder.addMatcher(
            dosingChannelsApi.endpoints.getDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                if (!dosingChannel.fertimeter) {
                    return;
                }
                addFertimeterToState(
                    draft,
                    dosingChannel.fertimeter,
                    dosingChannel.id,
                    dosingChannel.irrigationInfrastructure.id
                );
            }
        );

        builder.addMatcher(
            dosingChannelsApi.endpoints.updateDosingChannel.matchFulfilled,
            (draft, { payload: dosingChannel }) => {
                const fertimeter = dosingChannel.fertimeter;
                if (!fertimeter) {
                    return;
                }

                const currentLiters = draft.byId[fertimeter.id]?.liters ?? 0;
                draft.byId[fertimeter.id] = {
                    ...transformFertimeterToStateForm(
                        fertimeter,
                        dosingChannel.id,
                        dosingChannel.irrigationInfrastructure.id
                    ),
                    liters: currentLiters,
                };
            }
        );

        builder.addMatcher(dosingChannelsApi.endpoints.deleteDosingChannel.matchFulfilled, (draft, { meta }) => {
            const dosingChannelId = meta.arg.originalArgs.id;
            const fertimeterId = draft.idByDosingChannelId[dosingChannelId];
            delete draft.byId[fertimeterId];
            delete draft.idByDosingChannelId[dosingChannelId];
        });

        builder.addMatcher(
            irrigationInfrastructuresApi.endpoints.getIrrigationInfrastructures.matchFulfilled,
            (draft, { payload: irrInfras }) => {
                irrInfras.forEach(infra => {
                    infra.fertigation.dosingChannels.forEach(dosingChannel => {
                        if (!dosingChannel.fertimeter) {
                            return;
                        }
                        addFertimeterToState(
                            draft,
                            dosingChannel.fertimeter,
                            dosingChannel.id,
                            dosingChannel.irrigationInfrastructure.id
                        );
                    });
                });
            }
        );

        builder.addMatcher(
            irrigationInfrastructuresApi.endpoints.getIrrigationInfrastructure.matchFulfilled,
            (draft, { payload: irrInfra }) => {
                irrInfra.fertigation.dosingChannels.forEach(dosingChannel => {
                    if (!dosingChannel.fertimeter) {
                        return;
                    }
                    addFertimeterToState(
                        draft,
                        dosingChannel.fertimeter,
                        dosingChannel.id,
                        dosingChannel.irrigationInfrastructure.id
                    );
                });
            }
        );
    },
});

function addFertimeterToState(
    draft: WritableDraft<FertimetersState>,
    fertimeter: OndoCloudSchemas.FertimeterDto,
    dosingChannelId: string,
    irrigationInfrastructureId: string
) {
    if (draft.byId[fertimeter.id]) {
        return;
    }

    draft.byId[fertimeter.id] = transformFertimeterToStateForm(fertimeter, dosingChannelId, irrigationInfrastructureId);
    draft.idByDosingChannelId[dosingChannelId] = fertimeter.id;
}

function transformFertimeterToStateForm(
    fertimeter: OndoCloudSchemas.FertimeterDto,
    dosingChannelId: string,
    irrigationInfrastructureId: string
): FertimeterStateItem {
    return {
        id: fertimeter.id,
        liters: 0,
        flowRate: 0,
        flowRate_lh: 0,
        irrigationInfrastructureId,
        dosingChannelId,
        isFlowRateCalculating: false,
    };
}

function formatFertimeterState(fertimeter: OndoCloudState.FertimeterState): FertimeterStateItem {
    const { controllerId, name, ...formattedFertimeter } = fertimeter;
    return formattedFertimeter;
}

export const selectFertimeters = (state: RootState) => state.fertimeters;

export const selectFertimeterById = createSelector(
    selectFertimeters,
    (_: RootState, id: string) => id,
    (fertimeters, id): FertimeterStateItem | null => fertimeters.byId[id] ?? null
);

export const selectFertimeterFlowRate_lh = createSelector(selectFertimeterById, fertimeter => fertimeter?.flowRate_lh);

export const selectFertimeterLiters = createSelector(selectFertimeterById, fertimeter => fertimeter?.liters);

export const selectFertiMeterIsFlowRateCalculating = createSelector(
    selectFertimeterById,
    fertiMeter => fertiMeter?.isFlowRateCalculating ?? null
);
export const { sync, setLitersAndFlowRate, setFertiMeterCalculatingStatus } = fertimetersSlice.actions;
