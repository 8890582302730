import { SensorStatus } from '../../app/constants';
import { setSensorLastReadingTime, setSensorStatus, setSensorValue } from '../../slices/sensorsSlice';
import { SignalRListener } from '../middleware';

const onMeasuredValueChange: SignalRListener<OndoCloudEvents.FieldSensors.MeasuredValueChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setSensorValue({ sensorId: e.sensorId, parameterId: e.parameterId, value: e.value }));
    };

const onLastReadingTimeChanged: SignalRListener<OndoCloudEvents.FieldSensors.LastReadingTimeChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setSensorLastReadingTime({ sensorId: e.sensorId, lastReadingAt: e.lastReadAt }));
    };

const onSensorConnected: SignalRListener<OndoCloudEvents.FieldSensors.SensorConnected> =
    e =>
    ({ dispatch }) => {
        dispatch(setSensorStatus({ sensorId: e.id, status: SensorStatus.Connected }));
    };

const onSensorDisconnected: SignalRListener<OndoCloudEvents.FieldSensors.SensorDisconnected> =
    e =>
    ({ dispatch }) => {
        dispatch(setSensorStatus({ sensorId: e.id, status: SensorStatus.Disconnected }));
    };

export const listeners = {
    SensorValueChanged: onMeasuredValueChange,
    SensorValueRead: onLastReadingTimeChanged,
    SensorConnected: onSensorConnected,
    SensorDisconnected: onSensorDisconnected,
};
