import { type SortOrder } from '../components/dialog/sort-dialog/SortDialog';

export type CompareFn<T> = (a: T, b: T) => number;

export const orderedComparator =
    (order: SortOrder) =>
    <T>(fn: CompareFn<T>) =>
    (a: T, b: T) =>
        order === 'asc' ? fn(a, b) : fn(b, a);

export const comparatorWithAccessor =
    <K extends string, T, R>(key: K, accessor: (value: T, key: K) => R) =>
    (fn: CompareFn<R>) =>
    (a: T, b: T) => {
        const _a = accessor(a, key);
        const _b = accessor(b, key);
        return fn(_a, _b);
    };

/**
 * Sorts an array of OndoCloudSchemas.InputOutputDto by name and position
 *
 * @param a {OndoCloudSchemas.InputOutputDto}
 * @param b {OndoCloudSchemas.InputOutputDto}
 * @returns
 */
export function sortInputOutputsByNameAndPosition(
    a: OndoCloudSchemas.InputOutputDto,
    b: OndoCloudSchemas.InputOutputDto
) {
    const sortByName = a.peripheral.name.localeCompare(b.peripheral.name);
    const sortByPosition = a.position - b.position;
    return sortByName || sortByPosition;
}

export function nillComparator(a: any, b: any): number | null {
    if (a == null && b != null) {
        return -1;
    } else if (b == null && a != null) {
        return 1;
    } else if (a == null && b == null) {
        return 0;
    } else {
        return null;
    }
}

export function numberComparator(a: number | null, b: number | null) {
    const nillResult = nillComparator(a, b);
    if (nillResult !== null) {
        return nillResult;
    }

    return Number(a) - Number(b);
}

export function stringComparator(a: string | null, b: string | null) {
    const nillResult = nillComparator(a, b);
    if (nillResult !== null) {
        return nillResult;
    }

    return String(a).localeCompare(String(b));
}

export function numberOrStringComparator(a: number | string | null, b: number | string | null) {
    const nillResult = nillComparator(a, b);
    if (nillResult !== null) {
        return nillResult;
    }

    if (typeof a === 'number' || typeof b === 'number') {
        return numberComparator(Number(a), Number(b));
    } else {
        return stringComparator(a!.toString() ?? null, b!.toString());
    }
}

const ascStringComparator = orderedComparator('asc')(stringComparator);
const descStringComparator = orderedComparator('desc')(stringComparator);

/**
 * Sorts an array of elements which have name property in **ascending** order
 */
export function sortByNameAsc<T extends { name: string }>(a: T, b: T) {
    return ascStringComparator(a.name, b.name);
}

export function sortByNameDesc<T extends { name: string }>(a: T, b: T) {
    return descStringComparator(a.name, b.name);
}
