import { setMilliamps, setORP, setPh, setTemperature } from '../../slices/phSensorsSlice';
import { SignalRListener } from '../middleware';

const onPhChanged: SignalRListener<OndoCloudEvents.PhSensors.PhControllerPhChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setPh({
                id: e.id,
                ph: e.value,
            })
        );

const onMilliampsChanged: SignalRListener<OndoCloudEvents.PhSensors.PhControllerMiliAmpsChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setMilliamps({
                id: e.id,
                milliamps: e.value,
            })
        );

const onORPChanged: SignalRListener<OndoCloudEvents.PhSensors.PhControllerORPChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setORP({
                id: e.id,
                orp: e.value,
            })
        );

const onTemperatureChanged: SignalRListener<OndoCloudEvents.PhSensors.PhControllerTemperatureChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setTemperature({
                id: e.id,
                temperature: e.value,
            })
        );

export const listeners = {
    PhControllerPhChanged: onPhChanged,
    PhControllerMiliAmpsChanged: onMilliampsChanged,
    PhControllerORPChanged: onORPChanged,
    PhControllerTemperatureChanged: onTemperatureChanged,
};
