import { baseApi } from './baseApi';

export const alarmsHistoryApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getAlarmsHistory: build.query<
            OndoCloudSchemas.AlarmsHistoryDto[],
            Partial<{ offset: number; limit: number }> | void
        >({
            query: params => ({
                url: 'alarms-history',
                method: 'GET',
                params: params ?? undefined,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'AlarmsHistory' as const, id })),
                          { type: 'AlarmsHistory', id: 'LIST' },
                      ]
                    : [{ type: 'AlarmsHistory', id: 'LIST' }],
        }),
        getAlarmHistory: build.query<OndoCloudSchemas.AlarmsHistoryDto, Pick<OndoCloudSchemas.AlarmsHistoryDto, 'id'>>({
            query: ({ id }) => `alarms-history/${id}`,
            providesTags: result => (result ? [{ type: 'AlarmsHistory', id: result.id }] : []),
        }),
    }),
    overrideExisting: true,
});

export const { useGetAlarmHistoryQuery, useGetAlarmsHistoryQuery } = alarmsHistoryApi;
