import { HubConnectionState } from '@microsoft/signalr';
import { createAction, createSlice, nanoid, isPlainObject, PayloadAction } from '@reduxjs/toolkit';
import { CloudCommandStatus } from '../app/constants';
import { RootState } from '../app/store';

export interface SignalRState {
    connectionState: HubConnectionState;
    connectionId: string | null;
    isInitialConnect: boolean;
    retryContext: {
        /**
         * Elapsed milliseconds since start of connection retry
         */
        elapsedMilliseconds: number;
        /**
         * Remaining milliseconds until next retry attempt
         */
        remainingMillisecondsUntilNextRetry: number;
        /**
         * Number of retry attempts
         */
        previousRetryCount: number;
        /**
         * Current retry attempt delay in milliseconds
         */
        currentAttemptDelayMilliseconds: number;
    };
    commands: {
        byRequestId: { [K: string]: SignalRCommandEntry<any> };
        dict: {
            requestIdByCorrelationId: { [K: string]: string };
            requestIdByCommandKey: { [K: string]: string };
        };
    };
}

export type SignalRCommandEntry<ResponsePayload> =
    | {
          status: CloudCommandStatus.Pending;
          requestId: string;
          commandKey: string;
          cloudCommand: SignalRCommand;
      }
    | {
          status: CloudCommandStatus.Success;
          requestId: string;
          commandKey: string;
          cloudCommand: any;
          cloudResponse: OndoCloudCommands.CloudCommandResponse<ResponsePayload>;
      }
    | {
          status: CloudCommandStatus.Error;
          errors: OndoCloudCommands.CloudCommandError[];
          requestId: string;
          commandKey: string;
          cloudCommand: SignalRCommand;
          cloudResponse?: OndoCloudCommands.CloudCommandResponse<ResponsePayload>;
      };

export interface SignalRCommand {
    name: string;
    payload?: any;
}

const initialState: SignalRState = {
    isInitialConnect: true,
    connectionId: null,
    connectionState: HubConnectionState.Disconnected,
    retryContext: {
        elapsedMilliseconds: 0,
        remainingMillisecondsUntilNextRetry: 0,
        currentAttemptDelayMilliseconds: 0,
        previousRetryCount: 0,
    },
    commands: {
        byRequestId: {},
        dict: {
            requestIdByCommandKey: {},
            requestIdByCorrelationId: {},
        },
    },
};

export const signalRSlice = createSlice({
    name: 'signalR',
    initialState,
    reducers: {
        startConnection(draft) {
            draft.connectionState = HubConnectionState.Connecting;
        },
        connectSuccess(draft, { payload }: PayloadAction<{ connectionId: string }>) {
            draft.isInitialConnect = false;
            draft.connectionId = payload.connectionId;
            draft.connectionState = HubConnectionState.Connected;
            draft.retryContext = initialState.retryContext;
        },
        stopConnection(draft) {
            draft.connectionState = HubConnectionState.Disconnecting;
        },
        disconnectSuccess(draft) {
            draft.connectionState = HubConnectionState.Disconnected;
            draft.retryContext = initialState.retryContext;
            draft.connectionId = null;
        },
        reconnecting(draft) {
            draft.connectionState = HubConnectionState.Reconnecting;
        },
        updateRetryContext(draft, { payload }: PayloadAction<SignalRState['retryContext']>) {
            draft.retryContext = payload;
        },
        keepAlive() {},
    },
    extraReducers: builder => {
        builder.addCase(initiateCommand, (draft, { payload }) => {
            const { command, commandKey, requestId } = payload;

            draft.commands.byRequestId[requestId] = {
                status: CloudCommandStatus.Pending,
                commandKey,
                requestId,
                cloudCommand: command,
            };

            draft.commands.dict.requestIdByCommandKey[commandKey] = requestId;
        });

        builder.addCase(resolveCommand, (draft, { payload }) => {
            const { requestId, cloudResponse } = payload;
            const currentCommandState = draft.commands.byRequestId[requestId];

            draft.commands.byRequestId[requestId] = {
                status: CloudCommandStatus.Success,
                cloudResponse,
                commandKey: currentCommandState.commandKey,
                requestId: currentCommandState.requestId,
                cloudCommand: currentCommandState.cloudCommand,
            };
        });

        builder.addCase(rejectCommand, (draft, { payload }) => {
            const { requestId, errors } = payload;
            const currentCommandState = draft.commands.byRequestId[requestId];

            draft.commands.byRequestId[requestId] = {
                status: CloudCommandStatus.Error,
                errors,
                commandKey: currentCommandState.commandKey,
                requestId: currentCommandState.requestId,
                cloudCommand: currentCommandState.cloudCommand,
            };
        });
        builder.addCase(mapCommandIds, (draft, { payload }) => {
            const { commandKey, requestId, correlationId } = payload;

            const command = draft.commands.byRequestId[requestId];
            if (!command) {
                return;
            }

            draft.commands.dict.requestIdByCommandKey[commandKey] = requestId;
            draft.commands.dict.requestIdByCorrelationId[correlationId] = requestId;
        });
        builder.addCase(removeCommand, (draft, { payload }) => {
            const command = draft.commands.byRequestId[payload.requestId];
            if (!command) {
                return;
            }

            let correlationId: string;
            if (command.status === CloudCommandStatus.Success) {
                correlationId = command.cloudResponse.id;
                delete draft.commands.dict.requestIdByCorrelationId[correlationId];
            } else {
                const result = Object.entries(draft.commands.dict.requestIdByCorrelationId).find(
                    ([_, requestId]) => requestId === payload.requestId
                );
                if (result) {
                    delete draft.commands.dict.requestIdByCorrelationId[result[0]];
                }
            }

            delete draft.commands.dict.requestIdByCommandKey[command.commandKey];
            delete draft.commands.byRequestId[payload.requestId];
        });
    },
});

// Actions

export const sendCommand = createAction('signalR/command/send', (command: SignalRCommand) => {
    return {
        payload: {
            command,
            commandKey: getCommandKey(command),
            requestId: nanoid(),
        },
        meta: {
            async unwrap(): Promise<any> {},
        },
    };
});

export const mapCommandIds = createAction(
    'signalR/command/mapReqIdToCorrelationId',
    (commandKey: string, requestId: string, correlationId: string) => {
        return {
            payload: {
                commandKey,
                requestId,
                correlationId,
            },
        };
    }
);

export const initiateCommand = createAction(
    'signalR/command/initiate',
    (command: SignalRCommand, requestId: string, commandKey: string) => {
        return {
            payload: {
                command,
                requestId,
                commandKey,
            },
        };
    }
);

export const resolveCommand = createAction(
    'signalR/command/resolve',
    (requestId: string, cloudResponse: OndoCloudCommands.CloudCommandResponse) => {
        return {
            payload: {
                requestId,
                cloudResponse,
            },
        };
    }
);

export const rejectCommand = createAction(
    'signalR/command/reject',
    (requestId: string, errors: OndoCloudCommands.CloudCommandError[]) => {
        return {
            payload: {
                requestId,
                errors,
            },
        };
    }
);

export const removeCommand = createAction('signalR/command/remove', (requestId: string) => {
    return {
        payload: {
            requestId,
        },
    };
});

// Selectors

export const selectCommands = (state: RootState) => state.signalR.commands;

export const selectCommandByKey = (state: RootState, commandKey: string) => {
    const commands = selectCommands(state);
    const requestId = commands.dict.requestIdByCommandKey[commandKey];
    return commands.byRequestId[requestId];
};

export const selectCommandByCorrelationId = (state: RootState, correlationId: string) => {
    const commands = selectCommands(state);
    const requestId = commands.dict.requestIdByCorrelationId[correlationId];
    return commands.byRequestId[requestId];
};

export const selectCommandByRequestId = (state: RootState, requestId: string) => {
    const commands = selectCommands(state);
    return commands.byRequestId[requestId];
};

// Utils

function getCommandKey(command: SignalRCommand) {
    const { name, payload } = command;
    let stringifiedPayload: string;

    if (isPlainObject(payload)) {
        // Sort object keys before stringifying to prevent the same object resulting in different cache key.
        const sortedKeysPayload = Object.keys(payload)
            .sort()
            .reduce<any>((acc, key) => {
                acc[key] = (payload as any)[key];
                return acc;
            }, {});
        stringifiedPayload = JSON.stringify(sortedKeysPayload);
    } else {
        stringifiedPayload = String(payload);
    }

    return `(${name})_(${stringifiedPayload})`;
}

export const {
    startConnection,
    connectSuccess,
    stopConnection,
    disconnectSuccess,
    reconnecting,
    updateRetryContext,
    keepAlive,
} = signalRSlice.actions;
