import { createSelector, createSlice } from '@reduxjs/toolkit';
import { messagesApi } from '~/services/messages';
import { RootState } from '../app/store';
import { WritableDraft } from 'immer/dist/internal';
import dayjs from 'dayjs';

function formatMessageState(message: OndoCloudSchemas.MessageDto): MessageStateItem {
    const latestMessageReceived = window.localStorage.getItem('latestMessageReceived');
    const { expiresOn, messageSent, product, target, ...formattedMessage } = message;
    let seen: boolean;

    if (!latestMessageReceived) {
        seen = false;
        window.localStorage.setItem('latestMessageReceived', formattedMessage.publishOn);
    } else if (dayjs(message.publishOn).isAfter(dayjs(latestMessageReceived))) {
        seen = false;
        window.localStorage.setItem('latestMessageReceived', formattedMessage.publishOn);
    } else {
        seen = true;
    }

    return { ...formattedMessage, seen };
}

export type MessageStateItem = OndoCloudState.MessageState;
export type MessageState = Record<string, MessageStateItem>;

const initialState: MessageState = {};

export const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            messagesApi.endpoints.getActiveNotifications.matchFulfilled,
            (draft, { payload: messages }) => {
                messages.forEach(message => {
                    addMessageToState(draft, message);
                });
            }
        );
    },
});

function addMessageToState(draft: WritableDraft<MessageState>, message: OndoCloudSchemas.MessageDto) {
    if (draft[message.id]) {
        return;
    }

    draft[message.id] = formatMessageState(message);
}

// Selectors
export function selectMessages(state: RootState) {
    return state.messages;
}

export const selectMessageIds = createSelector(selectMessages, messages => Object.values(messages).map(({ id }) => id));

export const selectUnSeenMessages = createSelector(selectMessages, messages =>
    Object.values(messages).filter(({ seen }) => !seen)
);

export const selectMessageById = (state: RootState, id: string) => state.messages[id] ?? null;

export default messagesSlice.reducer;
