import { AirBubbleJetValveStatus } from '../../app/constants';
import { setAirBubbleJetValveStatus } from '../../slices/airBubbleJetValvesSlice';
import { SignalRListener } from '../middleware';

const onOpenAirBubbleJetValve: SignalRListener<OndoCloudEvents.AirBubbleJetValves.AirBubbleJetValveOpen> =
    e =>
    ({ dispatch }) => {
        dispatch(setAirBubbleJetValveStatus({ id: e.id, status: AirBubbleJetValveStatus.Opened }));
    };

const onCloseAirBubbleJetValve: SignalRListener<OndoCloudEvents.AirBubbleJetValves.AirBubbleJetValveClose> =
    e =>
    ({ dispatch }) => {
        dispatch(setAirBubbleJetValveStatus({ id: e.id, status: AirBubbleJetValveStatus.Closed }));
    };

export const listeners = {
    OpenAirBubbleJetValve: onOpenAirBubbleJetValve,
    CloseAirBubbleJetValve: onCloseAirBubbleJetValve,
};
