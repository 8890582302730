if (!window.APP_VERSIONS) {
    throw new Error(
        'Failed to load app versions! Please check if public/version.js exists and is configured properly!'
    );
}

const appVersions: ONDOAppVersions = JSON.parse(JSON.stringify(window.APP_VERSIONS));

delete window.APP_VERSIONS;

export { appVersions };
