import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ControllerStatus } from '../app/constants';
import { RootState } from '../app/store';
import { controllersApi } from '../services/controllers';

const initialState: OndoCloudState.RootState['controllerState'] = {};

export const controllersSlice = createSlice({
    name: 'controllers',
    initialState,
    reducers: {
        sync(draft, { payload: controllers }: PayloadAction<OndoCloudState.RootState['controllerState']>) {
            for (const [id, controller] of Object.entries(controllers)) {
                draft[id] = {
                    status: controller.status,
                    lastDisconnectedOn: controller.lastDisconnectedOn,
                };
            }
        },

        addController(draft, { payload }: PayloadAction<{ id: string } & OndoCloudState.ControllerState>) {
            const controller = draft[payload.id];
            if (controller) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                lastDisconnectedOn: null,
            };
        },

        removeController(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setControllerStatus(
            draft,
            { payload }: PayloadAction<{ id: string; status: ControllerStatus; lastDisconnectedOn?: string | null }>
        ) {
            const controller = draft[payload.id];
            if (!controller) {
                return;
            }

            controller.status = payload.status;
            if (payload.status === ControllerStatus.Disconnected) {
                controller.lastDisconnectedOn = payload.lastDisconnectedOn;
            }
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            controllersApi.endpoints.getControllers.matchFulfilled,
            (draft, { payload: controllers }) => {
                controllers.forEach(controller => {
                    if (draft[controller.id]) {
                        return;
                    }

                    draft[controller.id] = {
                        status: controller.status,
                        lastDisconnectedOn: controller.lastDisconnectedOn,
                    };
                });
            }
        );

        builder.addMatcher(
            controllersApi.endpoints.getControllerById.matchFulfilled,
            (draft, { payload: controller }) => {
                if (draft[controller.id]) {
                    return;
                }

                draft[controller.id] = {
                    status: controller.status,
                    lastDisconnectedOn: controller.lastDisconnectedOn,
                };
            }
        );
    },
});

// Selectors

function selectControllers(state: RootState) {
    return state.controllers;
}

export const selectControllerById = createSelector(
    selectControllers,
    (_: RootState, id: string) => id,
    (controllers, id): OndoCloudState.ControllerState | null => controllers[id] ?? null
);

export const selectControllerStatus = createSelector(
    selectControllerById,
    (controller): ControllerStatus | null => controller?.status ?? null
);

export const selectControllerLastDisconnectedAt = createSelector(
    selectControllerById,
    (controller): string | null => controller?.lastDisconnectedOn ?? null
);

export const selectDisconnectedControllers = createSelector(selectControllers, controllers =>
    Object.entries(controllers).reduce<string[]>((result, [id, controller]) => {
        if (controller.status === ControllerStatus.Disconnected) {
            result.push(id);
        }

        return result;
    }, [])
);

export const { sync, addController, removeController, setControllerStatus } = controllersSlice.actions;
