import dayjs from 'dayjs';
import { baseApi } from './baseApi';

export const messagesApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getActiveNotifications: build.query<OndoCloudSchemas.MessageDto[], Partial<{ activeDate: string }> | void>({
            query: params => ({
                url: 'messages',
                method: 'GET',
                params: params ?? undefined,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'OndoMessages' as const, id })),
                          { type: 'OndoMessages', id: 'LIST' },
                      ]
                    : [{ type: 'OndoMessages', id: 'LIST' }],
            transformResponse: (result: OndoCloudSchemas.MessageDto[]) =>
                result.sort((a, b) => {
                    if (dayjs(a.publishOn).isBefore(dayjs(b.publishOn))) {
                        return -1;
                    } else if (dayjs(a.publishOn).isAfter(dayjs(b.publishOn))) {
                        return 1;
                    } else {
                        return 0;
                    }
                }),
        }),
    }),
    overrideExisting: true,
});

export const { useGetActiveNotificationsQuery } = messagesApi;
