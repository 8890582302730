import { MainValveStatus } from '../../app/constants';
import { setMainValveStatus } from '../../slices/mainValvesSlice';
import { SignalRListener } from '../middleware';

const onMainValveOpened: SignalRListener<OndoCloudEvents.MainValve.MainValveOpen> =
    e =>
    ({ dispatch }) => {
        dispatch(setMainValveStatus({ id: e.id, status: MainValveStatus.Opened }));
    };

const onMainValveClosed: SignalRListener<OndoCloudEvents.MainValve.MainValveClose> =
    e =>
    ({ dispatch }) => {
        dispatch(setMainValveStatus({ id: e.id, status: MainValveStatus.Closed }));
    };

export const listeners = {
    MainValveOpen: onMainValveOpened,
    MainValveClose: onMainValveClosed,
};
