import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { SensorControllerType } from '../app/constants';
import { RootState } from '../app/store';
import { supmeaControllersApi } from '../services/supmeaControllers';

export type EcSensorsState = OndoCloudState.RootState['ecState'];
export type EcSensorStateItem = EcSensorsState[string];

const initialState: EcSensorsState = {};

export const ecSensorsSlice = createSlice({
    name: 'ecSensors',
    initialState,
    reducers: {
        sync(draft, { payload }: PayloadAction<EcSensorsState>) {
            draft = payload;
            return draft;
        },

        addEcSensor(draft, { payload }: PayloadAction<EcSensorStateItem>) {
            const sensor = draft[payload.id];
            if (sensor) {
                return;
            }

            draft[payload.id] = payload;
        },

        removeEcSensor(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setConductivity(draft, { payload }: PayloadAction<{ id: string; conductivity: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.conductivity = payload.conductivity;
        },

        setResistivity(draft, { payload }: PayloadAction<{ id: string; resistivity: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.resistivity = payload.resistivity;
        },

        setTDS(draft, { payload }: PayloadAction<{ id: string; tds: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.tds = payload.tds;
        },

        setTemperature(draft, { payload }: PayloadAction<{ id: string; temperature: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.temperature = payload.temperature;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            isAnyOf(
                supmeaControllersApi.endpoints.createSupmeaController.matchFulfilled,
                supmeaControllersApi.endpoints.updateSupmeaController.matchFulfilled
            ),
            (draft, { payload: supmeaController }) => {
                if (supmeaController.type !== SensorControllerType.EC) {
                    return;
                }

                draft[supmeaController.id] = {
                    id: supmeaController.id,
                    controllerId: supmeaController.connection.peripheral?.controllerId,
                    irrigationInfrastructureId: supmeaController.irrigationInfrastructure.id,
                    conductivity: 0,
                    resistivity: 0,
                    tds: 0,
                    temperature: 0,
                    function: supmeaController.function,
                };
            }
        );

        builder.addMatcher(supmeaControllersApi.endpoints.deleteSupmeaController.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

export const { sync, addEcSensor, removeEcSensor, setConductivity, setResistivity, setTDS, setTemperature } =
    ecSensorsSlice.actions;

// Selectors

export const selectEcSensors = (state: RootState) => state.ecSensors;
export const selectEcSensorById = createSelector(
    selectEcSensors,
    (_: RootState, id: string) => id,
    (ecSensors, id: string): EcSensorStateItem | null => ecSensors[id]
);

export const selectEcSensorConductivity = createSelector(
    selectEcSensorById,
    (ecSensor): number | null => ecSensor?.conductivity ?? null
);
