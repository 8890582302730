import { Loader, theme } from '@ondo-ui/components';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from './context/msal/MsalProvider';
import { StoreProvider } from './context/redux/StoreProvider';
import { AuthAppRenderer } from './components/auth/AuthAppRenderer';
import { Layout } from './components/layout/Loadable';
import { DialogProvider } from './context/dialog/DialogProvider';
import { MuiDateTimePickersLocalizationProvider } from './context/mui-datetime-pickers/MuiDateTimePickersLocalizationProvider';
import { Suspense } from 'react';
import { DeleteDialogProvider } from './context/dialog/DeleteDialogProvider';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { ServerConnectionOverlay } from './components/overlay/ServerConnectionOverlay';
import { AskNotificationsPermissionsDialogRenderer } from './components/notifications/AskNotificationsPermissionsDialogRenderer';
import { RouterContextProvider } from './components/routes/context/RouterContextProvider';
import { ServiceWorkersHub } from './workers/ServiceWorkersHub';

const App = () => {
    return (
        <BrowserRouter>
            <MsalProvider>
                <StoreProvider>
                    <StyledEngineProvider injectFirst>
                        <ServiceWorkersHub />
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <AuthAppRenderer>
                                <Suspense fallback={<Loader />}>
                                    <ServerConnectionOverlay />
                                    <AskNotificationsPermissionsDialogRenderer />
                                    <DialogProvider>
                                        <DeleteDialogProvider>
                                            <MuiDateTimePickersLocalizationProvider>
                                                <RouterContextProvider>
                                                    <Layout />
                                                </RouterContextProvider>
                                            </MuiDateTimePickersLocalizationProvider>
                                        </DeleteDialogProvider>
                                    </DialogProvider>
                                </Suspense>
                            </AuthAppRenderer>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </StoreProvider>
            </MsalProvider>
        </BrowserRouter>
    );
};

export { App };
