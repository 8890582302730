import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    dialogClasses,
    DialogContent,
    styled,
    Typography,
} from '@mui/material';
import { DialogActions } from './DialogActions';
import { DialogTitle } from './DialogTitle';

export type DialogType = 'loading' | 'error' | 'success' | 'noConnection' | 'delete' | 'generic' | 'warning';

export interface DialogProps {
    type: DialogType;
    title?: string;
    message: string | string[] | React.ReactNode;
    open: boolean;
    onClose: () => void;
    onConfirm?: () => void;
    actionsDisabled?: boolean;
    actions?: React.ReactNode;
    maxWidth?: MuiDialogProps['maxWidth'];
}

const DialogWrapper = styled(MuiDialog, {
    shouldForwardProp: prop => prop !== 'type',
})<MuiDialogProps & { type: DialogType }>(({ theme, type }) => {
    let border = '';
    if (type === 'error') {
        border = `2px solid ${theme.palette.error.main}`;
    } else if (type === 'noConnection') {
        border = `2px solid ${theme.palette.warning.main}`;
    }
    return {
        [`.${dialogClasses.paper}`]: {
            padding: theme.spacing(2),
            border,
        },
    };
});

const Dialog = ({
    type,
    title,
    message,
    open,
    onClose,
    onConfirm,
    actions,
    maxWidth,
    actionsDisabled = false,
}: DialogProps) => {
    const renderMessage = () => {
        if (Array.isArray(message)) {
            return message.map(message => (
                <Typography key={message} variant="subtitle2" align="center" paragraph>
                    {message}
                </Typography>
            ));
        } else if (typeof message === 'string') {
            return (
                <Typography variant="subtitle2" align="center" paragraph>
                    {message}
                </Typography>
            );
        } else {
            return message;
        }
    };

    return (
        <DialogWrapper fullWidth maxWidth={maxWidth} open={open} onClose={onClose} type={type}>
            {title && <DialogTitle type={type} title={title} />}
            <DialogContent sx={{ textAlign: 'center', py: 4 }}>{renderMessage()}</DialogContent>
            {actions ? (
                actions
            ) : (
                <DialogActions type={type} onClose={onClose} onConfirm={onConfirm} actionsDisabled={actionsDisabled} />
            )}
        </DialogWrapper>
    );
};

export { Dialog };
