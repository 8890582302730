import { baseApi } from './baseApi';

export const dosingChannelsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getDosingChannels: build.query<
            OndoCloudSchemas.DosingChannelDto[],
            Partial<{ irrigationInfrastructureId: string }>
        >({
            query: params => ({
                url: 'dosing-channels',
                method: 'GET',
                params,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'DosingChannels' as const, id })),
                          { type: 'DosingChannels', id: 'LIST' },
                      ]
                    : [{ type: 'DosingChannels', id: 'LIST' }],
        }),
        getDosingChannel: build.query<OndoCloudSchemas.DosingChannelDto, Pick<OndoCloudSchemas.DosingChannelDto, 'id'>>(
            {
                query: ({ id }) => `dosing-channels/${id}`,
                providesTags: (result, _, arg) => (result ? [{ type: 'DosingChannels' as const, id: arg.id }] : []),
            }
        ),
        createDosingChannel: build.mutation<
            OndoCloudSchemas.DosingChannelDto,
            OndoCloudSchemas.CreateDosingChannelCommand
        >({
            query: body => ({
                url: 'dosing-channels',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'DosingChannels' as const, id: 'LIST' },
                          { type: 'DosingChannels' as const, id: result.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                      ]
                    : [],
        }),
        updateDosingChannel: build.mutation<
            OndoCloudSchemas.DosingChannelDto,
            OndoCloudSchemas.EditDosingChannelCommand & { irrigationInfrastructureId: string; id: string }
        >({
            query: ({ id: dosingChannelId, irrigationInfrastructureId: _, ...dosingChannel }) => ({
                url: `dosing-channels/${dosingChannelId}`,
                method: 'PUT',
                body: dosingChannel,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'DosingChannels' as const, id: 'LIST' },
                          { type: 'DosingChannels' as const, id: result.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteDosingChannel: build.mutation<
            void,
            {
                id: OndoCloudSchemas.DosingChannelDto['id'];
                irrigationInfrastructureId: OndoCloudSchemas.IrrigationInfrastructureDto['id'];
            }
        >({
            query: ({ id }) => ({
                url: `dosing-channels/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'DosingChannels' as const, id: 'LIST' },
                          { type: 'DosingChannels' as const, id: arg.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetDosingChannelsQuery,
    useGetDosingChannelQuery,
    useCreateDosingChannelMutation,
    useUpdateDosingChannelMutation,
    useDeleteDosingChannelMutation,
} = dosingChannelsApi;
