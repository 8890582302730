import { baseApi } from './baseApi';

export const ventsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getFieldVents: build.query<OndoCloudSchemas.VentDto[], { fieldId: string }>({
            query: ({ fieldId }) => `fields/${fieldId}/vents`,
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Vents' as const, id })), { type: 'Vents', id: 'LIST' }]
                    : [{ type: 'Vents', id: 'LIST' }],
        }),
        getAllVents: build.query<OndoCloudSchemas.VentDto[], void>({
            query: () => 'vents',
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Vents' as const, id })), { type: 'Vents', id: 'LIST' }]
                    : [{ type: 'Vents', id: 'LIST' }],
        }),
        getVent: build.query<OndoCloudSchemas.VentDto, { ventId: string; fieldId: string }>({
            query: ({ ventId, fieldId }) => `fields/${fieldId}/vents/${ventId}`,
            providesTags: result => (result ? [{ type: 'Vents' as const, id: result.id }] : []),
        }),
        createVent: build.mutation<OndoCloudSchemas.VentDto, OndoCloudSchemas.CreateVentCommand & { fieldId: string }>({
            query: ({ fieldId, ...body }) => ({
                url: `fields/${fieldId}/vents`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'Vents', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        updateVent: build.mutation<
            OndoCloudSchemas.VentDto,
            OndoCloudSchemas.EditVentCommand & { fieldId: string; ventId: string }
        >({
            query: ({ fieldId, ventId, ...body }) => ({
                url: `fields/${fieldId}/vents/${ventId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'Vents', id: arg.ventId },
                          { type: 'Vents', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        deleteVents: build.mutation<
            void,
            {
                ventId: string;
                fieldId: string;
            }
        >({
            query: ({ ventId, fieldId }) => ({
                url: `fields/${fieldId}/vents/${ventId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, { ventId, fieldId }) =>
                !error
                    ? [
                          { type: 'Vents', id: ventId },
                          { type: 'Vents', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: fieldId },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateVentMutation,
    useDeleteVentsMutation,
    useGetVentQuery,
    useGetFieldVentsQuery,
    useUpdateVentMutation,
    useGetAllVentsQuery,
} = ventsApi;
