import { baseApi } from './baseApi';

export const circulationFansApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getCirculationFans: build.query<OndoCloudSchemas.CirculationFanDto[], { fieldId: string }>({
            query: ({ fieldId }) => `fields/${fieldId}/circulation-fans`,
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'CirculationFans' as const, id })),
                          { type: 'CirculationFans', id: 'LIST' },
                      ]
                    : [{ type: 'CirculationFans', id: 'LIST' }],
        }),
        getCirculationFan: build.query<OndoCloudSchemas.CirculationFanDto, { circFanId: string; fieldId: string }>({
            query: ({ circFanId, fieldId }) => `fields/${fieldId}/circulation-fans/${circFanId}`,
            providesTags: result => (result ? [{ type: 'CirculationFans' as const, id: result.id }] : []),
        }),
        createCirculationFan: build.mutation<
            OndoCloudSchemas.CirculationFanDto,
            OndoCloudSchemas.CreateCirculationFanCommand & { fieldId: string }
        >({
            query: ({ fieldId, ...body }) => ({
                url: `fields/${fieldId}/circulation-fans`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'CirculationFans', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        updateCirculationFan: build.mutation<
            OndoCloudSchemas.CirculationFanDto,
            OndoCloudSchemas.EditCirculationFanCommand & { fieldId: string; circFanId: string }
        >({
            query: ({ fieldId, circFanId, ...body }) => ({
                url: `fields/${fieldId}/circulation-fans/${circFanId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'CirculationFans', id: arg.circFanId },
                          { type: 'CirculationFans', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        deleteCirculationFan: build.mutation<
            void,
            {
                circFanId: string;
                fieldId: string;
            }
        >({
            query: ({ circFanId, fieldId }) => ({
                url: `fields/${fieldId}/circulation-fans/${circFanId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, { circFanId, fieldId }) =>
                !error
                    ? [
                          { type: 'CirculationFans', id: circFanId },
                          { type: 'CirculationFans', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: fieldId },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateCirculationFanMutation,
    useDeleteCirculationFanMutation,
    useGetCirculationFanQuery,
    useGetCirculationFansQuery,
    useUpdateCirculationFanMutation,
} = circulationFansApi;
