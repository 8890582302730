import { combineReducers } from 'redux';
import { configureStore, ThunkAction, Action, autoBatchEnhancer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

// Api services
import { baseApi } from '../services/baseApi';

// Middleware
import { signalRMiddleware } from '../signalR/middleware';
import { listenerMiddleware } from './redux-listener-middleware/listener-middleware';

// Slices
import { authSlice } from '../slices/authSlice';
import { sidemenuSlice } from '../slices/sidemenuSlice';
import { sendCommand, signalRSlice } from '../slices/signalR';
import { programsSlice } from '../slices/programsSlice';
import { watermetersSlice } from '../slices/watermetersSlice';
import { fertimetersSlice } from '../slices/fertimetersSlice';
import { alarmsSlice } from '../slices/alarmSlice';
import { irrigationValvesSlice } from '../slices/irrigationValvesSlice';
import { airBubbleJetsSlice } from '../slices/airBubbleJetsSlice';
import { frostProtectionDevicesSlice } from '../slices/frostProtectionDevicesSlice';
import { mainPumpsSlice } from '../slices/mainPumpsSlice';
import { boosterPumpsSlice } from '../slices/boosterPumpsSlice';
import { mainValvesSlice } from '../slices/mainValvesSlice';
import { peripheralsSlice } from '../slices/peripheralsSlice';
import { controllersSlice } from '../slices/controllersSlice';
import { dosingChannelValvesSlice } from '../slices/dosingChannelValvesSlice';
import { airBubbleJetValvesSlice } from '../slices/airBubbleJetValvesSlice';
import { sensorsSlice } from '../slices/sensorsSlice';
import { circulationFansSlice } from '../slices/circulationFanSlice';
import { weatherStationsSlice } from '../slices/weatherStationsSlice';
import { thermalScreensSlice } from '../slices/thermalScreensSlice';
import { mistingLinesSlice } from '../slices/mistingLinesSlice';
import { ecSensorsSlice } from '../slices/ecSensorsSlice';
import { phSensorsSlice } from '../slices/phSensorsSlice';
import { ventsSlice } from '../slices/ventSlice';
import { climateStrategiesSlice } from '../slices/climateStrategiesSlice';
import { notificationPermissionSlice } from '~/slices/notificationPermissionSlice';
import { messagesSlice } from '~/slices/messagesSlice';

const rootReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    [authSlice.name]: authSlice.reducer,
    [sidemenuSlice.name]: sidemenuSlice.reducer,
    [signalRSlice.name]: signalRSlice.reducer,
    [programsSlice.name]: programsSlice.reducer,
    [watermetersSlice.name]: watermetersSlice.reducer,
    [fertimetersSlice.name]: fertimetersSlice.reducer,
    [alarmsSlice.name]: alarmsSlice.reducer,
    [irrigationValvesSlice.name]: irrigationValvesSlice.reducer,
    [airBubbleJetsSlice.name]: airBubbleJetsSlice.reducer,
    [frostProtectionDevicesSlice.name]: frostProtectionDevicesSlice.reducer,
    [mainPumpsSlice.name]: mainPumpsSlice.reducer,
    [boosterPumpsSlice.name]: boosterPumpsSlice.reducer,
    [mainValvesSlice.name]: mainValvesSlice.reducer,
    [peripheralsSlice.name]: peripheralsSlice.reducer,
    [controllersSlice.name]: controllersSlice.reducer,
    [dosingChannelValvesSlice.name]: dosingChannelValvesSlice.reducer,
    [airBubbleJetValvesSlice.name]: airBubbleJetValvesSlice.reducer,
    [sensorsSlice.name]: sensorsSlice.reducer,
    [circulationFansSlice.name]: circulationFansSlice.reducer,
    [weatherStationsSlice.name]: weatherStationsSlice.reducer,
    [thermalScreensSlice.name]: thermalScreensSlice.reducer,
    [mistingLinesSlice.name]: mistingLinesSlice.reducer,
    [ecSensorsSlice.name]: ecSensorsSlice.reducer,
    [phSensorsSlice.name]: phSensorsSlice.reducer,
    [ventsSlice.name]: ventsSlice.reducer,
    [climateStrategiesSlice.name]: climateStrategiesSlice.reducer,
    [notificationPermissionSlice.name]: notificationPermissionSlice.reducer,
    [messagesSlice.name]: messagesSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: gDM =>
        gDM({
            serializableCheck: {
                ignoredActions: [sendCommand.type],
                ignoredPaths: ['meta.unwrap'],
            },
        }).concat([listenerMiddleware.middleware, baseApi.middleware, signalRMiddleware]),
    // Add auto-batching behaviour to RTK for improved performance
    // See: https://redux-toolkit.js.org/api/autoBatchEnhancer
    enhancers: exisitingEnhancers => exisitingEnhancers.concat(autoBatchEnhancer()),
});

/**
 * Setup refetchOnFocus and refetchOnMount listeners
 */
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
