import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    AccountInfo,
} from '@azure/msal-browser';
import { msalConfig } from './config';

const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account as AccountInfo;
        msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        const payload = event.payload as AuthenticationResult;

        const prevLocation = payload.state;
        if (prevLocation) {
            window.location.replace(prevLocation);
        }
    }
});

export { msalInstance };
