import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import urlJoin from 'url-join';
import { DEFAULT_LANGUAGE, SupportedLanguages } from '../app/i18n';
import Fetch, { FetchOptions } from 'i18next-fetch-backend';
import { appVersions } from '~/app/appVersions';

interface BackendFetchOptions extends Partial<FetchOptions> {
    expirationTime: number;
    defaultVersion: string;
}

const localesPath = import.meta.env.PROD
    ? `/locales/{{ns}}/{{ns}}.{{lng}}.json?version=${appVersions.UIVersion}`
    : `/public/locales/{{ns}}/{{ns}}.{{lng}}.json?version=${appVersions.UIVersion}`;

const fetchBackendOptions: BackendFetchOptions = {
    loadPath: urlJoin(import.meta.env.BASE_URL, localesPath),
    expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
    defaultVersion: appVersions.UIVersion,
};

const i18n = i18next
    .use(Fetch)
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        supportedLngs: SupportedLanguages,
        load: 'currentOnly',
        ns: ['buttons', 'dashboard'],
        fallbackLng: DEFAULT_LANGUAGE,
        lowerCaseLng: true,
        debug: import.meta.env.MODE !== 'production' && import.meta.env.MODE !== 'test',
        detection: {
            lookupLocalStorage: 'language',
        },
        interpolation: {
            escapeValue: false,
        },
        backend: fetchBackendOptions,
    });

export { i18n };
