import { ControllerStatus } from '../../app/constants';
import { SignalRListener } from '../middleware';
import { setControllerStatus } from '../../slices/controllersSlice';
import { syncControllerPrograms } from '../../slices/programsSlice';
import { sync as syncWatermeters } from '../../slices/watermetersSlice';
import { sync as syncFertimeters } from '../../slices/fertimetersSlice';
import { sync as syncAlarms } from '../../slices/alarmSlice';
import { sync as syncIrrigationValves } from '../../slices/irrigationValvesSlice';
import { sync as syncABJs } from '../../slices/airBubbleJetsSlice';
import { sync as syncFPDs } from '../../slices/frostProtectionDevicesSlice';
import { sync as syncMainPumps } from '../../slices/mainPumpsSlice';
import { sync as syncBoosterPumps } from '../../slices/boosterPumpsSlice';
import { sync as syncMainValves } from '../../slices/mainValvesSlice';
import { sync as syncPeripherals } from '../../slices/peripheralsSlice';
import { sync as syncControllers } from '../../slices/controllersSlice';
import { sync as syncDosingChannelValves } from '../../slices/dosingChannelValvesSlice';
import { sync as syncABJVs } from '../../slices/airBubbleJetValvesSlice';
import { sync as syncSensors } from '../../slices/sensorsSlice';
import { sync as syncCirculationFans } from '../../slices/circulationFanSlice';
import { sync as syncWeatherStations } from '../../slices/weatherStationsSlice';
import { sync as syncThermalScreens } from '../../slices/thermalScreensSlice';
import { sync as syncMistingLines } from '../../slices/mistingLinesSlice';
import { sync as syncEcSensors } from '../../slices/ecSensorsSlice';
import { sync as syncPhSensors } from '../../slices/phSensorsSlice';
import { sync as syncVents } from '../../slices/ventSlice';

const onControllerConnected: SignalRListener<OndoCloudEvents.Controllers.ControllerConnected> =
    e =>
    ({ dispatch }) => {
        const { id, state } = e;

        /**
         * This logic is copied from the `OnConnect` event of SignalR.
         * This is done on purpose as the current implementation of the ControllerConnected
         * event in the backend is not final and will undergo changes.
         * For the time being only `programs` slice is synchronized properly.
         * The rest of the slices should be reviewed and if necessary should undergo changes.
         */

        // Sync state: Unknown
        dispatch(syncABJs(state.airBubbleJetState));
        dispatch(syncABJVs(state.airBubbleJetValvesState));
        dispatch(syncAlarms(state.activeAlarmsState));
        dispatch(syncBoosterPumps(state.boosterPumpState));
        dispatch(syncCirculationFans(state.circulationFanState));
        dispatch(syncControllers(state.controllerState));
        dispatch(syncDosingChannelValves(state.mixerValveState));
        dispatch(syncEcSensors(state.ecState));
        dispatch(syncFertimeters(state.fertimetersState));
        dispatch(syncFPDs(state.frostProtectionState));
        dispatch(syncIrrigationValves(state.irrValvesState));
        dispatch(syncMainPumps(state.mainPumpState));
        dispatch(syncMainValves(state.mainValveState));
        dispatch(syncMistingLines(state.mistingLineState));
        dispatch(syncPeripherals(state.peripheralState));
        dispatch(syncPhSensors(state.phState));
        dispatch(syncSensors(state.sensorState));
        dispatch(syncThermalScreens(state.thermalScreenState));
        dispatch(syncVents(state.ventState));
        dispatch(syncWatermeters(state.watermetersState));
        dispatch(syncWeatherStations(state.weatherStationsState));

        // Sync state: Working
        dispatch(syncControllerPrograms({ controllerId: id, programsState: state.programsState }));
        dispatch(setControllerStatus({ id, status: ControllerStatus.Connected }));
    };

const onControllerDisconnected: SignalRListener<OndoCloudEvents.Controllers.ControllerDisconnected> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setControllerStatus({
                id: e.id,
                status: ControllerStatus.Disconnected,
                lastDisconnectedOn: e.lastDisconnectedOn,
            })
        );
    };

export const listeners = {
    ControllerConnected: onControllerConnected,
    ControllerDisconnected: onControllerDisconnected,
};
