import { baseApi } from './baseApi';
import { sortByNameAsc } from '../utils/sort';
import { startAppListening } from '../app/redux-listener-middleware/listener-middleware';
import { setProgramStatus } from '../slices/programsSlice';
import { ProgramStatus } from '../app/constants';
import { isProgramStatusFinal } from '../utils/programs';

export const irrigationInfrastructuresApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getIrrigationInfrastructures: build.query<OndoCloudSchemas.IrrigationInfrastructureDto[], void>({
            query: () => 'irrigation-infrastructures',
            transformResponse: (response: OndoCloudSchemas.IrrigationInfrastructureDto[]) =>
                response.sort(sortByNameAsc),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'IrrigationInfrastructures' as const, id })),
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                      ]
                    : [{ type: 'IrrigationInfrastructures', id: 'LIST' }],
        }),
        getIrrigationInfrastructure: build.query<
            OndoCloudSchemas.IrrigationInfrastructureDto,
            Pick<OndoCloudSchemas.IrrigationInfrastructureDto, 'id'>
        >({
            query: ({ id }) => `irrigation-infrastructures/${id}`,
            providesTags: (_, __, arg) => [
                { type: 'IrrigationInfrastructures', id: 'LIST' },
                { type: 'IrrigationInfrastructures', id: arg.id },
            ],
        }),

        createIrrigationInfrastructure: build.mutation<
            OndoCloudSchemas.IrrigationInfrastructureDto,
            OndoCloudSchemas.CreateIrrigationInfrastructureCommand
        >({
            query: body => ({
                url: 'irrigation-infrastructures',
                method: 'POST',
                body,
            }),
            invalidatesTags: result => (result ? [{ type: 'IrrigationInfrastructures', id: 'LIST' }] : []),
        }),
        updateIrrigationInfrastructure: build.mutation<
            OndoCloudSchemas.IrrigationInfrastructureDto,
            OndoCloudSchemas.EditIrrigationInfrastructureCommand & { id: string }
        >({
            query: ({ id, ...body }) => ({
                url: `irrigation-infrastructures/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'IrrigationInfrastructures', id: arg.id },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'DashboardIrrigationInfrastructure', id: arg.id },
                      ]
                    : [],
        }),
        deleteIrrigationInfrastructure: build.mutation<void, OndoCloudSchemas.IrrigationInfrastructureDto['id']>({
            query: id => ({
                url: `irrigation-infrastructures/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, id) =>
                !error
                    ? [
                          { type: 'IrrigationInfrastructures', id },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'DashboardIrrigationInfrastructure', id },
                      ]
                    : [],
        }),

        getIrrigationInfrastructureProgramsInfo: build.query<OndoCloudSchemas.DashboardIrrInfraDto, { id: string }>({
            query: ({ id }) => `irrigation-infrastructures/${id}/calendar`,
            providesTags: (_, __, arg) => [{ type: 'DashboardIrrigationInfrastructure', id: arg.id }],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetIrrigationInfrastructuresQuery,
    useGetIrrigationInfrastructureQuery,
    useGetIrrigationInfrastructureProgramsInfoQuery,
    useCreateIrrigationInfrastructureMutation,
    useUpdateIrrigationInfrastructureMutation,
    useDeleteIrrigationInfrastructureMutation,
} = irrigationInfrastructuresApi;

// Listeners

export const dashboardIrrigationInfrastructureListenerMiddleware = startAppListening({
    actionCreator: setProgramStatus,
    effect: (action, listenerApi) => {
        const status = action.payload.status;
        if (isProgramStatusFinal(status) || status === ProgramStatus.Paused) {
            listenerApi.dispatch(baseApi.util.invalidateTags(['DashboardIrrigationInfrastructure']));
        }
    },
});
