import { stringComparator } from '../utils/sort';
import { baseApi } from './baseApi';

export const usersApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getUsers: build.query<OndoCloudSchemas.UserDto[], void>({
            query: () => 'users',
            transformResponse: (result: OndoCloudSchemas.UserDto[]) =>
                result.sort((a, b) => stringComparator(`${a.firstName} ${a.lastName}`, `${b.firstName} ${b.lastName}`)),
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Users' as const, id })), { type: 'Users', id: 'LIST' }]
                    : [{ type: 'Users', id: 'LIST' }],
        }),

        getUser: build.query<OndoCloudSchemas.UserDto, { id: string }>({
            query: ({ id }) => `users/${id}`,
            providesTags: result => (result ? [{ type: 'Users' as const, id: result.id }] : []),
        }),

        updateUser: build.mutation<OndoCloudSchemas.UserDto, OndoCloudSchemas.EditUserDto & { id: string }>({
            query: ({ id, ...body }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'Users', id: arg.id },
                          { type: 'Users', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const { useGetUsersQuery, useGetUserQuery, useUpdateUserMutation } = usersApi;
