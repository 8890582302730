import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { SensorControllerType } from '../app/constants';
import { RootState } from '../app/store';
import { supmeaControllersApi } from '../services/supmeaControllers';

export type PhSensorsState = OndoCloudState.RootState['phState'];
export type PhSensorStateItem = PhSensorsState[string];

const initialState: PhSensorsState = {};

export const phSensorsSlice = createSlice({
    name: 'phSensors',
    initialState,
    reducers: {
        sync(draft, { payload }: PayloadAction<PhSensorsState>) {
            draft = payload;
            return draft;
        },

        addPhSensor(draft, { payload }: PayloadAction<PhSensorStateItem>) {
            const sensor = draft[payload.id];
            if (sensor) {
                return;
            }

            draft[payload.id] = payload;
        },

        removePhSensor(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setPh(draft, { payload }: PayloadAction<{ id: string; ph: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.ph = payload.ph;
        },

        setMilliamps(draft, { payload }: PayloadAction<{ id: string; milliamps: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.miliAmps = payload.milliamps;
        },

        setORP(draft, { payload }: PayloadAction<{ id: string; orp: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.orp = payload.orp;
        },

        setTemperature(draft, { payload }: PayloadAction<{ id: string; temperature: number }>) {
            const sensor = draft[payload.id];
            if (!sensor) {
                return;
            }

            sensor.temperature = payload.temperature;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            isAnyOf(
                supmeaControllersApi.endpoints.createSupmeaController.matchFulfilled,
                supmeaControllersApi.endpoints.updateSupmeaController.matchFulfilled
            ),
            (draft, { payload: supmeaController }) => {
                if (supmeaController.type !== SensorControllerType.PH) {
                    return;
                }

                draft[supmeaController.id] = {
                    id: supmeaController.id,
                    controllerId: supmeaController.connection.peripheral?.controllerId,
                    irrigationInfrastructureId: supmeaController.irrigationInfrastructure.id,
                    ph: 0,
                    miliAmps: 0,
                    orp: 0,
                    temperature: 0,
                    function: supmeaController.function,
                };
            }
        );

        builder.addMatcher(supmeaControllersApi.endpoints.deleteSupmeaController.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

export const { sync, addPhSensor, removePhSensor, setPh, setMilliamps, setORP, setTemperature } =
    phSensorsSlice.actions;

// Selectors

export const selectPhSensors = (state: RootState) => state.phSensors;
export const selectPhSensorById = createSelector(
    selectPhSensors,
    (_: RootState, id: string) => id,
    (phSensors, id: string): PhSensorStateItem | null => phSensors[id]
);

export const selectPhSensorPh = createSelector(selectPhSensorById, (phSensor): number | null => phSensor?.ph ?? null);
