import { DialogTitle as MuiDialogTitle, styled, Typography } from '@mui/material';
import { DialogType } from './Dialog';
import { DialogTitleIcon } from './DialogTitleIcon';

export interface DialogTitleProps {
    type: DialogType;
    title: string;
}

export const DialogTitleWrapper = styled(MuiDialogTitle)(({ theme }) => {
    return {
        padding: theme.spacing(0, 1, 4, 1),
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    };
});

const DialogTitle = ({ type, title }: DialogTitleProps) => {
    return (
        <DialogTitleWrapper>
            <DialogTitleIcon type={type} />
            <Typography variant="h2" whiteSpace="normal" sx={{ ml: 1, wordBreak: 'break-word' }} component="span">
                {title}
            </Typography>
        </DialogTitleWrapper>
    );
};

export { DialogTitle };
