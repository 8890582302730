import { createContext } from 'react';

export interface RouterContextValue {
    helperLink?: string;
    setHelperLink?: (link: string) => void;
}

export const RouterContext = createContext<RouterContextValue>({
    helperLink: undefined,
    setHelperLink: undefined,
});
