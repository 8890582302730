import { BoosterPumpStatus } from '../../app/constants';
import { setBoosterPumpStatus } from '../../slices/boosterPumpsSlice';
import { SignalRListener } from '../middleware';

const onBoosterPumpStarted: SignalRListener<OndoCloudEvents.BoosterPump.BoosterPumpStart> =
    e =>
    ({ dispatch }) => {
        dispatch(setBoosterPumpStatus({ id: e.id, status: BoosterPumpStatus.Started }));
    };

const onBoosterPumpStopped: SignalRListener<OndoCloudEvents.BoosterPump.BoosterPumpStop> =
    e =>
    ({ dispatch }) => {
        dispatch(setBoosterPumpStatus({ id: e.id, status: BoosterPumpStatus.Stopped }));
    };

export const listeners = {
    BoosterPumpStart: onBoosterPumpStarted,
    BoosterPumpStop: onBoosterPumpStopped,
};
