import { AirUniformingStatus } from '../../app/constants';
import {
    setCirculationFanStatus,
    setCirculationFanPeriods,
    setCirculationFanAirUniformingStatus,
} from '../../slices/circulationFanSlice';
import { SignalRListener } from '../middleware';

const onCirculationFanStatusChanged: SignalRListener<OndoCloudEvents.CirculationFans.CirculationFanStatusChanged> =
    e =>
    ({ dispatch }) => {
        dispatch(setCirculationFanStatus(e));
    };

const onCirculationFanPeriodsChanged: SignalRListener<
    OndoCloudEvents.CirculationFans.CircFanClimateStrategyPeriodChange
> =
    e =>
    ({ dispatch }) => {
        dispatch(setCirculationFanPeriods(e));
    };

const onCirculationFanAirUniformingWorking: SignalRListener<
    OndoCloudEvents.CirculationFans.CirculationFanAirUniformingWorking
> =
    e =>
    ({ dispatch }) => {
        dispatch(setCirculationFanAirUniformingStatus(e));
    };

const onCirculationFanAirUniformingResting: SignalRListener<
    OndoCloudEvents.CirculationFans.CirculationFanAirUniformingResting
> =
    e =>
    ({ dispatch }) => {
        dispatch(setCirculationFanAirUniformingStatus(e));
    };

const onCirculationFanAirUniformingStopped: SignalRListener<
    OndoCloudEvents.CirculationFans.CirculationFanAirUniformingStopped
> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setCirculationFanAirUniformingStatus({
                status: AirUniformingStatus.Idle,
                ...e,
            })
        );
    };

export const listeners = {
    CirculationFanStatusChanged: onCirculationFanStatusChanged,
    CircFanClimateStrategyPeriodChange: onCirculationFanPeriodsChanged,
    CirculationFanAirUniformingWorking: onCirculationFanAirUniformingWorking,
    CirculationFanAirUniformingResting: onCirculationFanAirUniformingResting,
    CirculationFanAirUniformingStopped: onCirculationFanAirUniformingStopped,
};
