import { Button, DialogActions as MuiDialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogType } from './Dialog';

export interface DialogActionsProps {
    type: DialogType;
    onConfirm?: () => void;
    onClose: () => void;
    actionsDisabled: boolean;
}

const DialogActions = ({ type, onConfirm, onClose, actionsDisabled = false }: DialogActionsProps) => {
    const { t } = useTranslation('buttons');

    if (type === 'delete') {
        return (
            <MuiDialogActions>
                <Button size="large" variant="contained" color="error" onClick={onConfirm} disabled={actionsDisabled}>
                    {t('delete', { ns: 'buttons' })}
                </Button>
                <Button size="large" variant="contained" color="secondary" onClick={onClose} disabled={actionsDisabled}>
                    {t('cancel', { ns: 'buttons' })}
                </Button>
            </MuiDialogActions>
        );
    }

    if (type === 'warning') {
        return (
            <MuiDialogActions>
                <Button size="large" variant="contained" color="primary" onClick={onConfirm} disabled={actionsDisabled}>
                    {t('save', { ns: 'buttons' })}
                </Button>
                <Button size="large" variant="contained" color="secondary" onClick={onClose} disabled={actionsDisabled}>
                    {t('cancel', { ns: 'buttons' })}
                </Button>
            </MuiDialogActions>
        );
    }

    return (
        <MuiDialogActions>
            <Button size="large" variant="contained" color="secondary" onClick={onClose} disabled={actionsDisabled}>
                {t('close', { ns: 'buttons' })}
            </Button>
        </MuiDialogActions>
    );
};

export { DialogActions };
