import { SignalRListener } from '../middleware/listeners';
import {
    setThermalScreenActiveControlMode,
    setThermalScreenError,
    setThermalScreenMode,
    setThermalScreenPeriods,
    setThermalScreenPosition,
    setThermalScreenStatus,
} from '../../slices/thermalScreensSlice';

const onThermalScreenStatusChange: SignalRListener<OndoCloudEvents.ThermalScreens.ThermalScreenStatusChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setThermalScreenStatus(e));
        dispatch(setThermalScreenActiveControlMode(e));
        dispatch(setThermalScreenError({ id: e.id, error: e.error }));
        dispatch(setThermalScreenMode(e));
    };

const onThermalScreenPositionChange: SignalRListener<OndoCloudEvents.ThermalScreens.ThermalScreenPosition> =
    e =>
    ({ dispatch }) => {
        dispatch(setThermalScreenPosition(e));
    };

const onThermalScreenClimateStrategyPeriodChange: SignalRListener<
    OndoCloudEvents.ThermalScreens.ThermalScreenClimateStrategyPeriodChange
> =
    e =>
    ({ dispatch }) => {
        dispatch(setThermalScreenPeriods(e));
    };

export const listeners = {
    ThermalScreenStatusChange: onThermalScreenStatusChange,
    ThermalScreenPositionChange: onThermalScreenPositionChange,
    ThermalScreenClimateStrategyPeriodChange: onThermalScreenClimateStrategyPeriodChange,
};
