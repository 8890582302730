import { useState } from 'react';
import {
    navigatorSupportsPushNotifications,
    setUserNotificationsPromptState,
    wasUserPromptedForNotifications,
} from '../../app/notifications/utils';
import { useAppSelector } from '../../hooks';
import { selectAuthStatus, selectUserId } from '../../slices/authSlice';
import { AskNotificationsPermissionsDialog } from './ask-permissions-dialog/AskNotificationsPermissionsDialog';

const AskNotificationsPermissionsDialogRenderer = () => {
    const areNotificationsSupported = navigatorSupportsPushNotifications();
    const [open, setOpen] = useState(areNotificationsSupported && Notification.permission === 'default');
    const userId = useAppSelector(selectUserId);
    const authStatus = useAppSelector(selectAuthStatus);
    const isAuthenticated = authStatus === 'loggedIn';
    const wasPromptedForNotifications = userId && wasUserPromptedForNotifications(userId);

    if (!isAuthenticated || !areNotificationsSupported || wasPromptedForNotifications) {
        return null;
    }

    return (
        <AskNotificationsPermissionsDialog
            open={open}
            onClose={() => {
                setOpen(false);
                setUserNotificationsPromptState(userId!, 'prompted');
            }}
        />
    );
};

export { AskNotificationsPermissionsDialogRenderer };
