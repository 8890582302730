import { baseApi } from './baseApi';

export const supmeaControllersApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getSupmeaControllers: build.query<
            OndoCloudSchemas.SupmeaControllerDto[],
            Partial<{ irrigationInfrastructureId: string }>
        >({
            query: params => ({
                url: 'supmea-controllers',
                method: 'GET',
                params,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'SupmeaControllers' as const, id })),
                          { type: 'SupmeaControllers', id: 'LIST' },
                      ]
                    : [{ type: 'SupmeaControllers', id: 'LIST' }],
        }),
        getSupmeaController: build.query<
            OndoCloudSchemas.SupmeaControllerDto,
            Pick<OndoCloudSchemas.SupmeaControllerDto, 'id'>
        >({
            query: ({ id }) => `supmea-controllers/${id}`,
            providesTags: (result, _, arg) => (result ? [{ type: 'SupmeaControllers' as const, id: arg.id }] : []),
        }),
        createSupmeaController: build.mutation<
            OndoCloudSchemas.SupmeaControllerDto,
            OndoCloudSchemas.CreateSupmeaControllerCommand
        >({
            query: body => ({
                url: 'supmea-controllers',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'SupmeaControllers' as const, id: 'LIST' },
                          { type: 'SupmeaControllers' as const, id: result.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateSupmeaController: build.mutation<
            OndoCloudSchemas.SupmeaControllerDto,
            OndoCloudSchemas.EditSupmeaControllerCommand & { irrigationInfrastructureId: string; id: string }
        >({
            query: ({ id, irrigationInfrastructureId: _, ...supmeaController }) => ({
                url: `supmea-controllers/${id}`,
                method: 'PUT',
                body: supmeaController,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'SupmeaControllers' as const, id: 'LIST' },
                          { type: 'SupmeaControllers' as const, id: result.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteSupmeaController: build.mutation<
            void,
            {
                id: string;
                irrigationInfrastructureId: string;
            }
        >({
            query: ({ id }) => ({
                url: `supmea-controllers/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'SupmeaControllers' as const, id: 'LIST' },
                          { type: 'SupmeaControllers' as const, id: arg.id },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetSupmeaControllersQuery,
    useGetSupmeaControllerQuery,
    useCreateSupmeaControllerMutation,
    useUpdateSupmeaControllerMutation,
    useDeleteSupmeaControllerMutation,
} = supmeaControllersApi;
