import { CheckmarkOutlinedIcon, DeleteIcon, LeafsIcon, Loader, WarningIcon } from '@ondo-ui/components';
import { DialogType } from './Dialog';

interface DialogTitleProps {
    type: DialogType;
}

const DialogTitleIcon = ({ type }: DialogTitleProps) => {
    switch (type) {
        case 'loading': {
            return <Loader threshold={null} />;
        }
        case 'error':
            return <WarningIcon color="error" fontSize="large" />;

        case 'noConnection':
            return <LeafsIcon color="warning" fontSize="large" />;

        case 'success': {
            return <CheckmarkOutlinedIcon color="primary" fontSize="large" />;
        }
        case 'delete': {
            return <DeleteIcon color="error" fontSize="large" />;
        }
        case 'warning': {
            return <WarningIcon color="warning" fontSize="large" />;
        }
        default:
            return null;
    }
};

export { DialogTitleIcon };
