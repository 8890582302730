import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SensorMeasuringParameter } from '../app/constants';
import { baseApi } from './baseApi';

export interface GetFieldSensorsArgs {
    fieldId: string;
    measuringParameter?: SensorMeasuringParameter;
}

export interface GetAllSensorsArgs {
    fieldIds: string[];
    measuringParameter?: SensorMeasuringParameter;
}

export const sensorsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getSensors: build.query<OndoCloudSchemas.SensorV2Dto[], { fieldId: string; sensorGroupId: string }>({
            query: ({ fieldId, sensorGroupId }) => `fields/${fieldId}/sensor-groups/${sensorGroupId}/sensors`,
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Sensors' as const, id })), { type: 'Sensors', id: 'LIST' }]
                    : [{ type: 'Sensors', id: 'LIST' }],
        }),

        getSensor: build.query<
            OndoCloudSchemas.SensorV2Dto,
            { sensorId: string; fieldId: string; sensorGroupId: string }
        >({
            query: ({ sensorId, fieldId, sensorGroupId }) =>
                `fields/${fieldId}/sensor-groups/${sensorGroupId}/sensors/${sensorId}`,
            providesTags: result => (result ? [{ type: 'Sensors' as const, id: result.id }] : []),
        }),

        getFieldSensors: build.query<OndoCloudSchemas.SensorV2Dto[], GetFieldSensorsArgs>({
            query: ({ fieldId, ...params }) => ({
                url: `fields/${fieldId}/sensors`,
                params: params ?? undefined,
            }),
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Sensors' as const, id })), { type: 'Sensors', id: 'LIST' }]
                    : [{ type: 'Sensors', id: 'LIST' }],
        }),

        getAllSensors: build.query<OndoCloudSchemas.SensorV2Dto[], GetAllSensorsArgs>({
            async queryFn(arg, _api, _extraOptions, baseQuery) {
                const promises = arg.fieldIds.map(fieldId =>
                    baseQuery({
                        url: `fields/${fieldId}/sensors`,
                        params: {
                            measuringParameter: arg.measuringParameter,
                        },
                    })
                );

                try {
                    const results = await Promise.all(promises);
                    const sensors: OndoCloudSchemas.SensorV2Dto[] = [];
                    for (const result of results) {
                        if (result.error) {
                            return {
                                error: result.error,
                            };
                        }

                        sensors.push(...(result.data as OndoCloudSchemas.SensorV2Dto[]));
                    }

                    return { data: sensors };
                } catch (err) {
                    return {
                        error: err as FetchBaseQueryError,
                    };
                }
            },
            providesTags: result =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Sensors' as const, id })), { type: 'Sensors', id: 'LIST' }]
                    : [{ type: 'Sensors', id: 'LIST' }],
        }),

        createSensor: build.mutation<
            OndoCloudSchemas.SensorV2Dto,
            { body: OndoCloudSchemas.CreateSensorCommand; fieldId: string; sensorGroupId: string }
        >({
            query: ({ body, fieldId, sensorGroupId }) => ({
                url: `fields/${fieldId}/sensor-groups/${sensorGroupId}/sensors`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'Sensors', id: 'LIST' },
                          { type: 'SensorGroups', id: 'LIST' },
                          { type: 'SensorGroups', id: arg.sensorGroupId },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'Assets', id: 'LIST' },
                      ]
                    : [],
        }),
        updateSensor: build.mutation<
            OndoCloudSchemas.SensorV2Dto,
            OndoCloudSchemas.EditSensorCommand & { sensorGroupId: string; fieldId: string; sensorId: string }
        >({
            query: ({ sensorGroupId, fieldId, sensorId, ...body }) => ({
                url: `fields/${fieldId}/sensor-groups/${sensorGroupId}/sensors/${sensorId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'Sensors', id: arg.sensorId },
                          { type: 'Sensors', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'SensorGroups', id: 'LIST' },
                          { type: 'SensorGroups', id: arg.sensorGroupId },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteSensor: build.mutation<
            void,
            {
                sensorId: string;
                sensorGroupId: string;
                fieldId: string;
            }
        >({
            query: ({ sensorId, fieldId, sensorGroupId }) => ({
                url: `fields/${fieldId}/sensor-groups/${sensorGroupId}/sensors/${sensorId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, { sensorId, sensorGroupId, fieldId }) =>
                !error
                    ? [
                          { type: 'Sensors', id: sensorId },
                          { type: 'Sensors', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: fieldId },
                          { type: 'SensorGroups', id: 'LIST' },
                          { type: 'SensorGroups', id: sensorGroupId },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetSensorQuery,
    useGetSensorsQuery,
    useGetAllSensorsQuery,
    useGetFieldSensorsQuery,
    useCreateSensorMutation,
    useUpdateSensorMutation,
    useDeleteSensorMutation,
} = sensorsApi;
