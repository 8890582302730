import { setConductivity, setResistivity, setTDS, setTemperature } from '../../slices/ecSensorsSlice';
import { SignalRListener } from '../middleware';

const onConductivityChanged: SignalRListener<OndoCloudEvents.EcSensors.EcControllerConductivityChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setConductivity({
                id: e.id,
                conductivity: e.value,
            })
        );

const onResistivityChanged: SignalRListener<OndoCloudEvents.EcSensors.EcControllerResistivityChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setResistivity({
                id: e.id,
                resistivity: e.value,
            })
        );

const onTDSChanged: SignalRListener<OndoCloudEvents.EcSensors.EcControllerTDSChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setTDS({
                id: e.id,
                tds: e.value,
            })
        );

const onTemperatureChanged: SignalRListener<OndoCloudEvents.EcSensors.EcControllerTemperatureChanged> =
    e =>
    ({ dispatch }) =>
        dispatch(
            setTemperature({
                id: e.id,
                temperature: e.value,
            })
        );

export const listeners = {
    EcControllerConductivityChanged: onConductivityChanged,
    EcControllerResistivityChanged: onResistivityChanged,
    EcControllerTDSChanged: onTDSChanged,
    EcControllerTemperatureChanged: onTemperatureChanged,
};
