import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BoosterPumpStatus } from '../app/constants';
import { RootState } from '../app/store';
import boosterPumpsApi from '../services/boosterPumps';

export type BoosterPumpStateItem = Omit<OndoCloudState.BoosterPumpState, 'controllerId'>;
export type BoosterPumpState = Record<string, BoosterPumpStateItem>;

const initialState: BoosterPumpState = {};

export const boosterPumpsSlice = createSlice({
    name: 'boosterPumps',
    initialState,
    reducers: {
        sync(draft, { payload: boosterPumps }: PayloadAction<OndoCloudState.RootState['boosterPumpState']>) {
            for (const [id, pump] of Object.entries(boosterPumps)) {
                draft[id] = {
                    status: pump.status,
                    irrigationInfrastructureId: pump.irrigationInfrastructureId,
                };
            }
        },
        addBoosterPump(draft, { payload }: PayloadAction<{ id: string } & BoosterPumpStateItem>) {
            const boosterPump = draft[payload.id];
            if (boosterPump) {
                return;
            }

            draft[payload.id] = {
                status: payload.status,
                irrigationInfrastructureId: payload.irrigationInfrastructureId,
            };
        },

        removeBoosterPump(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setBoosterPumpStatus(draft, { payload }: PayloadAction<{ id: string; status: BoosterPumpStatus }>) {
            const boosterPump = draft[payload.id];
            if (!boosterPump) {
                return;
            }

            boosterPump.status = payload.status;
        },
    },

    extraReducers: builder => {
        builder.addMatcher(
            boosterPumpsApi.endpoints.addBoosterPump.matchFulfilled,
            (draft, { payload: boosterPump }) => {
                draft[boosterPump.id] = {
                    status: BoosterPumpStatus.Stopped,
                    irrigationInfrastructureId: boosterPump.irrigationInfrastructure.id,
                };
            }
        );

        builder.addMatcher(
            boosterPumpsApi.endpoints.updateBoosterPump.matchFulfilled,
            (draft, { payload: boosterPump }) => {
                draft[boosterPump.id] = {
                    status: BoosterPumpStatus.Stopped,
                    irrigationInfrastructureId: boosterPump.irrigationInfrastructure.id,
                };
            }
        );

        builder.addMatcher(boosterPumpsApi.endpoints.deleteBoosterPump.matchFulfilled, (draft, { meta }) => {
            const id = meta.arg.originalArgs.id;
            delete draft[id];
        });
    },
});

// Selectors

export function selectBoosterPumps(state: RootState) {
    return state.boosterPumps;
}

export const selectBoosterPumpById = createSelector(
    selectBoosterPumps,
    (_: RootState, id: string) => id,
    (boosterPump, id) => boosterPump[id]
);

export const selectBoosterPumpStatus = createSelector(selectBoosterPumpById, boosterPump => boosterPump?.status);

export const { addBoosterPump, removeBoosterPump, setBoosterPumpStatus, sync } = boosterPumpsSlice.actions;
