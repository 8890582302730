import {
    setVentActiveControlMode,
    setVentClimateStrategyPeriods,
    setVentError,
    setVentMode,
    setVentPosition,
    setVentRainProtection,
    setVentStatus,
    setVentTemperatureProtection,
    setVentWindProtection,
    setWindProtectionRecovery,
} from '../../slices/ventSlice';
import { SignalRListener } from '../middleware/listeners';

const onVentPositionChange: SignalRListener<OndoCloudEvents.Vents.VentPositionChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentPosition(e));
    };

const onVentStatusChange: SignalRListener<OndoCloudEvents.Vents.VentStatusChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentStatus(e));
        dispatch(setVentMode(e));
        dispatch(setVentActiveControlMode(e));
        dispatch(setVentError({ id: e.id, error: e.error }));
    };

const onVentClimateStrategyPeriodChange: SignalRListener<OndoCloudEvents.Vents.VentClimateStrategyPeriodChange> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentClimateStrategyPeriods(e));
    };

const onVentRainProtectionActivated: SignalRListener<OndoCloudEvents.Vents.VentRainProtectionActivated> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentRainProtection({ isRainProtectionActive: true, ...e }));
    };

const onVentRainProtectionDeactivated: SignalRListener<OndoCloudEvents.Vents.VentRainProtectionDeactivated> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentRainProtection({ isRainProtectionActive: false, id: e.id }));
    };

const onVentTemperatureProtectionActivated: SignalRListener<OndoCloudEvents.Vents.VentTemperatureProtectionActivated> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentTemperatureProtection({ isTemperatureProtectionActive: true, ...e }));
    };

const onVentTemperatureProtectionDeactivated: SignalRListener<
    OndoCloudEvents.Vents.VentTemperatureProtectionDeactivated
> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentTemperatureProtection({ isTemperatureProtectionActive: false, id: e.id }));
    };

const onVentWindProtectionActivated: SignalRListener<OndoCloudEvents.Vents.VentWindProtectionActivated> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentWindProtection({ isWindProtectionActive: true, ...e }));
    };

const onVentWindProtectionDeactivated: SignalRListener<OndoCloudEvents.Vents.VentWindProtectionDeactivated> =
    e =>
    ({ dispatch }) => {
        dispatch(setVentWindProtection({ isWindProtectionActive: false, id: e.id }));
    };

const onVentWindProtectionRecoveryActivated: SignalRListener<
    OndoCloudEvents.Vents.VentWindProtectionRecoveryActivated
> =
    e =>
    ({ dispatch }) => {
        dispatch(setWindProtectionRecovery({ isWindProtectionRecoveryActive: true, ...e }));
    };

const onVentWindProtectionRecoveryDeactivated: SignalRListener<
    OndoCloudEvents.Vents.VentWindProtectionRecoveryDeactivated
> =
    e =>
    ({ dispatch }) => {
        dispatch(setWindProtectionRecovery({ isWindProtectionRecoveryActive: false, id: e.id }));
    };

export const listeners = {
    VentPositionChange: onVentPositionChange,
    VentStatusChange: onVentStatusChange,
    VentClimateStrategyPeriodChange: onVentClimateStrategyPeriodChange,
    VentRainProtectionActivated: onVentRainProtectionActivated,
    VentRainProtectionDeactivated: onVentRainProtectionDeactivated,
    VentTemperatureProtectionActivated: onVentTemperatureProtectionActivated,
    VentTemperatureProtectionDeactivated: onVentTemperatureProtectionDeactivated,
    VentWindProtectionDeactivated: onVentWindProtectionDeactivated,
    VentWindProtectionActivated: onVentWindProtectionActivated,
    VentWindProtectionRecoveryStarted: onVentWindProtectionRecoveryActivated,
    VentWindProtectionRecoveryCompleted: onVentWindProtectionRecoveryDeactivated,
};
