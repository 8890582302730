import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

export type ClimateStrategyStateItem = Omit<OndoCloudState.ClimateStrategyState, 'climateStrategyId'> & { id: string };
export type ClimateStrategiesState = {
    byId: Record<string, ClimateStrategyStateItem>;
    idByFieldId: Record<string, string>;
};

const initialState: ClimateStrategiesState = {
    byId: {},
    idByFieldId: {},
};

export const climateStrategiesSlice = createSlice({
    name: 'climateStrategies',
    initialState,
    reducers: {
        sync(
            draft,
            { payload: climateStrategiesById }: PayloadAction<OndoCloudState.RootState['climateStrategyState']>
        ) {
            const byId: ClimateStrategiesState['byId'] = {};
            const idByFieldId: ClimateStrategiesState['idByFieldId'] = {};
            for (const climateStrategy of Object.values(climateStrategiesById)) {
                byId[climateStrategy.climateStrategyId] = formatClimateStrategyStateItem(climateStrategy);
                idByFieldId[climateStrategy.fieldId] = climateStrategy.climateStrategyId;
            }

            draft.byId = byId;
            draft.idByFieldId = idByFieldId;
        },

        deactivateClimateStrategy(draft, { payload }: PayloadAction<{ id: string }>) {
            const id = payload.id;
            if (!draft.byId[id]) {
                return;
            }

            const fieldId = draft.byId[id].fieldId;
            delete draft.byId[id];
            delete draft.idByFieldId[fieldId];
        },

        changeClimateStrategyState(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.ClimateStrategies.ClimateStrategyStateChange>
        ) {
            const { climateStrategyId, ...strategy } = payload;

            draft.byId[climateStrategyId] = {
                id: climateStrategyId,
                ...strategy,
            };
            draft.idByFieldId[strategy.fieldId] = climateStrategyId;
        },
    },
});

function formatClimateStrategyStateItem(
    climateStrategy: OndoCloudState.ClimateStrategyState
): ClimateStrategyStateItem {
    const { climateStrategyId, ...strategy } = climateStrategy;
    return {
        id: climateStrategyId,
        ...strategy,
    };
}

export const selectActiveClimateStrategies = (state: RootState) => state.climateStrategies;
export const selectActiveClimateStrategyById = createSelector(
    selectActiveClimateStrategies,
    (_: RootState, id: string) => id,
    (climateStrategies, id): ClimateStrategyStateItem | null => climateStrategies.byId[id] ?? null
);
export const selectActiveClimateStrategyByFieldId = createSelector(
    selectActiveClimateStrategies,
    (_: RootState, fieldId: string) => fieldId,
    (climateStrategies, fieldId): ClimateStrategyStateItem | null => {
        const climateStrategyId = climateStrategies.idByFieldId[fieldId];
        return climateStrategies.byId[climateStrategyId] ?? null;
    }
);

export const { sync, deactivateClimateStrategy, changeClimateStrategyState } = climateStrategiesSlice.actions;
