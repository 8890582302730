import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ThermalScreenActiveControlMode,
    ThermalScreenError,
    ThermalScreenErrors,
    ThermalScreenMode,
    ThermalScreenStatus,
} from '../app/constants';
import { RootState } from '../app/store';
import { thermalScreensApi } from '../services/thermalScreens';

export type ThermalScreenStateItem = Omit<OndoCloudState.ThermalScreenState, 'controllerId'>;
export type ThermalScreenState = Record<string, ThermalScreenStateItem>;

const initialState: ThermalScreenState = {};

type ThermalScreenStatusChangePayload = {
    id: string;
} & (
    | {
          status:
              | ThermalScreenStatus.Error
              | ThermalScreenStatus.Idle
              | ThermalScreenStatus.Closed
              | ThermalScreenStatus.Unknown
              | ThermalScreenStatus.Opened;
      }
    | ({
          status:
              | ThermalScreenStatus.Opening
              | ThermalScreenStatus.Closing
              | ThermalScreenStatus.MeasuringTimes
              | ThermalScreenStatus.Synchronizing;
      } & Omit<OndoCloudEvents.ThermalScreens.ThermalScreenStatusChange, 'id' | 'status'>)
);

export const thermalScreensSlice = createSlice({
    name: 'thermalScreens',
    initialState,
    reducers: {
        sync(draft, { payload: thermalScreens }: PayloadAction<OndoCloudState.RootState['thermalScreenState']>) {
            draft = Object.entries(thermalScreens).reduce<typeof initialState>((result, [id, state]) => {
                result[id] = state;
                return result;
            }, {});

            return draft;
        },
        addThermalScreen(draft, { payload }: PayloadAction<{ id: string } & ThermalScreenStateItem>) {
            const thermalScreen = draft[payload.id];
            if (thermalScreen) {
                return;
            }

            draft[payload.id] = payload;
        },
        removeThermalScreen(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },
        setThermalScreenStatus(draft, { payload }: PayloadAction<ThermalScreenStatusChangePayload>) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }

            thermalScreen.status = payload.status;
            if (
                (payload.status === ThermalScreenStatus.Opening,
                payload.status === ThermalScreenStatus.Closing,
                payload.status === ThermalScreenStatus.MeasuringTimes,
                payload.status === ThermalScreenStatus.Synchronizing)
            ) {
                thermalScreen.operationStartedAt = payload.operationStartedAt;
            }
        },
        setThermalScreenPosition(draft, { payload }: PayloadAction<{ id: string; position: number }>) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }

            thermalScreen.position = payload.position;
        },
        setThermalScreenPeriods(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.ThermalScreens.ThermalScreenClimateStrategyPeriodChange>
        ) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }

            thermalScreen.activePeriod = payload.activePeriod;
            thermalScreen.nextPeriod = payload.nextPeriod;
        },

        setThermalScreenActiveControlMode(
            draft,
            { payload }: PayloadAction<{ id: string; activeControlMode: ThermalScreenActiveControlMode }>
        ) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }

            thermalScreen.activeControlMode = payload.activeControlMode;
        },
        setThermalScreenError(draft, { payload }: PayloadAction<{ id: string; error: ThermalScreenError }>) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }
            thermalScreen.error = payload.error;
        },
        setThermalScreenMode(draft, { payload }: PayloadAction<{ id: string; mode: ThermalScreenMode }>) {
            const thermalScreen = draft[payload.id];
            if (!thermalScreen) {
                return;
            }

            thermalScreen.mode = payload.mode;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            thermalScreensApi.endpoints.createThermalScreen.matchFulfilled,
            (draft, { payload: thermalScreen }) => {
                draft[thermalScreen.id] = {
                    status: ThermalScreenStatus.Idle,
                    position: 0,
                    fieldId: thermalScreen.field.id,
                    error: ThermalScreenErrors.NoErrors,
                    activeControlMode: ThermalScreenActiveControlMode.None,
                    mode: ThermalScreenMode.Remote,
                };
            }
        );
        builder.addMatcher(
            thermalScreensApi.endpoints.updateThermalScreen.matchFulfilled,
            (draft, { payload: thermalScreen }) => {
                const currentThermalScreen = draft[thermalScreen.id];
                if (!currentThermalScreen) {
                    return;
                }

                currentThermalScreen.status = ThermalScreenStatus.Idle;
            }
        );
        builder.addMatcher(thermalScreensApi.endpoints.deleteThermalScreens.matchFulfilled, (draft, { meta }) => {
            const thermalScreenId = meta.arg.originalArgs.thermalScreenId;
            delete draft[thermalScreenId];
        });
    },
});

// Selectors

export const selectThermalScreens = (state: RootState) => state.thermalScreens;

export const selectThermalScreenById = createSelector(
    selectThermalScreens,
    (_: RootState, id: string) => id,
    (thermalScreens, id) => thermalScreens[id]
);

export const selectThermalScreenStatus = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.status
);

export const selectThermalScreenPosition = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.position
);

export const selectThermalScreenCurrentActivePeriod = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.activePeriod ?? null
);

export const selectThermalScreenNextActivePeriod = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.nextPeriod ?? null
);

export const selectThermalScreenIsInActivePeriod = createSelector(selectThermalScreenCurrentActivePeriod, period =>
    Boolean(period)
);

export const selectThermalScreenHasNextActivePeriod = createSelector(selectThermalScreenNextActivePeriod, period =>
    Boolean(period)
);

export const selectThermalScreenActiveControlMode = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.activeControlMode
);

export const selectThermalScreenErrors = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.error ?? ThermalScreenError.NoErrors
);

export const selectThermalScreenMode = createSelector(
    selectThermalScreenById,
    thermalScreen => thermalScreen?.mode ?? ThermalScreenMode.Remote
);

export const {
    sync,
    addThermalScreen,
    removeThermalScreen,
    setThermalScreenStatus,
    setThermalScreenPeriods,
    setThermalScreenPosition,
    setThermalScreenActiveControlMode,
    setThermalScreenError,
    setThermalScreenMode,
} = thermalScreensSlice.actions;
