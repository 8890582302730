import { baseApi } from './baseApi';

export const mistingLinesApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getMistingLines: build.query<OndoCloudSchemas.MistingLineDto[], { fieldId: string }>({
            query: ({ fieldId }) => `fields/${fieldId}/misting-lines`,
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'MistingLines' as const, id })),
                          { type: 'MistingLines', id: 'LIST' },
                      ]
                    : [{ type: 'MistingLines', id: 'LIST' }],
        }),
        getMistingLine: build.query<OndoCloudSchemas.MistingLineDto, { mistingLineId: string; fieldId: string }>({
            query: ({ mistingLineId, fieldId }) => `fields/${fieldId}/misting-lines/${mistingLineId}`,
            providesTags: result => (result ? [{ type: 'MistingLines' as const, id: result.id }] : []),
        }),
        createMistingLine: build.mutation<
            OndoCloudSchemas.MistingLineDto,
            OndoCloudSchemas.CreateMistingLineCommand & { fieldId: string }
        >({
            query: ({ fieldId, ...body }) => ({
                url: `fields/${fieldId}/misting-lines`,
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'MistingLines', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        updateMistingLine: build.mutation<
            OndoCloudSchemas.MistingLineDto,
            OndoCloudSchemas.EditMistingLineCommand & { fieldId: string; mistingLineId: string }
        >({
            query: ({ fieldId, mistingLineId, ...body }) => ({
                url: `fields/${fieldId}/misting-lines/${mistingLineId}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result, __, arg) =>
                result
                    ? [
                          { type: 'MistingLines', id: arg.mistingLineId },
                          { type: 'MistingLines', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: arg.fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: arg.fieldId },
                      ]
                    : [],
        }),
        deleteMistingLine: build.mutation<
            void,
            {
                mistingLineId: string;
                fieldId: string;
            }
        >({
            query: ({ mistingLineId, fieldId }) => ({
                url: `fields/${fieldId}/misting-lines/${mistingLineId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, error, { mistingLineId, fieldId }) =>
                !error
                    ? [
                          { type: 'MistingLines', id: mistingLineId },
                          { type: 'MistingLines', id: 'LIST' },
                          { type: 'Fields', id: 'LIST' },
                          { type: 'Fields', id: fieldId },
                          { type: 'InputOutputs', id: 'LIST' },
                          { type: 'ClimateDevices', id: fieldId },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export const {
    useCreateMistingLineMutation,
    useDeleteMistingLineMutation,
    useGetMistingLineQuery,
    useGetMistingLinesQuery,
    useUpdateMistingLineMutation,
} = mistingLinesApi;
