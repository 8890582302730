// Put bootstrap scripts which setup certain libraries, configurations etc. below.
// Must be called before any imports
import './app/config';
import './bootstrap/dayjs';
import './bootstrap/i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

const MOUNT_NODE = document.getElementById('root');

const app = import.meta.env.DEV ? (
    <React.StrictMode>
        <App />
    </React.StrictMode>
) : (
    <App />
);

ReactDOM.render(app, MOUNT_NODE);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Number(import.meta.env.VITE_REPORT_WEB_VITALS) ? console.log : undefined);
