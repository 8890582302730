import { setLitersAndFlowRate } from '../../slices/fertimetersSlice';
import { SignalRListener } from '../middleware/listeners';

const onLitersChanged: SignalRListener<OndoCloudEvents.Fertimeters.FertimeterLitersChanged> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setLitersAndFlowRate({
                id: e.fertimeterId,
                liters: e.liters,
                flowRate: e.flowRate,
                flowRate_lh: e.flowRate_lh,
            })
        );
    };

export const listeners = {
    FertimeterLitersChanged: onLitersChanged,
};
