import {
    addFrostProtectionDevice,
    setFrostProtectionAutomaticSettings,
    setFrostProtectionDeviceStatus,
    setFrostProtectionStartTime,
    setFrostProtectionStrategyMode,
} from '../../slices/frostProtectionDevicesSlice';
import { SignalRListener } from '../middleware/listeners';
import { FrostProtectionDeviceStatus, FrostProtectionMode } from '../../app/constants';

const onFPDStarted: SignalRListener<OndoCloudEvents.FrostProtection.FrostProtectionStarted> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setFrostProtectionDeviceStatus({
                id: e.id,
                status: FrostProtectionDeviceStatus.Active,
            })
        );
        dispatch(
            setFrostProtectionStartTime({
                id: e.id,
                startedAt: e.startedAt,
            })
        );
    };

const onFPDStopped: SignalRListener<OndoCloudEvents.FrostProtection.FrostProtectionStopped> =
    e =>
    ({ dispatch }) => {
        dispatch(
            setFrostProtectionDeviceStatus({
                id: e.id,
                status: FrostProtectionDeviceStatus.Idle,
            })
        );
    };

const onFrostProtectionStrategyUpdated: SignalRListener<
    OndoCloudEvents.FrostProtection.FrostProtectionStrategyUpdated
> =
    e =>
    ({ dispatch }) => {
        if (e.mode === FrostProtectionMode.Automatic && e.automaticSettings) {
            dispatch(
                setFrostProtectionAutomaticSettings({
                    settings: e.automaticSettings,
                    id: e.deviceId,
                })
            );
        }
        dispatch(
            setFrostProtectionStrategyMode({
                mode: e.mode,
                id: e.deviceId,
            })
        );
    };

const onFrostProtectionAdded: SignalRListener<OndoCloudEvents.FrostProtection.FrostProtectionAdded> =
    e =>
    ({ dispatch }) => {
        dispatch(addFrostProtectionDevice(e));
    };

export const listeners = {
    FrostProtectionStarted: onFPDStarted,
    FrostProtectionStopped: onFPDStopped,
    FrostProtectionStrategyUpdated: onFrostProtectionStrategyUpdated,
    FrostProtectionAdded: onFrostProtectionAdded,
};
