import { baseApi } from './baseApi';

export const pushSubscriptionsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        createPushSubscription: build.mutation<void, PushSubscriptionJSON>({
            query: body => ({
                url: `push-subscriptions`,
                method: 'POST',
                body,
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useCreatePushSubscriptionMutation } = pushSubscriptionsApi;
