import { baseApi } from './baseApi';

export const waterMetersApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getWaterMeters: build.query<
            OndoCloudSchemas.WatermeterDto[],
            Partial<{ irrigationInfrastructureId: string }> | void
        >({
            query: params => ({
                url: 'water-meters',
                params: params ?? undefined,
            }),
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'WaterMeters' as const, id })),
                          { type: 'WaterMeters', id: 'LIST' },
                      ]
                    : [{ type: 'WaterMeters', id: 'LIST' }],
        }),
        getWaterMeter: build.query<OndoCloudSchemas.WatermeterDto, Pick<OndoCloudSchemas.WatermeterDto, 'id'>>({
            query: ({ id }) => `water-meters/${id}`,
            providesTags: (_, __, arg) => [{ type: 'WaterMeters', id: arg.id }],
        }),
        addWaterMeter: build.mutation<OndoCloudSchemas.WatermeterDto, OndoCloudSchemas.CreateWatermeterCommand>({
            query: payload => ({
                body: payload,
                method: 'POST',
                url: 'water-meters',
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'WaterMeters', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateWaterMeter: build.mutation<
            OndoCloudSchemas.WatermeterDto,
            {
                waterMeter: OndoCloudSchemas.EditWatermeterCommand;
                waterMeterId: OndoCloudSchemas.WatermeterDto['id'];
                infrastructureId: OndoCloudSchemas.WatermeterDto['irrigationInfrastructure']['id'];
            }
        >({
            query: ({ waterMeter, waterMeterId }) => ({
                url: `water-meters/${waterMeterId}`,
                method: 'PUT',
                body: waterMeter,
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'WaterMeters', id: arg.waterMeterId },
                          { type: 'WaterMeters', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.infrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteWaterMeter: build.mutation<
            void,
            {
                waterMeterId: OndoCloudSchemas.WatermeterDto['id'];
                irrigationInfrastructureId: OndoCloudSchemas.WatermeterDto['irrigationInfrastructure']['id'];
            }
        >({
            query: args => ({
                url: `water-meters/${args.waterMeterId}`,
                method: 'delete',
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'WaterMeters', id: args.waterMeterId },
                          { type: 'WaterMeters', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: args.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'ProgramsAlarmSettings', id: 'LIST' },
                          { type: 'AlarmTriggerDetails', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export default waterMetersApi;

export const {
    useGetWaterMeterQuery,
    useGetWaterMetersQuery,
    useAddWaterMeterMutation,
    useUpdateWaterMeterMutation,
    useDeleteWaterMeterMutation,
} = waterMetersApi;
