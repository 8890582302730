import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocalizedDayjs } from '../../hooks';

export interface MuiDateTimePickersLocalizationProviderProps {
    children: React.ReactNode;
}

const MuiDateTimePickersLocalizationProvider = ({ children }: MuiDateTimePickersLocalizationProviderProps) => {
    const dayjs = useLocalizedDayjs();
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale()}>
            {children}
        </LocalizationProvider>
    );
};

export { MuiDateTimePickersLocalizationProvider };
