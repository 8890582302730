import { baseApi } from './baseApi';

export const mainPumpsApi = baseApi.injectEndpoints({
    endpoints: build => ({
        getMainPumps: build.query<OndoCloudSchemas.MainPumpDto[], void>({
            query: () => 'main-pumps',
            providesTags: result =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: 'MainPumps' as const, id })),
                          { type: 'MainPumps', id: 'LIST' },
                      ]
                    : [{ type: 'MainPumps', id: 'LIST' }],
        }),
        getMainPump: build.query<OndoCloudSchemas.MainPumpDto, Pick<OndoCloudSchemas.MainPumpDto, 'id'>>({
            query: ({ id }) => `main-pumps/${id}`,
            providesTags: (_, __, arg) => [{ type: 'MainPumps', id: arg.id }],
        }),
        addMainPump: build.mutation<OndoCloudSchemas.MainPumpDto, OndoCloudSchemas.CreateMainPumpCommand>({
            query: payload => ({
                body: payload,
                method: 'POST',
                url: 'main-pumps',
            }),
            invalidatesTags: (result, _, arg) =>
                result
                    ? [
                          { type: 'MainPumps', id: 'LIST' },
                          { type: 'IrrigationInfrastructures', id: arg.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        updateMainPump: build.mutation<
            OndoCloudSchemas.MainPumpDto,
            {
                mainPump: OndoCloudSchemas.EditMainPumpCommand & { id: string };
                infrastructureId: string;
            }
        >({
            query: ({ mainPump }) => ({
                url: `main-pumps/${mainPump.id}`,
                method: 'PUT',
                body: mainPump,
            }),
            invalidatesTags: (_, error, arg) =>
                !error
                    ? [
                          { type: 'MainPumps', id: arg.mainPump.id },
                          { type: 'IrrigationInfrastructures', id: arg.infrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
        deleteMainPump: build.mutation<
            void,
            {
                mainPumpId: OndoCloudSchemas.MainPumpDto['id'];
                irrigationInfrastructureId: OndoCloudSchemas.MainPumpDto['irrigationInfrastructure']['id'];
            }
        >({
            query: args => ({
                url: `main-pumps/${args.mainPumpId}`,
                method: 'delete',
            }),
            invalidatesTags: (_, error, args) =>
                !error
                    ? [
                          { type: 'MainPumps', id: args.mainPumpId },
                          { type: 'IrrigationInfrastructures', id: args.irrigationInfrastructureId },
                          { type: 'IrrigationInfrastructures', id: 'LIST' },
                          { type: 'InputOutputs', id: 'LIST' },
                      ]
                    : [],
        }),
    }),
    overrideExisting: true,
});

export default mainPumpsApi;

export const {
    useGetMainPumpsQuery,
    useAddMainPumpMutation,
    useUpdateMainPumpMutation,
    useGetMainPumpQuery,
    useDeleteMainPumpMutation,
} = mainPumpsApi;
