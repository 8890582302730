import { useState } from 'react';
import { helpResources } from '~/app/helpResources';
import { RouterContext } from './RouterContext';

type RouterContextProviderProps = {
    children: React.LazyExoticComponent<() => React.ReactElement> | React.ReactElement;
};

const RouterContextProvider = ({ children }: RouterContextProviderProps) => {
    const [link, setLink] = useState<string>(helpResources.general ?? '');

    return (
        <RouterContext.Provider value={{ helperLink: link, setHelperLink: setLink }}>{children}</RouterContext.Provider>
    );
};

export { RouterContextProvider };
