import { EventMessage, EventType, AuthenticationResult, AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { navigatorSupportsPushNotifications } from '~/app/notifications/utils';
import { messagesApi } from '~/services/messages';
import { setNotificationPermission } from '~/slices/notificationPermissionSlice';
import { UserRole } from '../../app/constants';
import { store } from '../../app/store';
import { initiateLogin, initiateLogout, loginSuccess, logout } from '../../slices/authSlice';

export interface StoreProviderProps {
    children: JSX.Element;
}

function handleLoginStart() {
    store.dispatch(initiateLogin());
}

function handleLoginSuccess(payload: AuthenticationResult) {
    const account = payload.account as AccountInfo;

    store.dispatch(
        loginSuccess({
            id: account.localAccountId,
            role: account.idTokenClaims!.extension_Role as UserRole,
            companyName: '',
            username: account.username,
            name: account.name ?? '',
        })
    );
}

function handleLogoutStart() {
    store.dispatch(initiateLogout());
}

function handleLogout() {
    store.dispatch(logout());
}

function handleMsalEvent(e: EventMessage): void {
    if (e.eventType === EventType.LOGIN_START) {
        handleLoginStart();
    } else if (e.eventType === EventType.LOGIN_SUCCESS && e.payload) {
        handleLoginSuccess(e.payload as AuthenticationResult);
    } else if (e.eventType === EventType.LOGOUT_START) {
        handleLogoutStart();
    } else if (e.eventType === EventType.LOGOUT_SUCCESS) {
        handleLogout();
    }
}

/**
 * Due to lack of synchronization between the Permission API and Notification API on IOS safari
 * notification permission is initially set in the state from the Notification interface and consequently
 * updated each time Notification.requestPermission is called
 */

const handleSetNotificationPermission = () => {
    store.dispatch(
        setNotificationPermission({ permission: 'Notification' in window ? Notification.permission : 'default' })
    );
};

const handleServiceWorkerEventMessage = (event: MessageEvent<OndoCloudNotifications.ServiceWorkerEvents>) => {
    if (event.data && event.data.message === 'newOndoMessage') {
        store.dispatch(messagesApi.endpoints.getActiveNotifications.initiate({ activeDate: dayjs().toISOString() }));
    }
};

const StoreProvider = ({ children }: StoreProviderProps) => {
    const { instance: msalInstance } = useMsal();

    useEffect(() => {
        const cbId = msalInstance.addEventCallback(handleMsalEvent);
        return () => {
            cbId && msalInstance.removeEventCallback(cbId);
        };
    }, [msalInstance]);

    useEffect(() => {
        handleSetNotificationPermission();
    }, []);

    useEffect(() => {
        if (!navigatorSupportsPushNotifications()) return;

        const addServiceWorkerEventListener = () => {
            navigator.serviceWorker.addEventListener('message', handleServiceWorkerEventMessage);
        };

        const removeServiceWorkerEventListener = () => {
            navigator.serviceWorker.removeEventListener('message', handleServiceWorkerEventMessage);
        };

        addServiceWorkerEventListener();

        return removeServiceWorkerEventListener;
    });

    return <Provider store={store}>{children}</Provider>;
};

export { StoreProvider };
