import { changeIrrigationValveStatus } from '../../slices/irrigationValvesSlice';
import { SignalRListener } from '../middleware/listeners';

const onIrrigationValveStatusChanged: SignalRListener<OndoCloudEvents.IrrigationValves.IrrigationValveStatusChanged> =
    e =>
    ({ dispatch }) => {
        dispatch(
            changeIrrigationValveStatus({
                id: e.id,
                status: e.status,
            })
        );
    };

export const listeners = {
    IrrigationValveStatusChanged: onIrrigationValveStatusChanged,
};
