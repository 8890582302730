import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VentActiveControlMode, VentError, VentMode, VentStatus } from '~/app/constants';
import { RootState } from '~/app/store';
import { ventsApi } from '~/services/vents';

export type VentState = OndoCloudState.RootState['ventState'];
export type VentStateItem = VentState[string];

type VentRainProtectionPayload = { id: string } & (
    | {
          isRainProtectionActive: false;
      }
    | ({ isRainProtectionActive: true } & OndoCloudEvents.Vents.VentRainProtectionActivated)
);

type VentTemperatureProtectionPayload = { id: string } & (
    | {
          isTemperatureProtectionActive: false;
      }
    | ({ isTemperatureProtectionActive: true } & OndoCloudEvents.Vents.VentTemperatureProtectionActivated)
);

type VentWindProtectionPayload = { id: string } & (
    | {
          isWindProtectionActive: false;
      }
    | ({ isWindProtectionActive: true } & OndoCloudEvents.Vents.VentWindProtectionActivated)
);

type VentWindProtectionRecoveryPayload = { id: string } & (
    | {
          isWindProtectionRecoveryActive: false;
      }
    | ({ isWindProtectionRecoveryActive: true } & OndoCloudEvents.Vents.VentWindProtectionRecoveryActivated)
);

const initialState: VentState = {};

export const ventsSlice = createSlice({
    name: 'vents',
    initialState,
    reducers: {
        sync(draft, { payload: vents }: PayloadAction<VentState>) {
            for (const vent of Object.values(vents)) {
                draft[vent.id] = vent;
            }
        },

        addVent(draft, { payload }: PayloadAction<VentStateItem>) {
            const vent = draft[payload.id];
            if (vent) {
                return;
            }

            draft[payload.id] = payload;
        },

        removeVent(draft, { payload }: PayloadAction<{ id: string }>) {
            delete draft[payload.id];
        },

        setVentStatus(draft, { payload }: PayloadAction<{ id: string; status: VentStatus }>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.status = payload.status;
        },

        setVentMode(draft, { payload }: PayloadAction<{ id: string; mode: VentMode }>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.mode = payload.mode;
        },

        setVentActiveControlMode(
            draft,
            { payload }: PayloadAction<{ id: string; activeControlMode: VentActiveControlMode }>
        ) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.activeControlMode = payload.activeControlMode;
        },

        setVentPosition(draft, { payload }: PayloadAction<OndoCloudEvents.Vents.VentPositionChange>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.position = payload.position;
        },

        setVentClimateStrategyPeriods(
            draft,
            { payload }: PayloadAction<OndoCloudEvents.Vents.VentClimateStrategyPeriodChange>
        ) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.activePeriod = payload.activePeriod;
            vent.nextPeriod = payload.nextPeriod;
        },

        setVentRainProtection(draft, { payload }: PayloadAction<VentRainProtectionPayload>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.isRainProtectionActive = payload.isRainProtectionActive;

            if (payload.isRainProtectionActive) {
                vent.rainProtectionActivatedAt = payload.rainProtectionActivatedAt;
                vent.rainQuantityCurrent = payload.rainQuantityCurrent;
            }
        },

        setVentWindProtection(draft, { payload }: PayloadAction<VentWindProtectionPayload>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.isWindProtectionActive = payload.isWindProtectionActive;

            if (payload.isWindProtectionActive) {
                vent.windProtectionActivatedAt = payload.activatedAt;
            }
        },

        setVentTemperatureProtection(draft, { payload }: PayloadAction<VentTemperatureProtectionPayload>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.isTemperatureProtectionActive = payload.isTemperatureProtectionActive;

            if (payload.isTemperatureProtectionActive) {
                vent.temperatureProtectionActivatedAt = payload.temperatureProtectionActivatedAt;
            }
        },
        setWindProtectionRecovery(draft, { payload }: PayloadAction<VentWindProtectionRecoveryPayload>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }

            vent.isWindProtectionRecoveryInProgress = payload.isWindProtectionRecoveryActive;

            if (payload.isWindProtectionRecoveryActive) {
                vent.windProtectionRecoveryStartedAt = payload.startedAt;
            }
        },
        setVentError(draft, { payload }: PayloadAction<{ id: string; error: VentError }>) {
            const vent = draft[payload.id];
            if (!vent) {
                return;
            }
            vent.error = payload.error;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(ventsApi.endpoints.createVent.matchFulfilled, (draft, { payload: vent }) => {
            draft[vent.id] = {
                id: vent.id,
                status: VentStatus.Idle,
                position: 0,
                controllerId: vent.output.peripheral.controllerId,
                error: 0,
                isRainProtectionActive: false,
                isTemperatureProtectionActive: false,
                isWindProtectionActive: false,
                isWindProtectionRecoveryInProgress: false,
                rainQuantityCurrent: 0,
                windProtectionRecoveryStartedAt: '',
                windProtectionIsFrontWind: false,
                mode: VentMode.Remote,
            };
        });

        builder.addMatcher(ventsApi.endpoints.updateVent.matchFulfilled, (draft, { payload: vent }) => {
            const currentVent = draft[vent.id];
            if (!currentVent) {
                return;
            }

            currentVent.status = VentStatus.Idle;
            currentVent.controllerId = vent.output.peripheral.controllerId;
        });

        builder.addMatcher(ventsApi.endpoints.deleteVents.matchFulfilled, (draft, { meta }) => {
            const ventId = meta.arg.originalArgs.ventId;
            delete draft[ventId];
        });
    },
});

// Selectors

export const selectVents = (state: RootState) => state.vents;

export const selectVentById = createSelector(
    selectVents,
    (_: RootState, id: string) => id,
    (vents, id): VentStateItem | null => vents[id] ?? null
);

export const selectVentStatus = createSelector(selectVentById, vent => vent?.status ?? VentStatus.Idle);
export const selectVentMode = createSelector(selectVentById, vent => vent?.mode ?? VentMode.Remote);
export const selectVentPosition = createSelector(selectVentById, vent => vent?.position);
export const selectVentActivePeriod = createSelector(selectVentById, vent => vent?.activePeriod);
export const selectVentActivePeriodCirculationFanId = createSelector(
    selectVentActivePeriod,
    period => period?.circulationFanId ?? null
);
export const selectVentActivePeriodHasAirUniformingEnabled = createSelector(selectVentActivePeriod, period => {
    if (!period) {
        return false;
    }

    return Boolean(period.isAirUniformingEnabled);
});

export const selectVentCurrentActivePeriod = createSelector(selectVentById, vent => vent?.activePeriod ?? null);

export const selectVentNextActivePeriod = createSelector(selectVentById, vent => vent?.nextPeriod ?? null);

export const selectVentIsInActivePeriod = createSelector(selectVentCurrentActivePeriod, period => Boolean(period));
export const selectRainProtectionActivated = createSelector(
    selectVentById,
    vent => vent?.isRainProtectionActive ?? false
);
export const selectWindProtectionActivated = createSelector(
    selectVentById,
    vent => vent?.isWindProtectionActive ?? false
);
export const selectWindProtectionRecoveryActivated = createSelector(
    selectVentById,
    vent => vent?.isWindProtectionRecoveryInProgress ?? false
);
export const selectWindProtectionRecoverynActivatedAt = createSelector(
    selectVentById,
    vent => vent?.windProtectionRecoveryStartedAt
);
export const selectWindProtectionIsFrontWind = createSelector(
    selectVentById,
    vent => vent?.windProtectionIsFrontWind ?? false
);
export const selectTemperatureProtectionActivated = createSelector(
    selectVentById,
    vent => vent?.isTemperatureProtectionActive ?? false
);
export const selectTemperatureProtectionActivatedAt = createSelector(
    selectVentById,
    vent => vent?.temperatureProtectionActivatedAt
);
export const selectTemperatureProtectionThreshold = createSelector(selectVentActivePeriod, period => {
    if (!period) {
        return undefined;
    }

    return period.temperatureProtectionThreshold;
});
export const selectRainProtectionActivatedAt = createSelector(selectVentById, vent => vent?.rainProtectionActivatedAt);
export const selectWindProtectionActivatedAt = createSelector(selectVentById, vent => vent?.windProtectionActivatedAt);
export const selectVentActiveControlMode = createSelector(selectVentById, vent => vent?.activeControlMode);
export const selectVentError = createSelector(selectVentById, vent => vent?.error ?? VentError.NoErrors);
export const selectVentHasNextActivePeriod = createSelector(selectVentNextActivePeriod, period => Boolean(period));

export const {
    sync,
    addVent,
    removeVent,
    setVentStatus,
    setVentMode,
    setVentPosition,
    setVentClimateStrategyPeriods,
    setVentRainProtection,
    setVentTemperatureProtection,
    setVentActiveControlMode,
    setVentWindProtection,
    setWindProtectionRecovery,
    setVentError,
} = ventsSlice.actions;
